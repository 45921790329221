import { ICoordinates, PlayerToolEvent } from "@threekit-tools/treble/dist/types";
import {
  ModelsName_NodesT,
  NODES_THREEKIT,
  WallItemT,
} from "../../../constants/nodesNamesThreekit";
import {
  getItemNodeFromNullModel,
  getNodeFromId,
  getTranslationThreekit,
} from "../../general/getFunctions";
import {
  AllWallsIntervalsFloorT,
  getIntervalsBaseCabinetsForAllWalls,
} from "../../../../functionsConfigurator/intervals/getIntervalsInfoOnWallBase";
import {
  addCornerModelBase,
  ArrNamesCornerWallsT,
  checkModelBaseInCornerPoint,
  getInternalCornerCoordsForWalls,
  isEqualCoordsTolerance,
} from "../../../../functionsConfigurator/cabinets/addCornerModelBase";
import { once } from "../../../../pattern/once";
import {
  moveAllCabinetsBaseOnWall,
  moveCabinetBaseOnWallForEmptyCorner,
  MoveDirectionOnWallT,
} from "../../../../functionsConfigurator/cabinets/cabinetsBase/moving/moveAllCabinetsBaseOnWall";
import {
  arrAllDistanceFromModelToFrontWall,
  arrPositiveDistanceFromModelToFrontWall,
  checkApproachToCorner,
  checkApproachToCornerFromWallSidePoints,
  getCornerDistanceSorted,
  getDirectionIntervalEmpty,
  getModelPosition,
  getMoveDistanceModels,
  getObjDistance,
  getObjWallsForCalculateDistance,
  getWallNameFromMaxDistanceInCorner,
  isEmptyIntervalInWall,
  ObjWallsForCalculateDistanceT,
  rotateObjBackToWall,
} from "./generalFunc";
import { checkCornerIntervalEmpty, getIntervalsWallCabinetsForAllWalls, getSizeModelRelativeTransform } from "../../../../functionsConfigurator/intervals/getIntervalsInfoOnWall";
import { getExtremeModels } from "../../../../functionsConfigurator/cabinets/getExtremeModels";
import { ATTRIBUTES_DECORATIVE_PANELS, getConfiguratorModelFromNullName, updateDecorativeEnd } from "../../../../functionsConfigurator/cabinets/configuration/decorativePanel";
import { PRODUCT_POSITIONS_KEYS } from "../../../constants/cabinets";
import { isFilledIntervalForWall } from "../toolsDragCabinetsWall/generalFunc";
import { checkCornersEmptyRemoveCabinetsBase } from "../../../../functionsConfigurator/cabinets/cabinetsBase/checkCornersEmptyRemove";
import { Dispatch } from "@reduxjs/toolkit";
import { getIntersectsModelsFromWall } from "../../../../functionsConfigurator/cabinets/intersectsModelsFromWall";
import { setConfiguration } from "../../general/setFunctions";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../constants/attributesThreekit";
import { checkCountertopCabinetsBase } from "../../../../functionsConfigurator/cabinets/configuration/countertop";
import { checkCabinetsBaseConnectors } from "../../../../functionsConfigurator/cabinets/cabinetsBase/checkCabinetsBaseConnectors";
import { CORNER_EMPTY_BASE_SIZE_X, CORNER_EMPTY_BASE_SIZE_Z } from "../../../../functionsConfigurator/cabinets/constatns";
import { updateFrigePanels } from "../../../../functionsConfigurator/cabinets/configuration/fridgePanels";
import { checkCabinetsWallVerticalConnectors } from "../../../../functionsConfigurator/cabinets/cabinetsWall/checkCabinetsWallVerticalConnectors";
import { checkIfBaseCornerCabinet } from "../toolsDragCabinetsIsland/generalFunc";
import { checkAppliancesConnectors } from "../../../../functionsConfigurator/cabinets/appliances/checkApliancesConnectors";
import { updateDimensionsCabinetsBase } from "../../../../functionsConfigurator/dimensions/dimensionsCabinetsBase";
import { getSizeModelBoxFromAssetCabinetBase } from "../../../../functionsConfigurator/cabinets/cabinetsBase/size";
import { isOnDimensions } from "../../../../functionsConfigurator/dimensions/generalDimensions";
import { TOOLS_WHALEN } from "../toolsGeneral";

const getSelectedFirstItem = () => {
  //@ts-ignore
  const selectedIds = window.threekit.player.selectionSet.ids;
  if (selectedIds.length < 1) return undefined;
  const selectedFirstItem = selectedIds[0];
  return selectedFirstItem;
};

const getPositionMovedFloorModel = (idModel: string): PRODUCT_POSITIONS_KEYS => {
  const nameModel = getNodeFromId(idModel)["name"];
  const position = nameModel.split("_").slice(1, -1).join(" ") as PRODUCT_POSITIONS_KEYS;
  return position;
}

const checkIsThereCabinetFloor = (ev: PlayerToolEvent) => {
  const nameCabinetBase = NODES_THREEKIT.MODEL_CABINET_BASE;
  // const nameCabinetWall = NODES_THREEKIT.MODEL_CABINET_WALL;
  const nameCabinetIsland = NODES_THREEKIT.MODEL_CABINET_ISLAND;
  const nameAppliances = NODES_THREEKIT.MODEL_APPLIANCES;

  let selectedFirstItem = null;
  if (ev.hitNodes.length < 1) return selectedFirstItem;
  if (ev.hitNodes[0]["hierarchy"].length < 1) return selectedFirstItem;

  selectedFirstItem = ev.hitNodes[0]["hierarchy"].find(
    (item) =>
      item["name"].includes(nameCabinetBase)
      // item["name"].includes(nameCabinetWall) ||
      // item["name"].includes(nameCabinetIsland) ||
      // item["name"].includes(nameAppliances)
  );

  return selectedFirstItem;
};

const checkIsThereCabinet = (ev: PlayerToolEvent) => {
  const nameCabinetBase = NODES_THREEKIT.MODEL_CABINET_BASE;
  const nameCabinetWall = NODES_THREEKIT.MODEL_CABINET_WALL;
  const nameCabinetIsland = NODES_THREEKIT.MODEL_CABINET_ISLAND;
  const nameAppliances = NODES_THREEKIT.MODEL_APPLIANCES;

  let selectedFirstItem = undefined;
  if (ev.hitNodes.length < 1) return selectedFirstItem;
  if (ev.hitNodes[0]["hierarchy"].length < 1) return selectedFirstItem;

  selectedFirstItem = ev.hitNodes[0]["hierarchy"].find(
    (item) =>
      item["name"].includes(nameCabinetBase) ||
      item["name"].includes(nameCabinetWall) ||
      item["name"].includes(nameCabinetIsland) ||
      item["name"].includes(nameAppliances)
  );
  return selectedFirstItem;
};

const checkIsThereCabinetBase = (ev: PlayerToolEvent) => {
  const nameCabinetBase = NODES_THREEKIT.MODEL_CABINET_BASE;
  const nameAppliances = NODES_THREEKIT.MODEL_APPLIANCES;

  let selectedFirstItem = null;
  if (ev.hitNodes.length < 1) return selectedFirstItem;
  if (ev.hitNodes[0]["hierarchy"].length < 1) return selectedFirstItem;

  selectedFirstItem = ev.hitNodes[0]["hierarchy"].find(
    (item) => item["name"].includes(nameCabinetBase)
  );

  return selectedFirstItem;
};

const getDistanceFromObjToWall = (
  objWallsForCalculateDistance: ObjWallsForCalculateDistanceT,
  modelId: any,
  intervalsBaseCabinetsForAllWAlls: AllWallsIntervalsFloorT,
  changeCabinets: any
) => {
  const modelItem = getItemNodeFromNullModel({ id: modelId });
  const modelPos = getModelPosition(modelId);
  const modelNode = getNodeFromId(modelId);
  const modelNullName = modelNode["name"] as ModelsName_NodesT;
  // const modelSize = getSizeModelRelativeTransform(modelNullName);
  const modelSize = getSizeModelBoxFromAssetCabinetBase(modelNullName);
  // console.log("modelSize --- ==== ", modelSize);

  const objDistance = getObjDistance(objWallsForCalculateDistance, modelPos);
  // const arrWallNameFromObjDistance = Object.keys(objDistance) as Array<
  //   keyof typeof objDistance
  // >;

  // check base corner cabinet from id "1017"
  if (checkIfBaseCornerCabinet(modelNullName)) {

    const cornerDistance = checkApproachToCornerFromWallSidePoints(objDistance, modelSize, modelPos);

    if (Object.keys(cornerDistance).length === 2) {
      const cornerWallsSortedLeftRight =
        getCornerDistanceSorted(cornerDistance);
      const maxDistanceWallName =
        getWallNameFromMaxDistanceInCorner(cornerDistance);

      const configuratorCornerModel = getConfiguratorModelFromNullName(modelNullName);

      if (maxDistanceWallName === cornerWallsSortedLeftRight[0]) {
        configuratorCornerModel.setConfiguration({
          [ATTRIBUTES_NAMES_THREEKIT.ROTATE_CABINET_CORNER]: "right",
        });
      } else if (maxDistanceWallName === cornerWallsSortedLeftRight[1]) {
        configuratorCornerModel.setConfiguration({
          [ATTRIBUTES_NAMES_THREEKIT.ROTATE_CABINET_CORNER]: "left",
        });
      }


      // rotateObjBackToWall(
      //   modelId,
      //   cornerDistance[cornerWallsSortedLeftRight[0]]["wallDir"]
      // );
    }
    return;
  }

  // const arrAllDistanceFront = arrAllDistanceFromModelToFrontWall(objDistance);
  // const arrPositiveAllDistanceFront =
  //   arrPositiveDistanceFromModelToFrontWall(objDistance);

  // arrWallNameFromObjDistance.forEach((wallName) => {
  //   const distanceFront = objDistance[wallName]["distanceFront"];
  //   const distancePositiveX = objDistance[wallName]["distanceLeftX"];
  //   const distanceNegativeX = objDistance[wallName]["distanceRightX"];
  //   const wallWidth = objDistance[wallName]["width"];

  //   if (distanceFront < 0) {
  //     if (
  //       distanceFront > -1 &&
  //       Math.abs(distancePositiveX) < wallWidth &&
  //       Math.abs(distanceNegativeX) < wallWidth &&
  //       distanceFront.toFixed(4) ===
  //         Math.min.apply(null, arrAllDistanceFront).toFixed(4)
  //     )
  //       return rotateObjBackToWall(
  //         modelId,
  //         objDistance[wallName]["wallDir"],
  //         true
  //       );
  //   }

  //   if (
  //     distanceFront < modelSize["z"] * 2 &&
  //     Math.abs(distancePositiveX) < wallWidth &&
  //     Math.abs(distanceNegativeX) < wallWidth &&
  //     distanceFront.toFixed(4) ===
  //       Math.min.apply(null, arrPositiveAllDistanceFront).toFixed(4)
  //   )
  //     return rotateObjBackToWall(modelId, objDistance[wallName]["wallDir"]);
  // });

  const cornerDistance = checkApproachToCorner(objDistance, modelSize);

  if (Object.keys(cornerDistance).length === 2) {
    const maxDistanceWallName =
      getWallNameFromMaxDistanceInCorner(cornerDistance);
    const cornerWallsSortedLeftRight = getCornerDistanceSorted(cornerDistance);
    const intervalsForWallMaxDistance =
      intervalsBaseCabinetsForAllWAlls[maxDistanceWallName];
    // const offsetDistance = modelSize["z"] + 0.04; // 0.04 === ширина прижимної планки
    const directionIntervalEmpty = getDirectionIntervalEmpty(
      maxDistanceWallName,
      cornerWallsSortedLeftRight
    );
    const offsetDistance =
      directionIntervalEmpty === "left"
        ? CORNER_EMPTY_BASE_SIZE_Z
        : CORNER_EMPTY_BASE_SIZE_X;

    const isEmptyInterval = isEmptyIntervalInWall(
      intervalsForWallMaxDistance,
      offsetDistance,
      directionIntervalEmpty,
      modelNullName
    );

    if (isEmptyInterval) {

      const moveDistance = getMoveDistanceModels(
        directionIntervalEmpty,
        offsetDistance,
        intervalsForWallMaxDistance
      );

      const moveDirection =
        directionIntervalEmpty === "left" ? "right" : "left";

      changeCabinets(
        maxDistanceWallName,
        cornerWallsSortedLeftRight,
        moveDirection,
        moveDistance
      );

    }

    // check Decorative End
    if (cornerDistance[maxDistanceWallName]["distanceFront"] < modelSize["x"]) {
      const configuratorModel = getConfiguratorModelFromNullName(modelNullName);
      if (maxDistanceWallName === cornerWallsSortedLeftRight[0]) {
        configuratorModel.setConfiguration({
          [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Right",
          [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND]: "No",
        })
      }
      if (maxDistanceWallName === cornerWallsSortedLeftRight[1]) {
        configuratorModel.setConfiguration({
          [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Left",
          [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND]: "No",
        })
      }
    }

  }
};

/**
 * Тимчасова функція заглушка. Ємулює завершення події drag.
 * Щоб не виникала помилка трикіта.
 *
 */
export const cancelDragModelCustom = () => {
  var el: HTMLElement | null = document.querySelector("#player_wrapper canvas");
  if (!!el) {
    let ev = new MouseEvent("mouseup", {
      view: window,
      bubbles: true,
      cancelable: true,
      clientX: 0,
      clientY: 0,
    });
    el.dispatchEvent(ev);
  }
};

let selectedCabinetBaseId: string | undefined = undefined;
export const dragCabinetsBase = (dispatch: Dispatch) => {

  // let movedModelPosition: PRODUCT_POSITIONS_KEYS | undefined = undefined;
  let startModelPosistion: ICoordinates = {x: 0, y: 0, z: 0};

  return {
    active: true,
    enabled: true,
    key: TOOLS_WHALEN.DRAG_CABINETS_BASE,
    handlers: {
      mousedown: (ev: PlayerToolEvent) => {
        
        const selectedFloorModel = checkIsThereCabinetBase(ev);
        if (selectedFloorModel) {
          startModelPosistion = getTranslationThreekit({id: selectedFloorModel["nodeId"]})

          // Selecting models
          // const errorModelsIdsV2 = getIntersectsModelsFromWall();
          // if (errorModelsIdsV2.includes(selectedFloorModel["nodeId"])) {
          //   //@ts-ignore
          //   window.threekit.player.selectionSet.setStyle({
          //     outlineColor: '#ff7070'
          //   })
          // } else {
          //   //@ts-ignore
          //   window.threekit.player.selectionSet.setStyle({
          //     outlineColor: '#ffff00'
          //   })
          // }

          setConfiguration(
            ATTRIBUTES_NAMES_THREEKIT.PHYSICS_CABINETS_WALL,
            false
          );

          checkCabinetsBaseConnectors(selectedFloorModel["nodeId"]);
          checkCabinetsWallVerticalConnectors(selectedFloorModel["nodeId"]);
        }

        return false;
        
      },
      mouseup: (ev: PlayerToolEvent) => {

        // if (movedModelPosition) {
        //   updateDecorativeEnd(movedModelPosition);
        //   if (movedModelPosition === PRODUCT_POSITIONS_KEYS.BASE_CABINET) {
        //     checkCornersEmptyRemoveCabinetsBase(dispatch)
        //   }
        // }

        // checkCornersEmptyRemove();

        // const intervalsBaseCabinetsForAllWalls = getIntervalsBaseCabinetsForAllWalls();
        // console.log('intervalsBaseCabinetsForAllWalls --- ==== ',intervalsBaseCabinetsForAllWalls);

        // const intervalsWallCabinetsForAllWalls = getIntervalsWallCabinetsForAllWalls();
        // console.log('intervalsWallCabinetsForAllWalls --- ==== ',intervalsWallCabinetsForAllWalls);

        // const extremeModels = getExtremeModels(NODES_THREEKIT.MODEL_CABINET_BASE)
        // console.log('extremeModels --- ==== ',extremeModels);
        
        // Selecting Models
        // if (selectedCabinetBaseId !== undefined) {

        //   // Selecting Models
        //   const errorModelsIdsv1 = getIntersectsModelsFromWall();
        //   // const endModelPosistion = getTranslationThreekit({id: selectedCabinetBaseId});
        //   if (errorModelsIdsv1.length > 0 && errorModelsIdsv1.includes(selectedCabinetBaseId)/* && !isEqualCoordsTolerance(startModelPosistion, endModelPosistion, 0.001)*/) {
        //     //@ts-ignore
        //     window.threekit.player.selectionSet.setStyle({
        //       outlineColor: '#ff7070'
        //     })
        //     //@ts-ignore
        //     // window.threekit.player.selectionSet.set(errorModelsIdsv1, selectedCabinetBaseId)
        //   } else {
        //     //@ts-ignore
        //     window.threekit.player.selectionSet.setStyle({
        //       outlineColor: '#ffff00'
        //     })
        //   }

        // } else {

        //   //@ts-ignore
        //   window.threekit.player.selectionSet.setStyle({
        //     outlineColor: '#ffff00'
        //   });

        //   // const selectedCabinet = checkIsThereCabinet(ev);
        //   // if (selectedCabinet) {
        //   //   const errorModelsIdsV2 = getIntersectsModelsFromWall();
        //   //   if (errorModelsIdsV2.includes(selectedCabinet["nodeId"])) {
        //   //     //@ts-ignore
        //   //     window.threekit.player.selectionSet.setStyle({
        //   //       outlineColor: '#ff7070'
        //   //     })
        //   //     setTimeout(() => {
        //   //       //@ts-ignore
        //   //       window.threekit.player.selectionSet.setStyle({
        //   //         outlineColor: '#ff7070'
        //   //       })
        //   //     }, 50)
        //   //   } else {
        //   //     //@ts-ignore
        //   //     window.threekit.player.selectionSet.setStyle({
        //   //       outlineColor: '#ffff00'
        //   //     })
        //   //     setTimeout(() => {
        //   //       //@ts-ignore
        //   //       window.threekit.player.selectionSet.setStyle({
        //   //         outlineColor: '#ffff00'
        //   //       })
        //   //     }, 50)
        //   //   }
        //   // }

        // }

        setConfiguration(
          ATTRIBUTES_NAMES_THREEKIT.PHYSICS_CABINETS_WALL,
          true
        );

        if (selectedCabinetBaseId !== undefined) {
          const endModelPosition = getTranslationThreekit({id: selectedCabinetBaseId});
          if (!isEqualCoordsTolerance(startModelPosistion, endModelPosition, 0.001)) {
            checkCountertopCabinetsBase();
            updateDecorativeEnd(PRODUCT_POSITIONS_KEYS.BASE_CABINET);
            updateFrigePanels();
            checkAppliancesConnectors();
            updateDimensionsCabinetsBase();
          }
          checkCornersEmptyRemoveCabinetsBase(dispatch)
        }
        
        selectedCabinetBaseId = undefined;
        // movedModelPosition = undefined;
        return false;
      },
      drag: (ev: PlayerToolEvent) => {

        // const selectedFirstItem = getSelectedFirstItem();

        // if (selectedFirstItem && selectedFirstItem.length > 0) {

          // const selectedModel = checkIsThereCabinetFloor(ev);
          // if (selectedModel) {
          //   movedModelPosition = getPositionMovedFloorModel(selectedModel["nodeId"]);
          // }

          const selectedFloorModel = checkIsThereCabinetBase(ev);
          if (selectedFloorModel) {
            selectedCabinetBaseId = selectedFloorModel["nodeId"];
            runMovedCabinetsBase(() => {});
          }

        // }

        return false;

      },
    },
  };
};

const runMovedCabinetsBase = (addModelToCorner: () => void) => {
  const objWallsForCalculateDistance = getObjWallsForCalculateDistance();
  const intervalsBaseCabinetsForAllWalls =
    getIntervalsBaseCabinetsForAllWalls();

  const changeCabinets = once(
    (
      wallName: WallItemT,
      cornerWallsNames: ArrNamesCornerWallsT,
      moveDirection: MoveDirectionOnWallT,
      moveDistance: number
    ) => {
      const cornerCoords = getInternalCornerCoordsForWalls(cornerWallsNames);
      const isBusyCornerPoint = checkModelBaseInCornerPoint(cornerCoords);
      // перевіряємо наявність кутової моделі в куті
      // якщо в куті вже є кутова модель, то зміни не робимо
      if (isBusyCornerPoint) return;

      // console.log("ADDED CORNER MODEL --- ==== ");
      // console.log("MOVE ALL CABINETS BASE FOR WALL --- ==== ", wallName);
      // console.log("MOVE ALL CABINETS WALL FOR WALL --- ==== ", wallName);
      // console.log('moveDistance --- ==== ',moveDistance);

      


      // cancelDragModelCustom();
      moveCabinetBaseOnWallForEmptyCorner(
        wallName,
        intervalsBaseCabinetsForAllWalls[wallName],
        moveDirection,
        moveDistance
      )
      // moveAllCabinetsBaseOnWall(wallName, moveDirection, moveDistance);
      // moveAllCabinetsWallOnWall(wallName, moveDirection, moveDistance);
      addCornerModelBase(cornerWallsNames);
    }
  );

  let lastCallTime = 0;
  const interval = 1000 / 10; // 10 разів в секунду (1000 мс / 10)

  const runEveryFrameWindow = (timestamp: DOMHighResTimeStamp) => {

    if (!selectedCabinetBaseId) return;

    getDistanceFromObjToWall(
      objWallsForCalculateDistance,
      selectedCabinetBaseId,
      intervalsBaseCabinetsForAllWalls,
      changeCabinets
    );

    if (timestamp - lastCallTime >= interval) {
      if (isOnDimensions()) updateDimensionsCabinetsBase();
      lastCallTime = timestamp;
    }

    if (requestAnimationFrame) requestAnimationFrame(runEveryFrameWindow);
  };
  runEveryFrameWindow(0);
};
