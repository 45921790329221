import { useAttribute } from "@threekit-tools/treble/dist";
import { DeleteAllSvg } from "../../../assets/images/svg/DeleteAll";
import { DimentionsSvg } from "../../../assets/images/svg/Dimentions";
import { HistoryNextStepSvg } from "../../../assets/images/svg/HistoryNextStep";
import { HistoryPrevStepSvg } from "../../../assets/images/svg/HistoryPrevStep";
import { useAppDispatch } from "../../../hooks/useStoreHooks";
import { setModalVisible } from "../../../store/slices/modalsSlice/modalsSlice";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";
import { ButtonIcon } from "../../StandartComponents/Buttons/BtnIcon";
// scss
import s from "./SceneControlsBottom3D.module.scss";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../utils/constants/attributesThreekit";
import { updateDimensionsCabinetsBase } from "../../../functionsConfigurator/dimensions/dimensionsCabinetsBase";
import { updateDimensionsCabinetsWall } from "../../../functionsConfigurator/dimensions/dimensionsCabinetsWall";

// svg icons

export const SceneControlsBottom3D = ({...props}) => {
  const { deleteAllWalls, handleToggleDimentions } = props;
  const dispatch = useAppDispatch();
  const [attributeDimensions, setAttributeDimensions] = useAttribute(
    ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS
  );

  const handleClickPrevHistory = () => {
    console.log('handleClickPrevHistory --- ==== ',);
  }

  const handleClickNextHistory = () => {
    console.log('handleClickNextHistory --- ==== ',);
  }

  const handleClickDimentions = () => {
    if (attributeDimensions !== undefined) {
      if (!attributeDimensions["value"]) {
        updateDimensionsCabinetsBase();
        updateDimensionsCabinetsWall();
      }
      setAttributeDimensions(!attributeDimensions["value"]);
    }
  }

  const handleClickRemoveAllCabinets = () => {
    dispatch(setModalVisible({modalId: MODAL_KEYS.DELETE_ALL_CABINETS, value: true}))
  }

  return (
    <div className={s.sceneControlsBottom3D} onMouseUp={(e) => e.stopPropagation()}>
      {/* <ButtonIcon onClick={handleClickPrevHistory}><HistoryPrevStepSvg /></ButtonIcon> */}
      {/* <ButtonIcon onClick={handleClickNextHistory}><HistoryNextStepSvg /></ButtonIcon> */}
      <ButtonIcon
        onClick={handleClickDimentions}
        active={attributeDimensions !== undefined ? Boolean(attributeDimensions["value"]) : false}
      ><DimentionsSvg /><span>Ruler</span></ButtonIcon>
      <ButtonIcon onClick={handleClickRemoveAllCabinets}><DeleteAllSvg /><span>Delete All</span></ButtonIcon>
    </div>
  );
};
