import { getModalInfo } from "../../../store/selectors/modalsSelectors";
import { ModalsWrap } from "../ModalsWrap/ModalsWrap";
import s from "./ModalFramedType.module.scss";
import { setModalVisible } from "../../../store/slices/modalsSlice/modalsSlice";
import { ButtonMain } from "../../StandartComponents/Buttons/ButtonMain";
import { RadioOptions } from "../../StandartComponents/RadioOptions/RadioOptions";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { FRAMED_OPTIONS_VALUES, FramedOptions } from "../../../utils/constants/framedOptions";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";

export const ModalFramedType = ({ ...props }) => {
  const modalInfo = useAppSelector((state) =>
    getModalInfo(state)(MODAL_KEYS.FRAMED_TYPE)
  );
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(setModalVisible({ modalId: MODAL_KEYS.FRAMED_TYPE, value: false }));
  };

  if (!modalInfo["isVisible"]) return null;

  return (
    <ModalsWrap modalKey={MODAL_KEYS.FRAMED_TYPE}>
      <div className={s.modalFramedType}>
        <div className={s.content}>
          <h3 className={s.title}>Would you like Framed or Framless design</h3>
          <p className={s.descr}>
            You will be able to change this setting in the Cabinet Styling
            section.
          </p>
          <div className={s.formContent}>
            <RadioOptions id="AllCabinets_Frame" values={FramedOptions} defaultValue={FRAMED_OPTIONS_VALUES.FRAMED} sectionId="CabinetBuild" />
          </div>
          <div className={s.btnsWrap}>
            <ButtonMain
              text="Continue"
              onClick={handleCloseModal}
              color="blueDark"
              size="bigSuper"
              bold={true}
            />
          </div>
        </div>
      </div>
    </ModalsWrap>
  );
};
