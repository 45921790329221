import { IMetadata } from "@threekit-tools/treble/dist/types";
import { getСompletedModelsNullNames } from "../../functionsConfigurator/cabinets/getNodesCabinets";
import { NODES_THREEKIT } from "../../utils/constants/nodesNamesThreekit";
import { getItemNodeFromNullModel, getValuesMetadataItemFromNullModel } from "../../utils/threekit/general/getFunctions";
import { getSizeModelBoxFromAssetCabinetBase } from "../cabinets/cabinetsBase/size";
import { getExtremeModels } from "../cabinets/getExtremeModels";
import { ArrPriceDataTableT } from "../../store/slices/dataTablesSlice/dataTablesSliceT";
import { FramedOptionsValuesT } from "../../utils/constants/framedOptions";
import { ColorOptionsValuesT } from "../../utils/constants/colorsRoom";
import { getFrameValueDataTableFromFrameUI } from "../../functionsUI/сompareFrameUIAndFrameDataTable";
import { getColorValueDataTableFromColorUI } from "../../functionsUI/сompareColorUIAndColorDataTable";
import { ArrSummaryPriceDataTableT } from "../../functionsUI/summaryInfo/getArrSummaryProductsForCabinets";
import { THREEKIT_IMAGES_URL } from "../../utils/threekit/threekitConfig/threekitConfig";

const ID_TOE_KICK = "1111";

export const getCountToeKickFromLength = (lengthToeKick: number): number => {
  return Math.ceil(lengthToeKick / 2.4384);
}

export const isItemNameCorner = (modelName: string): boolean => {
  return modelName.includes("Corner")
}

const getSummaryLengthToeKickCabinetsPosition = (regExpCabinetsModels: NODES_THREEKIT): number => {
  const allNullForСabinets = getСompletedModelsNullNames(regExpCabinetsModels);
  const extremeModelsСabinets = getExtremeModels(regExpCabinetsModels);
  let summLengthToeKick = 0;
  if (allNullForСabinets.length < 1) return summLengthToeKick;
  allNullForСabinets.forEach((nameNullModel) => {
    const sizeModelBox = getSizeModelBoxFromAssetCabinetBase(nameNullModel);
    const itemNodeFromNullModel = getItemNodeFromNullModel({name: nameNullModel})
    const metadataItem: IMetadata = getValuesMetadataItemFromNullModel({name: nameNullModel});

    let lengthToeKickModel = sizeModelBox["x"];

    if (Object.keys(extremeModelsСabinets).includes(nameNullModel)) {
      const isLeftPointFree = extremeModelsСabinets[nameNullModel]["isLeftPointFree"];
      const isRightPointFree = extremeModelsСabinets[nameNullModel]["isRightPointFree"];
      if (isLeftPointFree && isRightPointFree) {
        lengthToeKickModel += (sizeModelBox["z"] - 0.01999) * 2
      } else {
        lengthToeKickModel += (sizeModelBox["z"] - 0.01999)
      }
    }

    if (isItemNameCorner(itemNodeFromNullModel["name"])) {
      if (Object.keys(metadataItem).length > 0 && metadataItem.hasOwnProperty("lengthToeKickFront") && metadataItem.hasOwnProperty("lengthToeKickSide")) {
        lengthToeKickModel = Number(metadataItem["lengthToeKickFront"]);
        if (Object.keys(extremeModelsСabinets).includes(nameNullModel)) {
          const isLeftPointFree = extremeModelsСabinets[nameNullModel]["isLeftPointFree"];
          const isRightPointFree = extremeModelsСabinets[nameNullModel]["isRightPointFree"];
          if (isLeftPointFree && isRightPointFree) {
            lengthToeKickModel += Number(metadataItem.hasOwnProperty("lengthToeKickSide")) * 2;
          } else {
            lengthToeKickModel += Number(metadataItem.hasOwnProperty("lengthToeKickSide"));
          }
        }
      }
    }

    summLengthToeKick += lengthToeKickModel;

  });

  return summLengthToeKick
}

export const getSummaryToeKick = (
  priceDatatable: ArrPriceDataTableT,
  frameValue: FramedOptionsValuesT,
  colorValueCabinetsBase: ColorOptionsValuesT,
  colorValueCabinetsIsland: ColorOptionsValuesT,
): ArrSummaryPriceDataTableT => {

  const summLengthToeKickCabinetsBase = getSummaryLengthToeKickCabinetsPosition(NODES_THREEKIT.MODEL_CABINET_BASE);
  const summLengthToeKickCabinetsIsland = getSummaryLengthToeKickCabinetsPosition(NODES_THREEKIT.MODEL_CABINET_ISLAND);

  if (summLengthToeKickCabinetsBase === 0 && summLengthToeKickCabinetsIsland === 0) return [];

  const convertedFrameValue = getFrameValueDataTableFromFrameUI(frameValue);
  const convertedColorValueCabinetsBase = getColorValueDataTableFromColorUI(colorValueCabinetsBase);
  const convertedColorValueCabinetsIsland = getColorValueDataTableFromColorUI(colorValueCabinetsIsland);

  if (convertedColorValueCabinetsBase === convertedColorValueCabinetsIsland) {
    const productInfoFromDataTable = priceDatatable.find(
      (objProduct) =>
        objProduct["ItemNumber"] === ID_TOE_KICK &&
        objProduct["Framed"] === convertedFrameValue &&
        objProduct["Color"] === convertedColorValueCabinetsBase
    );
    if (!!productInfoFromDataTable) {
      return [{
        ...productInfoFromDataTable,
        thumbnail: `${THREEKIT_IMAGES_URL}${productInfoFromDataTable["thumbnail"]}`,
        count: getCountToeKickFromLength(summLengthToeKickCabinetsBase + summLengthToeKickCabinetsIsland),
      }];
    }
  }

  let resultArr: ArrSummaryPriceDataTableT = [];

  if (summLengthToeKickCabinetsBase !== 0) {
    const productInfoFromDataTableCabinetsBase = priceDatatable.find(
      (objProduct) =>
        objProduct["ItemNumber"] === ID_TOE_KICK &&
        objProduct["Framed"] === convertedFrameValue &&
        objProduct["Color"] === convertedColorValueCabinetsBase
    );
    if (!!productInfoFromDataTableCabinetsBase) {
      resultArr.push({
        ...productInfoFromDataTableCabinetsBase,
        thumbnail: `${THREEKIT_IMAGES_URL}${productInfoFromDataTableCabinetsBase["thumbnail"]}`,
        count: getCountToeKickFromLength(summLengthToeKickCabinetsBase),
      });
    }
  }

  if (summLengthToeKickCabinetsIsland !== 0) {
    const productInfoFromDataTableCabinetsIsland = priceDatatable.find(
      (objProduct) =>
        objProduct["ItemNumber"] === ID_TOE_KICK &&
        objProduct["Framed"] === convertedFrameValue &&
        objProduct["Color"] === convertedColorValueCabinetsIsland
    );
    if (!!productInfoFromDataTableCabinetsIsland) {
      resultArr.push({
        ...productInfoFromDataTableCabinetsIsland,
        thumbnail: `${THREEKIT_IMAGES_URL}${productInfoFromDataTableCabinetsIsland["thumbnail"]}`,
        count: getCountToeKickFromLength(summLengthToeKickCabinetsIsland),
      });
    }
  }

  return resultArr;

}