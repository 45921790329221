import { CONNECTORS_CABINETS_BASE } from "../../../utils/constants/connectors";
import { ModelsName_NodesT } from "../../../utils/constants/nodesNamesThreekit";
import { getInstanceIdAssetFromNullModel, getNameNodeThreekit } from "../../../utils/threekit/general/getFunctions";
import { setActiveTagsThreekit } from "../../../utils/threekit/general/setFunctions";
import { isFeaturesModelNullName } from "../../features/general";
import { ArrWallRangesT, WallRangeT } from "../../intervals/getIntervalsInfoOnWall";
import { getIntervalsBaseCabinetsForAllWalls } from "../../intervals/getIntervalsInfoOnWallBase";
import { checkIfCornerCabinetFromNullName } from "./checkCornersCabinetsBase";
import { getSizeModelBoxFromAssetCabinetBase } from "./size";

type SideConnectorsT = {
  leftConnectors: boolean | undefined;
  rightConnectors: boolean | undefined;
}

const updateConnectorsTagsCabinetsBase = (objSideConnectors: SideConnectorsT, instanceIdConnectorsParentAsset: string) => {
  if (objSideConnectors["leftConnectors"] !== undefined) {
    setActiveTagsThreekit({
      from: instanceIdConnectorsParentAsset,
      name: CONNECTORS_CABINETS_BASE.LEFT,
      value: objSideConnectors["leftConnectors"],
    })
  }
  if (objSideConnectors["rightConnectors"] !== undefined) {
    setActiveTagsThreekit({
      from: instanceIdConnectorsParentAsset,
      name: CONNECTORS_CABINETS_BASE.RIGHT,
      value: objSideConnectors["rightConnectors"],
    })
  }
}

const isIntervalEmpty = (
  interval: WallRangeT
): boolean => interval["empty"];

const isIntervalSize = (
  interval: WallRangeT,
  widthSelectedModel: number
): boolean => {
  const intervalLength = interval["range"][1] - interval["range"][0];
  return intervalLength - widthSelectedModel >= -0.005;
  // return interval["range"][1] - interval["range"][0] >= widthSelectedModel
  // return Math.abs(intervalLength - widthSelectedModel) < 0.005;
}

const isNamesMatch = (
  interval: WallRangeT,
  nullNameSelectedModel: ModelsName_NodesT
): boolean => interval["name"] === nullNameSelectedModel;

export const isConnectorsSideTrue = (
  intervalNeabor: WallRangeT | null | undefined,
  intervalNeaborNext: WallRangeT | null | undefined,
  nullNameSelectedModel: ModelsName_NodesT,
  widthSelectedModel: number
): boolean => {
  return (
    intervalNeabor !== null &&
    intervalNeabor !== undefined &&
    (
      (isIntervalEmpty(intervalNeabor) &&
        isIntervalSize(intervalNeabor, widthSelectedModel)) ||
      (isIntervalEmpty(intervalNeabor) &&
        !isIntervalSize(intervalNeabor, widthSelectedModel) &&
        intervalNeaborNext !== null &&
        intervalNeaborNext !== undefined &&
        isNamesMatch(intervalNeaborNext, nullNameSelectedModel)) ||
      (!isIntervalEmpty(intervalNeabor) &&
        isNamesMatch(intervalNeabor, nullNameSelectedModel))
    )
  );
};

export const checkCabinetsBaseConnectors = (idSelectedModel: string) => {

  const nullNameSelectedModel = getNameNodeThreekit({id: idSelectedModel}) as ModelsName_NodesT;
  const sizeSelectedModel = getSizeModelBoxFromAssetCabinetBase(nullNameSelectedModel);

  // const completedModelsNullNames = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_BASE);
  // const extremeModels = getExtremeModels(NODES_THREEKIT.MODEL_CABINET_BASE);
  // const arrNullNamesExtremeModels = Object.keys(
  //   extremeModels
  // ) as Array<keyof typeof extremeModels>;
  // completedModelsNullNames.forEach((completedModelNullName) => {
  //   console.log('completedModelNullName --- ==== ',completedModelNullName);
  //   console.log('extremeModels[completedModelNullName] --- ==== ',extremeModels[completedModelNullName]);

  //   if (completedModelNullName === nullNameSelectedModel) {
  //     return;
  //   }

  //   const instanceIdAssetCompletedModel = getInstanceIdAssetFromNullModel({name: completedModelNullName});


  //   if (arrNullNamesExtremeModels.includes(completedModelNullName)) {
  //     console.log('Модель крайня треба перевіряти які конектори мають бути ввімкненими --- ==== ',);

  //     if (extremeModels[completedModelNullName]["isLeftPointFree"]) {
  //       setActiveTagsThreekit({
  //         from: instanceIdAssetCompletedModel,
  //         name: CONNECTORS_CABINETS_BASE.LEFT,
  //         value: true,
  //       })
  //       setActiveTagsThreekit({
  //         from: instanceIdAssetCompletedModel,
  //         name: CONNECTORS_CABINETS_BASE.RIGHT,
  //         value: false,
  //       })
  //     }

  //     if (extremeModels[completedModelNullName]["isRightPointFree"]) {
  //       setActiveTagsThreekit({
  //         from: instanceIdAssetCompletedModel,
  //         name: CONNECTORS_CABINETS_BASE.LEFT,
  //         value: false,
  //       })
  //       setActiveTagsThreekit({
  //         from: instanceIdAssetCompletedModel,
  //         name: CONNECTORS_CABINETS_BASE.RIGHT,
  //         value: true,
  //       })
  //     }

  //   } else {
  //     console.log('Модель всередині, Всі коннектори мають бути вимкнені --- ==== ',);

  //     setActiveTagsThreekit({
  //       from: instanceIdAssetCompletedModel,
  //       name: CONNECTORS_CABINETS_BASE.LEFT,
  //       value: false,
  //     })
  //     setActiveTagsThreekit({
  //       from: instanceIdAssetCompletedModel,
  //       name: CONNECTORS_CABINETS_BASE.RIGHT,
  //       value: false,
  //     })

  //   }

  // //   window.threekit.player.scene.set({
  // //     from: instanceIdAssetCompletedModel,
  // //     name: "connector_right",
  // //     //@ts-ignore
  // //     plug: "Properties",
  // //     //@ts-ignore
  // //     property: "tags",
  // //   }, [])
  // //   window.threekit.player.scene.set({
  // //     from: instanceIdAssetCompletedModel,
  // //     name: "connector_left",
  // //     //@ts-ignore
  // //     plug: "Properties",
  // //     //@ts-ignore
  // //     property: "tags",
  // //   }, [])

  // // const a22222 = window.threekit.player.scene.get({
  // //   from: instanceIdAssetCompletedModel,
  // //   name: "connector_right",
  // //   //@ts-ignore
  // //   plug: "Properties",
  // //   //@ts-ignore
  // //   property: "tags",
  // // })
  // // console.log('a22222 --- ==== ',a22222);
  // // const a333333 = window.threekit.player.scene.get({
  // //   from: instanceIdAssetCompletedModel,
  // //   name: "connector_left",
  // //   //@ts-ignore
  // //   plug: "Properties",
  // //   //@ts-ignore
  // //   property: "tags",
  // // })
  // // console.log('a333333 --- ==== ',a333333);


  // })

  const intervalsBaseCabinetsForAllWalls = getIntervalsBaseCabinetsForAllWalls();
  Object.values(intervalsBaseCabinetsForAllWalls).forEach((arrWallIntervals: ArrWallRangesT) => {

    let prevInterval: WallRangeT | null = null;
    let prevFilledInterval: WallRangeT | null = null;

    arrWallIntervals.forEach((interval, indx) => {

      let { empty, range, name } = interval;
      const nextInterval = arrWallIntervals[indx + 1];
      const nextFilledInterval = nextInterval !== undefined && nextInterval["empty"] ? arrWallIntervals[indx + 2] : arrWallIntervals[indx + 1];
      let objSideConnectors: SideConnectorsT = {
        leftConnectors: undefined,
        rightConnectors: undefined,
      }

      if (!empty && !!name && !isFeaturesModelNullName(name)) {

        const instanceIdAssetSelectedModel = getInstanceIdAssetFromNullModel({name: name});

        // якщо це перша модель на стіні, і вона є кутовою моделлю
        // то перевіряємо тільки праву сторону
        if (indx === 0 && checkIfCornerCabinetFromNullName(name)) {

          if (
            isConnectorsSideTrue(
              nextInterval,
              nextFilledInterval,
              nullNameSelectedModel,
              sizeSelectedModel["x"]
            )
          ) {
            objSideConnectors["rightConnectors"] = true;
          } else {
            objSideConnectors["rightConnectors"] = false;
          }

          prevInterval = interval;
          prevFilledInterval = interval;
          return updateConnectorsTagsCabinetsBase(objSideConnectors, instanceIdAssetSelectedModel)

        }

        // якщо це остання модель на стіні, і вона є кутовою моделлю
        // то перевіряємо тільки ліву сторону
        if (indx === arrWallIntervals.length - 1 && checkIfCornerCabinetFromNullName(name)) {

          if (
            isConnectorsSideTrue(
              prevInterval,
              prevFilledInterval,
              nullNameSelectedModel,
              sizeSelectedModel["x"]
            )
          ) {
            objSideConnectors["leftConnectors"] = true
          } else {
            objSideConnectors["leftConnectors"] = false;
          }

          prevInterval = interval;
          prevFilledInterval = interval;
          return updateConnectorsTagsCabinetsBase(objSideConnectors, instanceIdAssetSelectedModel)

        }

        // якщо це є виділеною моделлю, то вмикаємо для неї всі конектори
        if (name === nullNameSelectedModel) {

          objSideConnectors["leftConnectors"] = true;
          objSideConnectors["rightConnectors"] = true;
          prevInterval = interval;
          prevFilledInterval = interval;
          return updateConnectorsTagsCabinetsBase(objSideConnectors, instanceIdAssetSelectedModel)

        }

        // перевіряємо ліву сторону
        if (
          isConnectorsSideTrue(
            prevInterval,
            prevFilledInterval,
            nullNameSelectedModel,
            sizeSelectedModel["x"]
          )
          // prevInterval !== null &&
          // (
          //   (prevInterval["empty"] && (prevInterval["range"][1] - prevInterval["range"][0]) >= sizeSelectedModel["x"]) ||
          //   (prevInterval["empty"] && (prevInterval["range"][1] - prevInterval["range"][0]) < sizeSelectedModel["x"] && prevFilledInterval !== null && prevFilledInterval["name"] === nullNameSelectedModel) ||
          //   (!prevInterval["empty"] && prevInterval["name"] === nullNameSelectedModel)
          // )
        ) {
          objSideConnectors["leftConnectors"] = true
        } else {
          objSideConnectors["leftConnectors"] = false;
        }
        
        // перевіряємо праву сторону
        if (
          isConnectorsSideTrue(
            nextInterval,
            nextFilledInterval,
            nullNameSelectedModel,
            sizeSelectedModel["x"]
          )
          // nextInterval !== undefined &&
          // (
          //   (nextInterval["empty"] && (nextInterval["range"][1] - nextInterval["range"][0]) >= sizeSelectedModel["x"]) ||
          //   (nextInterval["empty"] && (nextInterval["range"][1] - nextInterval["range"][0]) < sizeSelectedModel["x"] && nextFilledInterval !== undefined && nextFilledInterval["name"] === nullNameSelectedModel) ||
          //   (!nextInterval["empty"] && nextInterval["name"] === nullNameSelectedModel)
          // ) 
        ) {
          objSideConnectors["rightConnectors"] = true;
        } else {
          objSideConnectors["rightConnectors"] = false;
        }

        updateConnectorsTagsCabinetsBase(objSideConnectors, instanceIdAssetSelectedModel);

        prevFilledInterval = interval;

      }

      if (!empty && !!name && isFeaturesModelNullName(name)) {
        prevFilledInterval = interval;
      }

      prevInterval = interval;

    })
  })

}