import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { CabinetsAndFeatures_NodesT, ModelCabinetWallT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getСompletedModelsNullNames } from "./../getNodesCabinets";
import { getModelPositionFromName } from "../../../utils/threekit/tools/toolsDragCabinetsIsland/generalFunc";
import { getTranslationThreekit } from "../../../utils/threekit/general/getFunctions";
import { getSizeModelBoxFromAssetCabinetWall } from "./size";
import { CABINETS_WALL_BOTTOM_POSITION, CABINETS_WALL_STANDART_HEIGHT } from "../constatns";

export const getDefaultPositionYCabinetsWall = (): number => {
  return CABINETS_WALL_BOTTOM_POSITION + CABINETS_WALL_STANDART_HEIGHT
}

export type ObjPositionCabinetsWallT = {
  [key in ModelCabinetWallT]: ICoordinates;
};
/**
 * Функція для визначення позицій всіх встановлених настінних моделей.
 *
 * @returns {ObjPositionCabinetsWallT} Об'ект з інформацією про позиції всіх встановлених на сцені настінних моделей.
 */
export const getPositionForAllCabinetsWall = (): ObjPositionCabinetsWallT => {
  const allNullForСabinetsWall =
    getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_WALL);
  return allNullForСabinetsWall.reduce(
    (objExtremePointsAcc: ObjPositionCabinetsWallT, modelName) => {
      const modelPos = getModelPositionFromName(modelName);
      return {
        ...objExtremePointsAcc,
        [modelName]: modelPos
      };
    },
    {}
  );
}

/**
 * Функція перевіряє чи настінна модель знаходиться на дефолтній висоті від підлоги.
 *
 * @param {CabinetsAndFeatures_NodesT} nullName Name для Null моделі, яку перевіряємо.
 * @returns {Boolean} true - на дефолтній висоті, false - змыщена вверх або вниз.
 */
export const checkDefaultPositionCabinetsWall = (
  nullName: CabinetsAndFeatures_NodesT
): boolean => {
  const positionCabinetWall = getTranslationThreekit({ name: nullName });
  const sizeCabinetWall = getSizeModelBoxFromAssetCabinetWall(nullName);
  return (
    Math.abs(
      positionCabinetWall["y"] + sizeCabinetWall["y"] - getDefaultPositionYCabinetsWall()
    ) <= 0.005
  );
};

/**
 * Функція перевіряє чи настінна модель знаходиться на дефолтній висоті від підлоги.
 *
 * @param {ICoordinates} positionCabinetWall Name для Null моделі, яку перевіряємо.
 * @param {ICoordinates} sizeCabinetWall Name для Null моделі, яку перевіряємо.
 * @returns {Boolean} true - на дефолтній висоті, false - змыщена вверх або вниз.
 */
export const checkDefaultPositionCabinetsWallFromParams = (
  positionCabinetWall: ICoordinates,
  sizeCabinetWall: ICoordinates,
): boolean => {
  return (
    Math.abs(
      positionCabinetWall["y"] + sizeCabinetWall["y"] -
        (CABINETS_WALL_BOTTOM_POSITION + CABINETS_WALL_STANDART_HEIGHT)
    ) <= 0.005
  );
};