export interface ModalInterfaceI {
  isVisible: boolean;
  isFirstShow?: boolean;
  message?: string;
}

export enum MODAL_KEYS {
  DECORATIVE_END = "decorativeEnd",
  HELP_2D = "help2D",
  HELP_3D = "help3D",
  FRAMED_TYPE = "framedType",
  PRICE = "price",
  CONTINUE_2D = "continue2D",
  EMAIL_SUCCESS = "sendEmailSuccess",
  EMAIL_ERROR = "sendEmailError",
  LOADER = "loader",
  DELETE_ALL_CABINETS = "deleteAllCabinets",
  MESSAGE = "message",
}

export type ModalsInfoT = {
  [key in MODAL_KEYS]: ModalInterfaceI
}

export type KeysModalsT = keyof ModalsInfoT;