import {
  useThreekitInitStatus,
  DeleteIcon,
  TrashCanIcon,
  WindowIcon,
  DoorIcon,
  OpeningIcon,
  AngleIcon,
  RulerIcon,
  UndoIcon,
  RedoIcon,
  CursorIcon,
  LayoutIcon,
  WallIcon,
  useConfigurator,
  useAttribute,
  useNestedConfigurator,
} from "@threekit-tools/treble";
import { debounce } from "lodash";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import useWindowSize, { IWindowSize } from "../../../hooks/useWindowSize";
import { SceneControlsGroup2D } from "../../SceneControls/SceneControlsGroup2D/SceneControlsGroup2D";

import s from "./PlayerRoomBuilder2D.module.scss";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../utils/constants/attributesThreekit";
import { getAppliedConfigurationThreekitAttribute, getEvalNode } from "../../../utils/threekit/general/getFunctions";
import { ATTRIBUTE_NAMES } from "../../../utils/constants/attributesThreekitRoomBuilder";

export const PlayerRoomBuilder2D = ({ ...props }) => {
  const {
    //  Blueprint canvas
    canvasRef,
    //  Enhanced Attributes
    walls,
    windows,
    doors,
    openings,
    selectedElement,
    //  State Managers
    mode,
    dimensions,
    angleSnapping,
    reset,
    deleteElement,
    draw,
  } = props;

  const windowSize = useWindowSize();

  const player2DWrap = useRef<HTMLDivElement>(null);
  const [player2DWrapSize, setPlayer2DWrapSize] = useState<IWindowSize>({
    width: undefined,
    height: undefined,
  });
  const [attributeWalls, setAttributeWalls]: any = useAttribute(
    ATTRIBUTES_NAMES_THREEKIT.WALLS
  );

  useEffect(() => {
    setPlayer2DWrapSize({
      width: player2DWrap["current"]?.clientWidth,
      height: player2DWrap["current"]?.clientHeight,
    });
  }, [windowSize["width"], windowSize["height"]]);

  const funcDebonce = debounce(() => draw(), 1);

  funcDebonce();

  // useLayoutEffect(() => {

  //   if (attributeWalls !== undefined) {
  //     // @ts-ignore
  //     window.threekit.player.evaluate().then(() => {
  //       const appliedConfigurationWall = getAppliedConfigurationThreekitAttribute(
  //         ATTRIBUTES_NAMES_THREEKIT.WALLS
  //       );
  //       const evalNodeWall = getEvalNode({ id: appliedConfigurationWall[appliedConfigurationWall.length - 1] });
  //       if (evalNodeWall) {
  //         const wallConfigurator = evalNodeWall["configurator"];
  //         // @ts-ignore
  //         const currentConfiguration = wallConfigurator["configuration"];
  //         // console.log("currentConfiguration --- ==== ", currentConfiguration);
  //         const updatedConfiguration = {
  //           ...currentConfiguration,
  //           [ATTRIBUTES_NAMES_THREEKIT.SYSTEM_UPDATE_FEATURES]: !currentConfiguration[ATTRIBUTES_NAMES_THREEKIT.SYSTEM_UPDATE_FEATURES],
  //         };
  //         // console.log("updatedConfiguration --- ==== ", updatedConfiguration);
  //         wallConfigurator.setConfiguration(updatedConfiguration);
  //       }
  //     })
      
  //   }

  // }, [attributeWalls?.value.length]);

  // TEST 163 task
  // useLayoutEffect(() => {

  //   if (attributeWalls !== undefined) {
  //     // @ts-ignore
  //     window.threekit.player.evaluate().then(() => {
  //       const appliedConfigurationWall = getAppliedConfigurationThreekitAttribute(
  //         ATTRIBUTES_NAMES_THREEKIT.WALLS
  //       );
  //       const evalNodeWall = getEvalNode({ id: appliedConfigurationWall[appliedConfigurationWall.length - 1] });
  //       if (evalNodeWall) {
  //         const wallConfigurator = evalNodeWall["configurator"];
  //         // @ts-ignore
  //         const currentConfiguration = wallConfigurator["configuration"];
  //         const updatedConfiguration = {
  //           ...currentConfiguration,
  //           [ATTRIBUTE_NAMES.wallIsVisible]: false,
  //         };
  //         wallConfigurator.setConfiguration(updatedConfiguration);
  //       }
  //     })
      
  //   }

  // }, [attributeWalls?.value.length]);

  return (
    <div className={s.player2DWrap} ref={player2DWrap}>
      <div className={s.player2DMenu}>
        {/* {mode.options.map((opt: any) => (
          <React.Fragment key={opt["value"]}>
            {!opt.selected && (
              <button key={opt.value} type="button" onClick={opt.handleSelect}>
                {opt.label}
              </button>
            )}
          </React.Fragment>
        ))} */}
        {/* {windows?.values.map((window: any) => (
          <button
            key={window.name}
            type="button"
            //@ts-ignore
            onClick={() => window.add()}
            className={`flex flex-row items-center capitalize cursor-pointer border-none py-4 pl-5 text-white text-left bg-teal-700 hover:bg-teal-600`}
          >
            <div>
              <WindowIcon color="white" />
            </div>
            <div className="ml-2">{window.name}</div>
          </button>
        ))} */}
        {/* {doors?.values.map((door: any) => (
          <button
            key={door.name}
            type="button"
            //@ts-ignore
            onClick={() => door.add()}
            className={`flex flex-row items-center capitalize cursor-pointer border-none py-3 pl-5 text-white bg-teal-700 hover:bg-teal-600`}
          >
            <div>
              <DoorIcon color="white" />
            </div>
            <div className="ml-2">{door.name}</div>
          </button>
        ))} */}
        {/* {openings?.values.map((opening: any) => (
          <button
            key={opening.name}
            type="button"
            //@ts-ignore
            onClick={() => opening.add()}
            className={`flex flex-row items-center capitalize cursor-pointer border-none py-3 pl-5 text-white bg-teal-700 hover:bg-teal-600`}
          >
            <div>
              <OpeningIcon color="white" />
            </div>
            <div className="ml-2">{opening.name}</div>
          </button>
        ))}*/}
        {/* <button
          type="button"
          className={`flex flex-row items-center capitalize border-none py-3 pl-5 text-white bg-teal-700 hover:bg-teal-600`}
        >
          <div>
            <LayoutIcon color="white" />
          </div>
          <div className="ml-2">Layout Templates</div>
        </button> */}
      </div>
      <div className={s.player2DCanvasWrap}>
        <canvas
          ref={canvasRef}
          width={player2DWrapSize["width"]}
          height={player2DWrapSize["height"]}
        />
        {/* <div className={s.player2DCanvasBottomControls}>
          <button
            type="button"
            className={`m-2 border-none cursor-pointer bg-white hover:bg-teal-600`}
          >
            <UndoIcon />
          </button>
          <button
            type="button"
            className={`m-2 border-none cursor-pointer bg-white hover:bg-teal-600`}
          >
            <RedoIcon />
          </button>
          <button
            type="button"
            onClick={() => angleSnapping.handleToggle()}
            className={`m-2 border-none cursor-pointer ${
              angleSnapping.enabled ? 'bg-teal-600' : 'bg-white'
            } hover:bg-teal-600`}
          >
            <AngleIcon />
          </button>
          <button
            type="button"
            onClick={() => dimensions.handleToggle()}
            className={`m-2 border-none cursor-pointer bg-white hover:bg-teal-600`}
          >
            <RulerIcon />
          </button>
          <button
            type="button"
            onClick={() => deleteAll()}
            className={`m-2 border-none cursor-pointer bg-white hover:bg-teal-600`}
          >
            <TrashCanIcon />
          </button>
        </div> */}
        {selectedElement && (
          <SceneControlsGroup2D
            walls={walls}
            windows={windows}
            doors={doors}
            openings={openings}

            selectedElement={selectedElement}
            deleteElement={deleteElement}
          />
        )}
      </div>
    </div>
  );
};
