import { useAttribute, useThreekitInitStatus } from "@threekit-tools/treble/dist";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getPriceDataTableFromThreekit } from "../../api/apiThreekit";
import { Loading } from "../../components/Loading/Loading";
import { Logo } from "../../components/Logo/Logo";
import { MainMenu } from "../../components/MainMenu/MainMenu";
import { PlayerThreeKit } from "../../components/PlayerThreeKit/PlayerThreeKit";
import { CabinetControls } from "../../components/SceneControls/CabinetControls/CabinetControls";
import { SceneControlsBottom3D } from "../../components/SceneControls/SceneControlsBottom3D/SceneControlsBottom3D";
import { SceneControlsTop } from "../../components/SceneControls/SceneControlsTop/SceneControlsTop";
import { SceneInfoText } from "../../components/SceneControls/SceneInfoText/SceneInfoText";
import { SectionsParams } from "../../components/SectionsParams/SectionsParams";
import { setPositionForAllCabinets } from "../../functionsConfigurator/cabinets/position";
import { getSavedConfiguration } from "../../functionsConfigurator/saveConfiguration/getSavedConfiguration";
import { useAppDispatch, useAppSelector } from "../../hooks/useStoreHooks";
import { setPriceTable } from "../../store/slices/dataTablesSlice/dataTablesSlice";
import { setModalVisible } from "../../store/slices/modalsSlice/modalsSlice";
import {
  setAllSettingConfig,
  setReconfigurationSettings,
  setSavedConfiguration2D,
} from "../../store/slices/settingsUI/settingsUISlice";
import InitializationProvider from "../../utils/initializationProvider/initializationProvider";

// scss
import s from "./CabinetsBuilder3D.module.scss";
import { MODAL_KEYS } from "../../store/slices/modalsSlice/modalsSliceT";
import {
  getActiveSettingUI,
  getPriceDataTable,
} from "../../store/selectors/settingsUISelectors";
import { ModalsGlobal } from "../../components/Modals/ModalsGlobal/ModalsGlobal";
import { ROUTES } from "../../router/RouterProvider/RouterProvider";
import { handlePointerLeaveInCanvas } from "../../utils/other/handlePointerLeaveInCanvas";
import { runTrackingConfigurationChanges } from "../../utils/threekit/trackingConfigurationChanges";
import { TOOLS_WHALEN, addCustomTool, deactivateToolThreekit } from "../../utils/threekit/tools/toolsGeneral";
import { dragCabinetsBase } from "../../utils/threekit/tools/toolsDragCabinetsBase/toolsDragCabinetsBase";
import { dragCabinetsIsland } from "../../utils/threekit/tools/toolsDragCabinetsIsland/toolsDragCabinetsIsland";
import { dragCabinetsWall } from "../../utils/threekit/tools/toolsDragCabinetsWall/toolsDragCabinetsWall";
import { selectObjectThreeKit } from "../../utils/threekit/tools/toolsSelectObjectInInRoom";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";
import { buildWallFromData, getAllWallsNode } from "../../functionsConfigurator/wallsAndFloor/buildWallFromData";
import { setInitialRoomCameraPosition } from "../../functionsConfigurator/camera/setInitialRoomCameraPosition";
import { getConfiguration2D } from "../../functionsConfigurator/configurator2D/getConfiguration2D";
import { revertBooleanAttributeThreekit } from "../../utils/threekit/revertBooleanAttribute";

export const CabinetsBuilder3D = () => {
  const dispatch = useAppDispatch();
  const hasLoadedThreekit = useThreekitInitStatus();
  const [attributeWalls, setAttributeWalls] = useAttribute(
    ATTRIBUTES_NAMES_THREEKIT.WALLS
  );
  const [attributeWindows, setAttributeWindows] = useAttribute(
    ATTRIBUTES_NAMES_THREEKIT.WINDOWS
  );
  const [attributeDoors, setAttributeDoors] = useAttribute(
    ATTRIBUTES_NAMES_THREEKIT.DOORS
  );
  const [attributeOpenings, setAttributeOpenings] = useAttribute(
    ATTRIBUTES_NAMES_THREEKIT.OPENINGS
  );

  const { hash, key, pathname, search, state } = useLocation();
  const searchParams = new URLSearchParams(search);
  const reconfigurationShortId = searchParams.get("shortId");
  const activeSettingUI = useAppSelector(getActiveSettingUI);
  const [isLoadConfiguration, setLoadConfiguration] = useState<boolean>(false);
  const [isLoad, setLoad] = useState<boolean>(false);

  // Обпрацювання браузерної події оновлення або закриття сторінки
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Perform actions before the component unloads
      event.preventDefault();
      event.returnValue = "Refreshing the page or returning to the browser will cause all progress to be lost.";
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Обпрацювання браузерної події повернення на попередню сторінку по історії
  useEffect(() => {
    const handlePopstate = (event: PopStateEvent) => {
      // window.history.pushState(null, "", window.location.href);
      // Perform actions before the component unloads
      event.preventDefault();
      dispatch(setModalVisible({modalId: MODAL_KEYS.CONTINUE_2D, value: true}))
    };
    window.history.pushState(null, "", window.location.href);
    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);
  
  useEffect(() => {
    if (hasLoadedThreekit) {
      handlePointerLeaveInCanvas();
      let modalsVisible = true;
      if (reconfigurationShortId) {
        modalsVisible = false
      }
      dispatch(setModalVisible({ modalId: MODAL_KEYS.HELP_3D, value: modalsVisible }));
      dispatch(setModalVisible({ modalId: MODAL_KEYS.FRAMED_TYPE, value: modalsVisible }));
      // dispatch(setAllSettingConfig())
      // getPriceDataTableFromThreekit().then((res) => {
      //   dispatch(setPriceTable(res));
      // })
    }
  }, [hasLoadedThreekit]);

  useEffect(() => {

    if (hasLoadedThreekit) {

      if (reconfigurationShortId) {

        setLoadConfiguration(false);

        getSavedConfiguration({ reconfigurationShortId }).then((res) => {

          dispatch(
            setReconfigurationSettings(res["metadata"]["objectActiveParams"])
          );
          setPositionForAllCabinets(res["metadata"]["transformAllCabinets"]);

          console.log("RUN BUILD WALL --- ==== ");
          if (
            !!attributeWalls &&
            !!attributeWindows &&
            !!attributeDoors &&
            !!attributeOpenings &&
            Object.keys(getAllWallsNode()).length < 1
          ) {
            buildWallFromData(
              attributeWalls["value"],
              attributeWindows["value"],
              attributeDoors["value"],
              attributeOpenings["value"]
            ).then(() => {
              // Для запуску виконання кастомного скрипта на асетах вікон, дверей та проемів
              return revertBooleanAttributeThreekit(ATTRIBUTES_NAMES_THREEKIT.SYSTEM_UPDATE_FEATURES);
            }).then(() => {
              setLoadConfiguration(true);
            });
          }

          setInitialRoomCameraPosition()

        });

      } else {

        setLoadConfiguration(true);

        console.log("RUN BUILD WALL --- ==== ");
        if (
          !!attributeWalls &&
          !!attributeWindows &&
          !!attributeDoors &&
          !!attributeOpenings &&
          Object.keys(getAllWallsNode()).length < 1
        ) {
          buildWallFromData(
            attributeWalls["value"],
            attributeWindows["value"],
            attributeDoors["value"],
            attributeOpenings["value"]
          );
        }
      }

      runTrackingConfigurationChanges();
      addCustomTool(dragCabinetsBase(dispatch));
      addCustomTool(dragCabinetsIsland);
      addCustomTool(dragCabinetsWall(dispatch));
      addCustomTool(selectObjectThreeKit(dispatch));
    }

  }, [hasLoadedThreekit]);

  useEffect(() => {
    return () => {
      if (hasLoadedThreekit) {
        deactivateToolThreekit(TOOLS_WHALEN.DRAG_CABINETS_BASE);
        deactivateToolThreekit(TOOLS_WHALEN.DRAG_CABINETS_ISLAND);
        deactivateToolThreekit(TOOLS_WHALEN.DRAG_CABINETS_WALL);
        deactivateToolThreekit(TOOLS_WHALEN.SELECT_MODELS);
      }
    }
  }, [])

  useLayoutEffect(() => {
    if (!reconfigurationShortId) setLoadConfiguration(true);
  }, [reconfigurationShortId])

  const priceDataTable = useAppSelector(getPriceDataTable);
  useEffect(() => {
    if (priceDataTable.length < 1) {
      getPriceDataTableFromThreekit().then((res) => {
        dispatch(setPriceTable(res));
      });
    }
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (activeSettingUI.length < 1 && reconfigurationShortId === null) {
      navigate(ROUTES["buildRoom"], { replace: true });
    }
  }, []);

  useEffect(() => {
    if (hasLoadedThreekit) {
      const configuration2D = getConfiguration2D();
      dispatch(setSavedConfiguration2D(configuration2D));
    }
  }, [hasLoadedThreekit]);

  useEffect(() => {
    if (hasLoadedThreekit && isLoadConfiguration) {
      setLoad(true)
      dispatch(setModalVisible({ modalId: MODAL_KEYS.LOADER, value: false }));
    } else {
      setLoad(false)
      dispatch(setModalVisible({ modalId: MODAL_KEYS.LOADER, value: true }));
    }
  }, [hasLoadedThreekit, isLoadConfiguration]);

  // useEffect(() => {
  //   console.log("isLoad --- ==== ", isLoad);
  // }, [isLoad])

  // if (isLoad) return <ModalsGlobal />;

  return (
    <div className={s.pageGrid}>
      <div className={s.mobHeader}>
        <div className={s.mainMenuLogoWrap}>
          <Logo />
        </div>
      </div>
      <aside className={s.mainSidebar} onMouseUp={(e) => e.stopPropagation()}>
        <MainMenu />
        <SectionsParams />
      </aside>
      {hasLoadedThreekit && (
        <>
          <div className={s.sceneContent}>
            {hasLoadedThreekit && <PlayerThreeKit />}
            <SceneControlsTop />
            <SceneControlsBottom3D />
            <SceneInfoText />
            <CabinetControls />
          </div>
        </>
      )}

      <ModalsGlobal />
    </div>
  );
};
