import { debounce } from "lodash";
import { ModelCabinetWallT, NODES_THREEKIT, PlaneCabinetsWallT, WallItemT } from "../../../utils/constants/nodesNamesThreekit";
import { getNumberNodeThreekitFromName } from "../../general";
import { getModelsWallNullOnPlane } from "../../intervals/getIntervalsInfoOnWall";

/**
 * Функція шукає настінні моделі, які встановлені безпосередньо на обраних стінах. НІСТІННІ МОДЕЛІ(Cabinets Wall).
 * По імені стіни знаходиться ім'я плейну, та шукаються моделі на плейні.
 *
 * @param {WallItemT[]} arrWallsNames Масив імен стін з Threekit, на яких шукаємо настінні моделі (поцьому плейну переміщуються моделі на стіні).
 * @return {ModelCabinetWallT[]} Массив з іменами Threekit, для Cabinets Wall Models Null ["Model_Cabinets_Wall_0", ...].
 */
export const getArrModelsWallNullOnWalls = (arrWallsNames: WallItemT[]): ModelCabinetWallT[] => {
  let arrModelsWallNullOnWalls: ModelCabinetWallT[] = [];
  arrWallsNames.forEach((wallName) => {
    const wallNameNum = getNumberNodeThreekitFromName(wallName);
    const planeName = `${NODES_THREEKIT.PLANE_CABINETS_WALL}${wallNameNum}` as PlaneCabinetsWallT;
    const modelsWallNullOnWall = getModelsWallNullOnPlane(planeName) as ModelCabinetWallT[];

    arrModelsWallNullOnWalls = [
      ...arrModelsWallNullOnWalls,
      ...modelsWallNullOnWall
    ]
  })
  return arrModelsWallNullOnWalls;
}

/**
 * Функція дозволяє або забороняє переміщення по осям X та Z для однієї настінної шафи.
 * Встановлює значення "Physics" для моделей в Threekit
 *
 * @param {ModelCabinetWallT} nullNameCabinetWall Name для Cabinets Wall Models Null ["Model_Cabinets_Wall_0", ...].
 * @param {boolean} value Нове значення для властивостей lockTX та lockTZ.
 */
export const setLockTranslationCabinetWall = (nullNameCabinetWall: ModelCabinetWallT,  value: boolean) => {
  window.threekit.player.scene.set({
    name: nullNameCabinetWall,
    //@ts-ignore
    plug: "Physics",
    properties: {
        name: "Rigid Body"
    },
    //@ts-ignore
    property: 'lockTX'
  }, value);

  window.threekit.player.scene.set({
    name: nullNameCabinetWall,
    //@ts-ignore
    plug: "Physics",
    properties: {
        name: "Rigid Body"
    },
    //@ts-ignore
    property: 'lockTZ'
  }, value);
}

/**
 * Функція дозволяє або забороняє переміщення по осям X та Z для настінних шкафів.
 * Встановлює значення "Physics" для моделей в Threekitю
 *
 * @param {ModelCabinetWallT[]} arrNamesNullForModelsWall Массив з іменами Threekit, для Cabinets Wall Models Null ["Model_Cabinets_Wall_0", ...].
 * @param {boolean} value Нове значення для властивостей lockTX та lockTZ.
 */
export const setLockTranslationCabinetsWall = (arrNamesNullForModelsWall: ModelCabinetWallT[],  value: boolean) => {
  arrNamesNullForModelsWall.forEach((nameModelNull) => {
    setLockTranslationCabinetWall(nameModelNull, value)
  })
}

/**
 * Функція отримує настінні моделі, які встановлені безпосередньо на обраних стінах.
 * Та викликає функції, які дозволяють або забороняють переміщення по осям X та Z настінних шкафів на стінах.
 *
 * @param {WallItemT[]} arrNamesVisibleWalls Массив імен стін, які є видимими.
 * @param {WallItemT[]} arrNamesHiddenWalls Массив імен стін, які є прихованими.
 */
export const debouncedCheckLockTranslationCabinetsWall = debounce((arrNamesVisibleWalls: WallItemT[], arrNamesHiddenWalls: WallItemT[]) => {
  
  let arrNamesModelsUnlockTranslation = getArrModelsWallNullOnWalls(arrNamesVisibleWalls);
  let arrNamesModelsLockTranslation = getArrModelsWallNullOnWalls(arrNamesHiddenWalls);

  setLockTranslationCabinetsWall(arrNamesModelsUnlockTranslation, false);
  setLockTranslationCabinetsWall(arrNamesModelsLockTranslation, true);

}, 300)