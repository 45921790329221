import { ICoordinates } from "@threekit-tools/treble/dist/types";
import {
  CabinetsAndFeatures_NodesT,
  ModelCabinetWallT,
  PlaneCabinetsWallT,
} from "../../utils/constants/nodesNamesThreekit";
import { getBoxWidthThreekit } from "../../utils/threekit/general/getFunctions";
import { checkDefaultPositionCabinetsWall } from "../cabinets/cabinetsWall/position";
import {
  isNullNameAppliancesFridge,
  isOTRCabinetWall,
} from "../cabinets/checkModels";
import { getModelsBaseNullOnWall } from "../cabinets/getNodesCabinets";
import { isFeaturesModelNullName } from "../features/general";
import { getWallNameFromPlaneName } from "../wallsAndFloor/getGeneralWallInfo";
import { getNeighborPlanes } from "../wallsAndFloor/getWallPlanesInfo";
import { getIntervalSize } from "./generalIntervals";
import {
  ArrWallRangesT,
  WallRangeT,
  addIntervalToArrIntervals,
  createRangesEmptyAndFilled,
  getIntervalsInfoOnWall,
} from "./getIntervalsInfoOnWall";
import { getIntervalsInfoOnWallForCabinetsBase } from "./getIntervalsInfoOnWallBase";
import { getSizeModelBoxFromAssetCabinetWall } from "../cabinets/cabinetsWall/size";
import { isNullNameModelCabinetWallT } from "../cabinets/cabinetsWall/checkCabinetsWall";
import { isNullNameModelApliancesT } from "../cabinets/appliances/checkTypeApliances";
import { getSizeModelBoxFromAssetAppliances } from "../cabinets/appliances/size";

// Tовщина дверей для Cabinets Wall
const DEPTH_DOOR_CABINETS_WALL = 0.0193023;

/**
 * Формує масив заповнених інтервалів на плейні, в яких розташовані холодильники
 *
 * @param {PlaneCabinetsWallT} namePlane Name плейну, для якого шукаємо наявність інтервалів з холодильниками
 * @return {ArrWallRangesT} Масив інтервалів з холодильниками на плейні
 */
const getIntervalsFridge = (namePlane: PlaneCabinetsWallT): ArrWallRangesT => {
  const modelsBaseNullOnWall = getModelsBaseNullOnWall(
    getWallNameFromPlaneName(namePlane)
  );
  const intervalsCabinetsBaseOnWall = getIntervalsInfoOnWallForCabinetsBase(
    modelsBaseNullOnWall,
    namePlane
  );
  const arrIntervalsFilledFromFridge = intervalsCabinetsBaseOnWall.filter(
    (objInterval) => {
      const { empty, name, range } = objInterval;
      if (!empty && name !== undefined) {
        return isNullNameAppliancesFridge(name);
      }
    }
  );
  return arrIntervalsFilledFromFridge;
};

/**
 * Фільтрує масив інтервалів для настінних шкафів таким чином,
 * щоб в ньому залишились інтервали з моделями, які розташовані на стандартній висоті
 * та щоб залишились інтервали з Features (вікна, двері, проеми)
 *
 * @param {PlaneCabinetsWallT} namePlane Name плейну, для якого фільтруємо інтервали
 * @return {ArrWallRangesT} Масив інтервалів з моделями, які розташовані на стандартній висоті
 * та з Features (вікна, двері, проеми)
 */
const filterIntervalsCabinetsWallByDefaultHeightAndFeatures = (
  namePlane: PlaneCabinetsWallT
): ArrWallRangesT => {
  const intervalsCabinetsWall = getIntervalsInfoOnWall(namePlane);

  const arrIntervalsFilledByDefaultHeightAndFeatures =
    intervalsCabinetsWall.filter((objInterval) => {
      const { empty, name, range } = objInterval;
      if (!empty && name !== undefined) {
        return (
          checkDefaultPositionCabinetsWall(name) ||
          isFeaturesModelNullName(name)
        );
      }
    });

  const planeWidth = getBoxWidthThreekit({ name: namePlane });
  const arrIntervalsFilledAndEmpty = createRangesEmptyAndFilled(
    arrIntervalsFilledByDefaultHeightAndFeatures,
    planeWidth
  );

  return arrIntervalsFilledAndEmpty;
};

/**
 * Об'єднує інтервали.
 *
 * @param {ArrWallRangesT} arrIntervalsCabinetsWallAndFeatures Масив інтервалів з моделями, які розташовані на стандартній висоті та з Features (вікна, двері, проеми)
 * @param {ArrWallRangesT} arrIntervalsFilledFromFridge Масив інтервалів з холодильниками
 * @return {ArrWallRangesT} Об'єднаний масив інтервалів
 */
const mergeIntervals = (
  arrIntervalsCabinetsWallAndFeatures: ArrWallRangesT,
  arrIntervalsFilledFromFridge: ArrWallRangesT
): ArrWallRangesT => {
  let intervalsCabinetsWallFeaturesFridge: ArrWallRangesT =
    arrIntervalsCabinetsWallAndFeatures;
  arrIntervalsFilledFromFridge.forEach((objIntervalFridge) => {
    intervalsCabinetsWallFeaturesFridge = addIntervalToArrIntervals(
      arrIntervalsCabinetsWallAndFeatures,
      objIntervalFridge
    );
  });
  return intervalsCabinetsWallFeaturesFridge;
};

/**
 * Формує масив інтервалів для настінних шкафів, який враховує інтервали для Features (вікна, двері, проеми)
 * та інтервали для холодильників, що розташованібіля стіни.
 *
 * @param {PlaneCabinetsWallT} namePlane Name плейну, для якого формується масив інтервалів
 * @return {ArrWallRangesT} Масив інтервалів
 */
const getIntervalsCabinetWallFeaturesFridge = (
  namePlane: PlaneCabinetsWallT
): ArrWallRangesT => {
  // Отримуємо інтервали для Cabinets Wall, які знаходяться на дефолтній висоті
  // Та інтервали для Features (вікна, двері, проеми)
  let intervalsCabinetsWallAndFeatures =
    filterIntervalsCabinetsWallByDefaultHeightAndFeatures(namePlane);

  // Отримуємо інтервали для холодильників, що розташовані біля стіни
  const intervalsFridge = getIntervalsFridge(namePlane);

  // Повертаємо спільний масив інтервалів
  return mergeIntervals(intervalsCabinetsWallAndFeatures, intervalsFridge);
};

/**
 * Повертає розмір інтервалу, на основі розмірів моделі, яка розташована на сусідній стіні близько до кута.
 *
 * @param {CabinetsAndFeatures_NodesT | undefined} nameModel Name моделі.
 * @return {number} Розмір інтервалу.
 */
const getSizeNewInterval = (
  nameModel: CabinetsAndFeatures_NodesT | undefined
): number => {
  let sizeNewInterval: number = 0;

  if (nameModel !== undefined && isNullNameModelApliancesT(nameModel)) {
    const sizeApplianceOnLeftPlane =
      getSizeModelBoxFromAssetAppliances(nameModel);
    sizeNewInterval = sizeApplianceOnLeftPlane["z"];
  }

  if (nameModel !== undefined && isNullNameModelCabinetWallT(nameModel)) {
    const sizeCabinetWallOnLeftPlane =
      getSizeModelBoxFromAssetCabinetWall(nameModel);
    sizeNewInterval =
      sizeCabinetWallOnLeftPlane["z"] + DEPTH_DOOR_CABINETS_WALL;
  }

  return sizeNewInterval;
};

/**
 * Визначає чи присутній на сусідній стіні зліва в спільному куті Cabinet Wall або інша модель.
 * Та, якщо модель в куті присутня, додаємо відповідний інтервал в загальний масив інтервалів.
 *
 * @param {ArrWallRangesT} baseIntervals Загальний масив інтервалів.
 * @param {PlaneCabinetsWallT | undefined} planeLeftName Ім'я сусіднього зліва плейну.
 * @param {number} offsetCorner Мінімальна відстань від кута, на якій мають розташовуватись шкафи на сусідніх стінах.
 * @return {ArrWallRangesT} Змінений або той самий масив інтервалів baseIntervals.
 */
const checkLeftInterval = (
  baseIntervals: ArrWallRangesT,
  planeLeftName: PlaneCabinetsWallT | undefined,
  offsetCorner: number
): ArrWallRangesT => {
  // Якщо не знайдений сусідній зліва плейн, повертаємо той самий масив інтервалів
  if (planeLeftName === undefined) return baseIntervals;

  // Отримуємо масив інтервалів для сусіднього зліва плейну
  // Цей масив інтервалів має враховувати Cabinets Wall, Холодильник, Вікна, Двері, Проеми
  const intervalsOnLeftWall =
    getIntervalsCabinetWallFeaturesFridge(planeLeftName);

  // Шукаємо останній інтервал для данного плейну
  const lastIntervalOnLeftWall =
    intervalsOnLeftWall[intervalsOnLeftWall.length - 1];

  // Перевіряємо:
  // - якщо останній інтервал пустий І має розмір більший ніж розмір глубини моделі
  // то повертаємо той самий масив інтервалів
  if (
    lastIntervalOnLeftWall["empty"] &&
    getIntervalSize(lastIntervalOnLeftWall) > offsetCorner
  )
    return baseIntervals;

  // В іншому випадку додаємо в загальний масив інтервалів на стіні ще один заповнений інтервал на початок
  // який визначатиме що на сусідній стіні зліва близько до кута розташований шкаф
  // та матиме розмір, який відповідає розміру глубини моделі, яка знаходиться на сусідній стіні близько до кута

  // Шукаємо ім'я моделі, що знаходиться близько до кута на сусідній зліва стіні
  const reversedIntervalsOnLeftWall = [...intervalsOnLeftWall].reverse();
  const indexLastFilledInterval = reversedIntervalsOnLeftWall.findIndex(
    (objInterval) => objInterval["name"] !== undefined
  );
  const nameLeftModel =
    reversedIntervalsOnLeftWall[indexLastFilledInterval]["name"];

  // Знаходимо розмір нового проміжку
  const sizeNewInterval = getSizeNewInterval(nameLeftModel);

  if (sizeNewInterval === 0) return baseIntervals;

  // Створюємо новий інтервал зліва
  const newLeftInterval: WallRangeT = {
    empty: false,
    name: nameLeftModel,
    range: [0, sizeNewInterval],
  };

  // Додаємо новий інтервал в загальний масив інтервалів на початок і повертаємо його
  return addIntervalToArrIntervals(baseIntervals, newLeftInterval);
};

/**
 * Визначає чи присутній на сусідній стіні зправа в спільному куті Cabinet Wall або інша модель.
 * Та, якщо модель в куті присутня, додаємо відповідний інтервал в загальний масив інтервалів.
 *
 * @param {ArrWallRangesT} baseIntervals Загальний масив інтервалів.
 * @param {PlaneCabinetsWallT | undefined} planeRightName Ім'я сусіднього зправа плейну.
 * @param {number} offsetCorner Мінімальна відстань від кута, на якій мають розташовуватись шкафи на сусідніх стінах.
 * @return {ArrWallRangesT} Змінений або той самий масив інтервалів baseIntervals.
 */
const checkRightInterval = (
  baseIntervals: ArrWallRangesT,
  currentPlaneName: PlaneCabinetsWallT,
  planeRightName: PlaneCabinetsWallT | undefined,
  offsetCorner: number
): ArrWallRangesT => {
  // Якщо не знайдений сусідній зправа плейн, повертаємо той самий масив інтервалів
  if (planeRightName === undefined) return baseIntervals;

  // Отримуємо масив інтервалів для сусіднього зправа плейну
  // Цей масив інтервалів має враховувати Cabinets Wall, Холодильник, Вікна, Двері, Проеми
  const intervalsOnRightWall =
    getIntervalsCabinetWallFeaturesFridge(planeRightName);

  // Шукаємо перший інтервал для данного плейну
  const firstIntervalOnLeftWall = intervalsOnRightWall[0];

  // Перевіряємо:
  // - якщо перший інтервал пустий І має розмір більший ніж розмір глубини моделі
  // то повертаємо той самий масив інтервалів
  if (
    firstIntervalOnLeftWall["empty"] &&
    getIntervalSize(firstIntervalOnLeftWall) > offsetCorner
  )
    return baseIntervals;

  // В іншому випадку додаємо в загальний масив інтервалів на стіні ще один заповнений інтервал в кінець
  // який визначатиме що на сусідній стіні зправа близько до кута розташована модель
  // та матиме розмір, який відповідає розміру глубини моделі, яка знаходиться на сусідній стіні близько до кута

  // Шукаємо ім'я моделі, що знаходиться близько до кута на сусідній зправа стіні
  const indexFirstFilledInterval = intervalsOnRightWall.findIndex(
    (objInterval) => objInterval["name"] !== undefined
  );
  const nameRightModel = intervalsOnRightWall[indexFirstFilledInterval]["name"];

  // Знаходимо розмір нового проміжку
  const sizeNewInterval = getSizeNewInterval(nameRightModel);

  if (sizeNewInterval === 0) return baseIntervals;

  // Отримуємо розмір плейну, для формування нового інтервалу
  const currentPlaneWidth = getBoxWidthThreekit({ name: currentPlaneName });

  // Створюємо новий інтервал зправа
  const newLeftInterval: WallRangeT = {
    empty: false,
    name: nameRightModel,
    range: [currentPlaneWidth - sizeNewInterval, currentPlaneWidth],
  };

  // Додаємо новий інтервал в загальний масив інтервалів на початок і повертаємо його
  return addIntervalToArrIntervals(baseIntervals, newLeftInterval);
};

/**
 * Формує массив інтервалів для на стіні для Cabinets Wall для визначення позиції для нового шкафа.
 * З урахуванням інтервалів на сусідніх стінах.
 *
 * @param {ArrWallRangesT} baseIntervals Загальний масив інтервалів для стіни без урахування розташування шкафів на сусідніх стінах бизько до спільних кутів.
 * @param {PlaneCabinetsWallT} currentPlaneName Name стіни, на якій відбувається позиціонування Cabinet Base в правому куті.
 * @param {ICoordinates} sizeCabinetBaseNew Null Name нової моделі, яку потрібно поставити у позицію правого кута на стіні.
 */
const getIntervalsForPositionedNewCabinetBase = (
  baseIntervals: ArrWallRangesT,
  currentPlaneName: PlaneCabinetsWallT,
  sizeCabinetBaseNew: ICoordinates
): ArrWallRangesT => {
  let resultArrIntervals: ArrWallRangesT = baseIntervals;

  // Мінімальна відстань від кута, на якій мають розташовуватись шкафи на сусідніх стінах
  // Для того щоб їх не праховувати при додаванні нових шкафів в той самий кут
  // DEPTH_DOOR_CABINETS_WALL - товщина дверей для Cabinets Wall
  const offsetCorner = sizeCabinetBaseNew["z"] + DEPTH_DOOR_CABINETS_WALL;

  // 1. Отримуємо імена для сусідніх плейнів зліва та справа
  const { planeLeftName, planeRightName } = getNeighborPlanes(currentPlaneName);

  // 2. Визначаємо чи присутній на сусідній стіні зліва в спільному куті Cabinet Wall чи Холодильник.
  // Та, якщо модель в куті присутня, додаємо відповідний інтервал в загальний масив інтервалів.
  resultArrIntervals = checkLeftInterval(
    resultArrIntervals,
    planeLeftName,
    offsetCorner
  );

  // 3. Визначаємо чи присутній на сусідній стіні зправа в спільному куті Cabinet Base.
  // Та, якщо шкаф в куті присутній, додає відповідний інтервал в загальний масив інтервалів.
  resultArrIntervals = checkRightInterval(
    resultArrIntervals,
    currentPlaneName,
    planeRightName,
    offsetCorner
  );

  return resultArrIntervals;
};

/**
 * Формує масив інтервалів для визначення позиції для нової моделі Cabinet Wall.
 *
 * @param {PlaneCabinetsWallT} namePlane Name плейну, для якого формується масив інтервалів
 * @param {ModelCabinetWallT} nullNameCabinetWallNew Масив інтервалів з холодильниками
 * @return {ArrWallRangesT} Масив інтервалів
 */
export const getIntervalsForCkeckNewPositionCabinetWall = (
  namePlane: PlaneCabinetsWallT,
  nullNameCabinetWallNew: ModelCabinetWallT,
  sizeCabinetWallNew: ICoordinates
): ArrWallRangesT => {
  let resultIntervals: ArrWallRangesT = [];

  // Якщо модель не є низьким шкафом, що може розташовуватись над холодильником
  // То масив інтервалів буде включати інтервали для: Cabinets Wall, Features (вікна, двері, проеми) та інтервали для холодильників
  if (!isOTRCabinetWall(nullNameCabinetWallNew)) {
    resultIntervals = getIntervalsCabinetWallFeaturesFridge(namePlane);
  } else {
    // Якщо нова модель може розташовуватись над холодильником, то повертаємо тільки настінні інтервали intervalsCabinetsWallAndFeatures
    resultIntervals =
      filterIntervalsCabinetsWallByDefaultHeightAndFeatures(namePlane);
  }

  return getIntervalsForPositionedNewCabinetBase(
    resultIntervals,
    namePlane,
    sizeCabinetWallNew
  );
};
