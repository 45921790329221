import React, { useEffect } from "react";
import { DeleteBasketSvg } from "../../../assets/images/svg/DeleteBasket";
import { DimentionsSvg } from "../../../assets/images/svg/Dimentions";
import { HistoryNextStepSvg } from "../../../assets/images/svg/HistoryNextStep";
import { HistoryPrevStepSvg } from "../../../assets/images/svg/HistoryPrevStep";
import { ZoomIn } from "../../../assets/images/svg/ZoomIn";
import { ZoomOut } from "../../../assets/images/svg/ZoomOut";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { getHistoryFull2D, getHistoryLatest2D } from "../../../store/selectors/historySelectors";
import { ButtonIcon } from "../../StandartComponents/Buttons/BtnIcon";
// scss
import s from "./SceneControlsBottom2D.module.scss";
import { useThreekitInitStatus } from "@threekit-tools/treble/dist";
import { setConfigurationFullAll } from "../../../utils/threekit/general/setFunctions";
import { ActionCreators } from "redux-undo";
import { IDimensionOptions } from "@threekit-tools/treble/dist/hooks/useSpaces/types";

// svg icons

export const SceneControlsBottom2D = ({...props}) => {
  const { deleteAllWalls, dimensions, zoom } = props;

  const dispatch = useAppDispatch();
  const hasLoaded = useThreekitInitStatus();

  const handleClickPrevHistory = () => {
    dispatch(ActionCreators.undo());
  }

  const handleClickNextHistory = () => {
    console.log('handleClickNextHistory --- ==== ',);
  }

  const handleClickDimentions = () => {
    // handleToggleDimentions();
    console.log("dimensions --- ==== ", dimensions);
    if (dimensions === undefined) return;

    let nextOption: any = {};
    dimensions.options.forEach((option: any, index: number) => {
      if (option["selected"]) {
        const nextIndex = index !== dimensions["options"].length - 1 ? index + 1 : 0;
        nextOption = dimensions["options"][nextIndex]
      }
    });

    console.log("nextOption --- ==== ", nextOption);

    nextOption.handleSelect();

  }

  const handleClickRemoveAllWalls = () => {
    deleteAllWalls();
  }

  const handleClickZoomIn = () => {
    zoom.handleChange(2);
  }

  const handleClickZoomOut = () => {
    zoom.handleChange(-2);
  }

  const historyFullList = useAppSelector(getHistoryFull2D);
  // const historyLastPresent = useAppSelector(getHistoryLatest2D);
  // useEffect(() => {
  //   if (hasLoaded && historyLastPresent !== undefined) {
  //     setConfigurationFullAll(historyLastPresent["configuration"]);
  //   }
  // }, [historyLastPresent]);

  const getDimentionsText = (value: IDimensionOptions) => {
    switch (value) {
      case IDimensionOptions.NONE:
        return "No Size";
      case IDimensionOptions.DETAILED:
        return "Detailed Size";
      default:
        return "Overall Size";
    }
  }

  return (
    <div className={s.sceneControlsBottom2D} onMouseUp={(e) => e.stopPropagation()}>
      <div className={s.sceneControlsBlock}>
        <ButtonIcon onClick={handleClickZoomIn}><ZoomIn /></ButtonIcon>
        <ButtonIcon onClick={handleClickZoomOut}><ZoomOut /></ButtonIcon>
      </div>
      <div className={s.sceneControlsBlock}>
        {/* <ButtonIcon onClick={handleClickPrevHistory}><HistoryPrevStepSvg /></ButtonIcon> */}
        {/* <ButtonIcon onClick={handleClickNextHistory}><HistoryNextStepSvg /></ButtonIcon> */}
        <ButtonIcon onClick={handleClickDimentions}><DimentionsSvg /><span>{ getDimentionsText(dimensions["value"]) }</span></ButtonIcon>
        <ButtonIcon onClick={handleClickPrevHistory} disabled={historyFullList.length <= 1} ><DeleteBasketSvg /></ButtonIcon>
      </div>
    </div>
  );
};
