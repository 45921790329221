import { IThreekitPrivateConfigurator } from "@threekit-tools/treble/dist/types";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../utils/constants/attributesThreekit";
import { getAppliedConfigurationThreekitAttribute, getEvalNodeFromId, getItemNodeFromNullModel } from "../../../utils/threekit/general/getFunctions";
import { SelectedModelNameT } from "../../../store/slices/settingsUI/typesSettingsUISlice";
import { getConfiguratorModelFromNullName } from "./decorativePanel";
import { SinkSizeT } from "../../../functionsUI/appliances";
import { NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";

export const enum ATTRIBUTES_SINK {
  SINK_SIZE = "Sink",
}
export type SinkSizeValuesT = "sink 25" | "sink 33";
export type DoorDirectionConfigurationT = {
  [ATTRIBUTES_SINK.SINK_SIZE]?: SinkSizeValuesT,
}

/**
 * Функція оновлює конфігурацію тумби під раковину.
 * Міняє розмір раковини для тумби. Атрибут Sink.
 *
 * @param {string} sinkCabinetId Id тумби з раковиною.
 * @param {SinkSizeT} sinkSize Значення атрибуту, яке потрібно оновити.
 */
// export const updateConfigurationSink = async (sinkCabinetId: string, sinkSize: SinkSizeT) => {

//   // перебираємо всі активні моделі з атрибутів Cabinets Base і Cabinets Island
//   // шукаємо модель, ім'я якої містить isSinkCabinet
//   const appliedConfigurationCabinetsBase = getAppliedConfigurationThreekitAttribute(ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE);
//   const appliedConfigurationCabinetsIsland = getAppliedConfigurationThreekitAttribute(ATTRIBUTES_NAMES_THREEKIT.CABINETS_ISLAND);

//   let configuratorCabinetSink: IThreekitPrivateConfigurator | undefined;
//   [ ...appliedConfigurationCabinetsBase, ...appliedConfigurationCabinetsIsland ].forEach((itemId: string) => {
//     const evalNodeModel = getEvalNodeFromId(itemId);
//     if (evalNodeModel["name"].includes(sinkCabinetId))
//       configuratorCabinetSink = evalNodeModel["configurator"];
//   })

//   if (!!configuratorCabinetSink) {
//     await configuratorCabinetSink.setConfiguration({
//       Sink: sinkSize,
//     });
//   }
  
// }

export const checkBaseCabinet = (modelNullName: SelectedModelNameT): boolean => {
  return modelNullName.includes(NODES_THREEKIT.MODEL_CABINET_BASE);
}

export const checkBaseOrIslandCabinet = (modelNullName: SelectedModelNameT): boolean => {
  return modelNullName.includes(NODES_THREEKIT.MODEL_CABINET_BASE) || modelNullName.includes(NODES_THREEKIT.MODEL_CABINET_ISLAND);
}

/**
 * Функція перевіряє чи є обрана модель тумбою з раковиною розміром 36".
 *
 * @param {SelectedModelNameT} modelNullName Null Name моделеі.
 * @returns {boolean} true - якщо шкаф є тумбою з раковиною розміром 36".
 */
export const checkSinkBaseCabinet36in = (
  modelNullName: SelectedModelNameT
): boolean => {
  if (modelNullName === undefined) return false;
  if (!checkBaseOrIslandCabinet(modelNullName)) return false;
  const modelItem = getItemNodeFromNullModel({ name: modelNullName });
  if (modelItem === undefined) return false;
  return (
    modelItem["name"].includes("36in") &&
    modelItem["name"].includes("Sink Base Cabinet")
  );
};

/**
 * Функція шукає активне значення атрибуту, який визначає розмір раковини, для моделі.
 *
 * @param {SelectedModelNameT} modelNullName Null Name моделеі.
 * @returns {DoorDirectionValuesT | undefined} Значення атрибуту або undefined - якщо модель не є тумбою з раковиною розміром 36".
 */
export const getActiveSinkSizeForModel = (modelNullName: SelectedModelNameT): SinkSizeT | undefined => {

  if (!!!modelNullName) return undefined;

  const isSinkBaseCabinet36in = checkSinkBaseCabinet36in(modelNullName);

  if (!isSinkBaseCabinet36in) return undefined;

  const configuratorModel = getConfiguratorModelFromNullName(modelNullName);
  const configurationModel = configuratorModel.getConfiguration();
  const activeSinkSize = configurationModel[ATTRIBUTES_SINK.SINK_SIZE] as SinkSizeValuesT;
  return activeSinkSize;
}

/**
 * Функція оновлює конфігурацію тумби під раковину.
 * Міняє розмір раковини для тумби. Атрибут Sink.
 *
 * @param {SelectedModelNameT} modelNullName Null Name моделеі.
 * @param {SinkSizeT} sinkSize Значення атрибуту, яке потрібно оновити.
 */
export const updateConfigurationSinkNew = async (modelNullName: SelectedModelNameT, sinkSize: SinkSizeT) => {

  if (!!!modelNullName) return;

  const isSinkBaseCabinet36in = checkSinkBaseCabinet36in(modelNullName);

  if (!isSinkBaseCabinet36in) return;

  const newConfiguration = {
    [ATTRIBUTES_SINK.SINK_SIZE]: sinkSize,
  }
  const configuratorModel = getConfiguratorModelFromNullName(modelNullName);
  await configuratorModel.setConfiguration(newConfiguration);
  
}