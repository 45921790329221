import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { getValueOptionFrigePanels } from "../../../store/selectors/settingsUISelectors";
import store from "../../../store/store";
import { CabinetsAndFeatures_NodesT, ModelsName_NodesT, NODES_THREEKIT, WallItemT } from "../../../utils/constants/nodesNamesThreekit";
import { getKeys } from "../../../utils/other/getObjKeysFromType";
import { getTranslationThreekit } from "../../../utils/threekit/general/getFunctions";
import { isFeaturesModelNullName } from "../../features/general";
import { getNumberNodeThreekitFromName } from "../../general";
import { WallRangeT, getIntervalsWallCabinetsForAllWalls } from "../../intervals/getIntervalsInfoOnWall";
import { getIntervalsBaseCabinetsForAllWalls } from "../../intervals/getIntervalsInfoOnWallBase";
import { isEqualCoordsTolerance } from "../addCornerModelBase";
import { getSizeModelBoxFromAssetCabinetBase } from "../cabinetsBase/size";
import { checkDefaultPositionCabinetsWall, checkDefaultPositionCabinetsWallFromParams } from "../cabinetsWall/position";
import { getSizeModelBoxFromAssetCabinetWall } from "../cabinetsWall/size";
import { isNullNameAppliances, isNullNameAppliancesFridge, isNullNameCabinetBase, isOTRCabinetWall, isUpperPantryCabinetWall } from "../checkModels";
import { ATTRIBUTES_DECORATIVE_PANELS, DecorativeLocationValuesT, DecorativeValuesT, getConfiguratorModelFromNullName } from "./decorativePanel";

export type FrigeConfigurationT = {
  [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]?: DecorativeValuesT,
  [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]?: DecorativeLocationValuesT,
}

const getObjConfigurationForModel = (
  isPanelLeft: boolean,
  isPanelRight: boolean,
): FrigeConfigurationT => {

  let objConfiguration: FrigeConfigurationT = {
    [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
    [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Left and Right",
  }

  if (isPanelLeft && isPanelRight) {
    objConfiguration = {
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "Yes",
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Left and Right",
    }
  } else if (isPanelLeft && !isPanelRight) {
    objConfiguration = {
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "Yes",
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Left",
    }
  } else if (!isPanelLeft && isPanelRight) {
    objConfiguration = {
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "Yes",
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Right",
    }
  } else {
    objConfiguration = {
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Left",
    }
  }

  return objConfiguration;

}

const getIsPanelSide = (
  intervalFloor: WallRangeT | undefined,
  intervalWall: WallRangeT | undefined,
): boolean => {
  let isPanelSide = false;

  let isDefaultPositionCabinetsWallFromIntervalWall: boolean = true;
  if (
    intervalFloor !== undefined &&
    intervalWall !== undefined &&
    !intervalWall["empty"] &&
    intervalWall["name"] !== undefined
  ) {
    const positionCabinetWallFromIntervalWall = getTranslationThreekit({ name: intervalWall["name"] });
    const sizeCabinetWallFromIntervalWall = getSizeModelBoxFromAssetCabinetWall(intervalWall["name"]);
    isDefaultPositionCabinetsWallFromIntervalWall = checkDefaultPositionCabinetsWallFromParams(
      positionCabinetWallFromIntervalWall,
      sizeCabinetWallFromIntervalWall
    )
  }
  
  if (
    intervalFloor !== undefined &&
    intervalWall !== undefined &&
    intervalFloor["empty"] &&
    (intervalWall["empty"] || (!isDefaultPositionCabinetsWallFromIntervalWall)) &&
    intervalFloor["range"][1] - intervalFloor["range"][0] > 0.05 &&
    intervalWall["range"][1] - intervalWall["range"][0] > 0.05
  ) {
    isPanelSide = true;
  }
  return isPanelSide;
}

const getConfigurationOTRCabinets = (
  objConfiguration: FrigeConfigurationT,
  floorNullName: CabinetsAndFeatures_NodesT,
  positionCabinetWall: ICoordinates,
  prevIntervalFloor: WallRangeT | undefined,
  nextIntervalFloor: WallRangeT | undefined,
  prevIntervalWall: WallRangeT | undefined,
  nextIntervalWall: WallRangeT | undefined,
) => {

  let resultObjConfiguration: FrigeConfigurationT = { ...objConfiguration };

  if (isNullNameAppliances(floorNullName)) {

    const positionFloorModel = getTranslationThreekit({ name: floorNullName });

    const isEqualCoords = isEqualCoordsTolerance(
      { ...positionCabinetWall, y: 0},
      { ...positionFloorModel, y: 0},
      0.05
    )
    // Якщо це холодильник
    // Якщо проекції координат на підлогу для OTR моделі та холодильника співпадають в якомусь околі
    // То модель OTR стоіть над холодильником
    if (
      isNullNameAppliancesFridge(floorNullName) &&
      isEqualCoords
    ) {

      // Перевіряємо сусідів зліва для холодильника та OTR шкафа
      // це робимо перевіряючи сусідні інтервали зліва
      // якщо сусідні інтервали зліва пусті, то вмикаємо для OTR шкафа декоративну панель зліва
      let isPanelLeft = getIsPanelSide(prevIntervalFloor, prevIntervalWall);
      // Перевіряємо сусідів справа для холодильника та OTR шкафа
      // це робимо перевіряючи сусідні інтервали справа
      // якщо сусідні інтервали справа пусті, то вмикаємо для OTR шкафа декоративну панель справа
      let isPanelRight = getIsPanelSide(nextIntervalFloor, nextIntervalWall);

      resultObjConfiguration = getObjConfigurationForModel(isPanelLeft, isPanelRight);

    }

  }

  return resultObjConfiguration;

}

const getConfigurationUpperPantryCabinets = (
  objConfiguration: FrigeConfigurationT,
  floorNullName: CabinetsAndFeatures_NodesT,
  positionCabinetWall: ICoordinates,
  sizeCabinetWall: ICoordinates,
  prevIntervalFloor: WallRangeT | undefined,
  nextIntervalFloor: WallRangeT | undefined,
  prevIntervalWall: WallRangeT | undefined,
  nextIntervalWall: WallRangeT | undefined,
) => {

  let resultObjConfiguration: FrigeConfigurationT = { ...objConfiguration };

  // Перевіряємо чи це напольний шкаф такого ж розміру як настінна модель Upper Pantry
  if (isNullNameCabinetBase(floorNullName)) {
    const floorNullNameCabinetBase = floorNullName as ModelsName_NodesT;
    const sizeFloorModel = getSizeModelBoxFromAssetCabinetBase(floorNullNameCabinetBase);
    const positionFloorModel = getTranslationThreekit({ name: floorNullName });
    const isEqualCoords = isEqualCoordsTolerance(
      { ...positionCabinetWall, y: 0},
      { ...positionFloorModel, y: 0},
      0.05
    )

    // Перевіряємо чи напольний шкаф такого ж розміру як і настінний шкаф Upper Pantry
    // та перевіряємо на співпадіння проекції координат на підлогу для OTR моделі та напольного шкафа
    if (
      Math.abs(sizeCabinetWall["x"] - sizeFloorModel["x"]) < 0.05 &&
      isEqualCoords
    ) {

      // Перевіряємо сусідів зліва для напольного шкафа та Upper Pantry шкафа
      // це робимо перевіряючи сусідні інтервали зліва
      // якщо сусідні інтервали зліва пусті, то вмикаємо для Upper Pantry шкафа декоративну панель зліва
      let isPanelLeft = getIsPanelSide(prevIntervalFloor, prevIntervalWall);
      // Перевіряємо сусідів справа для напольного шкафа та Upper Pantry шкафа
      // це робимо перевіряючи сусідні інтервали справа
      // якщо сусідні інтервали справа пусті, то вмикаємо для Upper Pantry шкафа декоративну панель справа
      let isPanelRight = getIsPanelSide(nextIntervalFloor, nextIntervalWall);

      resultObjConfiguration = getObjConfigurationForModel(isPanelLeft, isPanelRight);

    }
  }

  return resultObjConfiguration;

}

export const updateFrigePanels = (valueParamFrigePanelsUI?: boolean) => {

  const state = store.getState();

  const frigePanelsValueUI = getValueOptionFrigePanels(state) as boolean;

  const valueFrigePanels = valueParamFrigePanelsUI !== undefined ? valueParamFrigePanelsUI : frigePanelsValueUI

  const intervalsCabinetsWallForAllWalls = getIntervalsWallCabinetsForAllWalls();
  const intervalsCabinetsBaseForAllWalls = getIntervalsBaseCabinetsForAllWalls();

  // перебираємо всі стіни для яких створені інтервали
  const arrWallNames = getKeys(intervalsCabinetsWallForAllWalls);
  arrWallNames.forEach((planeName) => {

    const wallIntervals = intervalsCabinetsWallForAllWalls[planeName];

    if (wallIntervals.length < 2) return;

    let prevIntervalWall: WallRangeT | undefined = undefined;
    // перебираємо всі інтервали на стіні
    wallIntervals.forEach((objWallInterval, indx) => {

      const nextIntervalWall: WallRangeT | undefined = wallIntervals[indx + 1];

      if (
        // 1. Перевіряємо чи інтервал не пустий
        objWallInterval["empty"] ||
        objWallInterval["name"] === undefined
      ) {
        prevIntervalWall = objWallInterval;
        return;
      }

      // 2. Перевіряємо чи це не інтервал для вікон, дверей або проемів
      if (isFeaturesModelNullName(objWallInterval["name"])) {
        prevIntervalWall = objWallInterval;
        return;
      }

      const isOTR = isOTRCabinetWall(objWallInterval["name"]);
      const isUpperPantry = isUpperPantryCabinetWall(objWallInterval["name"]);
      const sizeCabinetWall = getSizeModelBoxFromAssetCabinetWall(objWallInterval["name"]);

      if (
        // 3. Перевіряємо, що це не модель OTR
        // 4. Перевіряємо, що модель не Upper Pantry
        (!isOTR && !isUpperPantry) ||
        // 5. Перевіряємо чи глубина моделі менше 24"
        sizeCabinetWall["z"] < 0.58
      ) {
        prevIntervalWall = objWallInterval;
        return;
      }
      
      const positionCabinetWall = getTranslationThreekit({ name: objWallInterval["name"] });
      const configuratorCabinetWall = getConfiguratorModelFromNullName(objWallInterval["name"]);
      if (
        // 6. Перевіряємо чи настінна модель знаходиться на стандартній висоті від підлоги
        !checkDefaultPositionCabinetsWallFromParams(positionCabinetWall, sizeCabinetWall) ||
        // 7. Якщо Frige Panels вимкнено в UI, то вимикаємо декоративні панелі
        !valueFrigePanels
      ) {
        prevIntervalWall = objWallInterval;
        return configuratorCabinetWall.setConfiguration({
          [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
        });
      }

      // ПЕРЕВІРЯЄМО для настінної моделі умови по напольних моделях,
      // які розташовуються на тій же стіні
      
      const numPlane = getNumberNodeThreekitFromName(planeName);
      const nameWall = `${NODES_THREEKIT.WALL_ITEM}${numPlane}` as WallItemT;
      const floorIntervals = intervalsCabinetsBaseForAllWalls[nameWall];


      let objConfiguration: FrigeConfigurationT = {
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Left and Right",
      }
      let prevIntervalFloor: WallRangeT | undefined = undefined;
      // перебираємо інтервали для напольних моделей,
      // та шукаємо відповідно холодильник або шкаф відповідного розміру для Upper Pantry
      floorIntervals.forEach((objFloorInterval, indx) => {

        const nextIntervalFloor: WallRangeT | undefined = floorIntervals[indx + 1];

        if (
          // 1. Перевіряємо чи інтервал на підлозі не пустий
          objFloorInterval["empty"] ||
          objFloorInterval["name"] === undefined
        ) {
          prevIntervalFloor = objFloorInterval;
          // Пропускаємо пусті інтервали
          return;
        }
        
        // Робимо перевірки для OTR шкафа
        // Та отримуємо конфігурацію для OTR шкафа
        if (isOTR) {

          objConfiguration = getConfigurationOTRCabinets(
            objConfiguration,
            objFloorInterval["name"],
            positionCabinetWall,
            prevIntervalFloor,
            nextIntervalFloor,
            prevIntervalWall,
            nextIntervalWall,
          );

        }

        // Робимо перевірки для Upper Pantry шкафа
        // Та отримуємо конфігурацію для Upper Pantry шкафа
        if (isUpperPantry) {

          objConfiguration = getConfigurationUpperPantryCabinets(
            objConfiguration,
            objFloorInterval["name"],
            positionCabinetWall,
            sizeCabinetWall,
            prevIntervalFloor,
            nextIntervalFloor,
            prevIntervalWall,
            nextIntervalWall,
          )

        }

        prevIntervalFloor = objFloorInterval;

      })

      prevIntervalWall = objWallInterval;

      configuratorCabinetWall.setConfiguration(objConfiguration);

    })

  })

}