import { ArrWallRangesT, RangeT } from "./getIntervalsInfoOnWall";

function doIntervalsOverlap(interval1: RangeT, interval2: RangeT, tolerance: number) {
  return interval1[1] + tolerance >= interval2[0] && interval2[1] + tolerance >= interval1[0];
}

export const checkIntersectIntervalFromIntervals = (
  targetInterval: RangeT,
  arrIntervals: ArrWallRangesT,
): boolean => {
  let isIntersect: boolean = false;
  arrIntervals.forEach((interval) => {
    if (!interval.empty && doIntervalsOverlap(targetInterval, interval["range"], 0.005)) {
      isIntersect = true;
    }
  })
  return isIntersect;
}