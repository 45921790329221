
// Descriptions Type Component
// last characters -> GT - group Type Component
// last characters -> ST - setting Type Component

// hiddenProperty - сховані опції потрібні для конфігурації

export type standardComponent =
  | "SelectStandart"
  | "CategoryGroup"
  | "Tabs"
  | "OptionsGroup"
  | "SwitchStandart"
  | "SelectColor"
  | "RadioOptions"
  | "SwitchSpaceBetween"
  | "RadioOptionsIconText";
export type groupComponent =
  | "SettingsBlock"
  | "SettingsProduts"
  | "OptionsTab"
  | "FiltersGroup"
  | "CategoriesGroup"
  | "hiddenProperty"
  | "CabinetsBuildGroup"
  | "SubMenu2DWrap"
  | "SubMenu2DHelp"
  | "SubMenu2DTemplate"
  | "SubMenu2DGrag"
  | "ProductsGroup"
  | standardComponent;
export type allTypeComponent = standardComponent | groupComponent;
// Descriptions: typeOptions
// groupingOptions - options for groping
// configurationOptions - options for configuration param
// groupingOptionsSwitchingOpening - options for groping ang opening or closing
export type typeOptions =
  | "groupingOptions"
  | "groupingOptionsTabs"
  | "configurationOptions";

// Descriptions: flag options
// isVisible - visible or not for (group or settings)
// switchingOptions - opening group or not for (groupingOptionsSwitchingOpening )
//

// Options Type
export type dependencyT =
  | "isVisible"
  | "isInclude"
  | "isIncludeSustemSetting"
  | "";

export type dependencySettingT = {
  dependencyType: dependencyT;
  idParams: string;
  isValue: any;
};
export type ArrDependencySettingT = dependencySettingT[]
// END

export type ValuesConfigurationT = {
  label: string | number;
  value: string | number;
}

export type OptionsT = {
  id: string;
  typeOptions: typeOptions;
  label?: string | string[];
  embeddedView?: IComponent[];
  dependencySettings?: ArrDependencySettingT;
  isSettingSide?: boolean;
  values?: any;
  defaultValue?: any;
  visibilityState?: boolean;
  [key: string]: any;
};

export type GroupingOptionsT = OptionsT & {
  typeOptions: "groupingOptions";
  isVisible?: boolean;
};
export type GroupingOptionsTabsT = OptionsT & {
  typeOptions: "groupingOptionsTabs";
  isVisible?: boolean;
};
export type ConfigurationOptionsT = OptionsT & {
  typeOptions: "configurationOptions";
  isVisible?: boolean;
};

export type IComponentData =
  | GroupingOptionsT
  | GroupingOptionsTabsT
  | ConfigurationOptionsT;

export type IComponent = {
  type: allTypeComponent;
  data: IComponentData;
};

export enum SECTIONS_KEYS {
  CABINETS = "Cabinets",
  ISLAND = "Island",
  APPLIANCES = "Appliances",
  CABINET_BUILD = "CabinetBuild",
  CABINET_COLOR = "CabinetColor",
}
