import { type } from "os";
import React from "react";
import { RadioOptionI } from "../../../../utils/constants/framedOptions";

// scss
import s from "./DistributorLinks.module.scss"

export type DistributorLinkT = {
  text: string;
  link: string;
}

export const DistributorLinks = ({ ...props }) => {
  const { values } = props;
  return (
    <div className={s.distributorsLinks}>
      {values.map((value: DistributorLinkT) => (
        <a href={value["link"]} key={Math.floor(100000 + Math.random() * 900000)} target="_blank">{value["text"]}</a>
      ))}
    </div>
  );
};
