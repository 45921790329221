import { ICoordinates, IDisplayAttributeArrayValue } from "@threekit-tools/treble/dist/types";
import { WallItemT } from "../../utils/constants/nodesNamesThreekit";
import { getModelsBaseNullOnWall } from "../cabinets/getNodesCabinets";
import { getIntervalsInfoOnWallForCabinetsBase } from "./getIntervalsInfoOnWallBase";
import { getPlaneNameFromWallName } from "../wallsAndFloor/getWallPlanesInfo";
import { getWidthModelFromMetadataAttribute } from "../cabinets/metadata";
import { ArrWallRangesT, getIntervalForModel, getIntervalsInfoOnWall } from "./getIntervalsInfoOnWall";
import { getIntervalsForPositionedNewCabinetBase } from "./intervalsInfoForPositionedNewCabinetsBase";

/**
 * Перебирає values атрибута, які мають додатися в кімнату, та перевіряє чи є місце для нових моделей на стіні
 *
 * @param {ArrWallRangesT} intervalsWall Інтервали для обраної стіни
 * @param {IDisplayAttributeArrayValue[]} valuesAttributeForAdded Масив values атрибуту, які мають додатися на сцену
 * @return {boolean} true - на стіні є місце для додавання моделей, false - на стіні немає місця для додавання моделей
 */
const checkIntervalInWallForValuesAttribute = (
  intervalsWall: ArrWallRangesT,
  valuesAttributeForAdded: IDisplayAttributeArrayValue[],
) => {

  let isIntervalInWall = true;

  valuesAttributeForAdded.forEach((valueAttribute) => {
    const widthModel = getWidthModelFromMetadataAttribute(valueAttribute);
    if (widthModel === null) {
      return new Error(`For model ${valueAttribute["name"]} there is no metadata - width.
       This data describes the width size of the model. Please add the correct metadata for the model's item in Threekit.`);
    }
    const intervalForModel = getIntervalForModel(
      intervalsWall,
      widthModel
    );
    if (intervalForModel === undefined) isIntervalInWall = false;
  });

  return isIntervalInWall;

}

/**
 * Перевіряє чи є на стіні вільне місце для додавання нових напольних шкафів
 *
 * @param {WallItemT} wallName Name стіни, для якої робимо перевірку
 * @param {IDisplayAttributeArrayValue[]} valuesAttributeForAdded Масив values атрибуту, які мають додатися на сцену
 * @return {boolean} true - на стіні є місце для додавання моделей, false - на стіні немає місця для додавання моделей
 */
export const checkEmptyIntervalInWallForCabinetsBase = (
  wallName: WallItemT,
  valuesAttributeForAdded: IDisplayAttributeArrayValue[]
): boolean => {
  // Отримуємо список моделей, що розташовані на обраній стіні currentWallName
  const modelsBaseNullOnWall = getModelsBaseNullOnWall(wallName);
  // Отримуємо інтервали для обраної стіни currentWallName
  const intervalsForCurrentWall = getIntervalsInfoOnWallForCabinetsBase(
    modelsBaseNullOnWall,
    getPlaneNameFromWallName(wallName)
  );
  const isIntervalInWall = checkIntervalInWallForValuesAttribute(
    intervalsForCurrentWall,
    valuesAttributeForAdded
  );
  return isIntervalInWall;
};

/**
 * Перевіряє чи є на стіні вільне місце для додавання нового напольного шкафа
 *
 * @param {WallItemT} wallName Name стіни, для якої робимо перевірку
 * @param {IDisplayAttributeArrayValue[]} valuesAttributeForAdded Масив values атрибуту, які мають додатися на сцену
 * @return {boolean} true - на стіні є місце для додавання моделей, false - на стіні немає місця для додавання моделей
 */
export const checkEmptyIntervalInWallForCabinetBase = (
  wallName: WallItemT,
  sizeModel: ICoordinates
): boolean => {


  
  // // Отримуємо список моделей, що розташовані на обраній стіні currentWallName
  // const modelsBaseNullOnWall = getModelsBaseNullOnWall(wallName);
  // // Отримуємо інтервали для обраної стіни currentWallName
  // const intervalsForCurrentWall = getIntervalsInfoOnWallForCabinetsBase(
  //   modelsBaseNullOnWall,
  //   getPlaneNameFromWallName(wallName)
  // );



  // Отримуємо масив інтервалів для стіни, на якій знаходиться модель
  // з урахуванням розташування моделей на сусідніх стінах близько до спільних
  const intervalsForCkeckNewPosition = getIntervalsForPositionedNewCabinetBase(wallName, sizeModel);
  console.log("intervalsForCkeckNewPosition --- ==== ", intervalsForCkeckNewPosition);
  const intervalForModel = getIntervalForModel(
    intervalsForCkeckNewPosition,
    sizeModel["x"]
  );
  return intervalForModel !== undefined;
};

/**
 * Перевіряє чи є на стіні вільне місце для додавання нового настінного шкафа
 *
 * @param {WallItemT} wallName Name стіни, для якої робимо перевірку
 * @param {IDisplayAttributeArrayValue[]} valuesAttributeForAdded Масив values атрибуту, які мають додатися на сцену
 * @return {boolean} true - на стіні є місце для додавання моделей, false - на стіні немає місця для додавання моделей
 */
export const checkEmptyIntervalInWallForCabinetsWall = (
  wallName: WallItemT,
  valuesAttributeForAdded: IDisplayAttributeArrayValue[]
): boolean => {
  // Отримуємо інтервали для обраної стіни currentWallName
  const intervalsForCurrentWall = getIntervalsInfoOnWall(
    getPlaneNameFromWallName(wallName)
  );
  const isIntervalInWall = checkIntervalInWallForValuesAttribute(
    intervalsForCurrentWall,
    valuesAttributeForAdded
  );
  return isIntervalInWall;
};