export enum COLORS_VALUES {
  WHITE = "WT",
  GREY = "GR",
}

export type ColorOptionsValuesT = COLORS_VALUES.WHITE | COLORS_VALUES.GREY;

export interface ValueCabinetColorI {
  id: string;
  value: COLORS_VALUES;
  label: string;
  color: string;
}

export interface CabinetColorGroupI {
  label: string;
  value: string;
  values: ValueCabinetColorI[];
}

export interface CabinetColorI {
  [key: string]: CabinetColorGroupI
}

export const ColorValues: ValueCabinetColorI[] = [
  {
    id: "WhiteShaker",
    value: COLORS_VALUES.WHITE,
    label: "White Shaker",
    color: '#EBE7E5',
  },
  {
    id: "GreyShaker",
    value: COLORS_VALUES.GREY,
    label: "Grey Shaker",
    color: '#BBB6AC',
  },
]

export const CabinetColor: CabinetColorI = {
  ColorAllCabinets: {
    label: "All Cabinets Color",
    value: '',
    values: ColorValues
  },
  ColorBaseCabinets: {
    label: "Base Cabinets Color",
    value: '',
    values: ColorValues
  },
  ColorWallCabinets: {
    label: "Wall Cabinets Color",
    value: '',
    values: ColorValues
  },
  ColorIslandCabinets: {
    label: "Island Cabinets Color",
    value: '',
    values: ColorValues
  },
}