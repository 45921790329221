// scss
import s from "./Header.module.scss";

// images
import MainMenuLogo from "./../../assets/images/Scott Living 2021_Logo-Horiz.png";
import { Logo } from "../../../../Logo/Logo";

export const Header = () => {
  return (
    <div className={s.contentProducts_head}>
      <Logo />
      <div className={s.title}>
        Drawing Summary and Bill of Material
      </div>
    </div>
  );
};
