import { Provider } from "react-redux";
import { GlobalStyle } from "./components/UtilsComponents/GlobalStyle/GlobalStyle";
import { MyRouterProvider } from "./router/RouterProvider/RouterProvider";
import store from "./store/store";
import { ThreekitProvider } from "@threekit-tools/treble";
import { onAnnotationChange } from "./utils/threekit/annotation/annotationStyle";
import { useAppDispatch } from "./hooks/useStoreHooks";
import { THREEKIT_ENV } from "./utils/threekit/threekitConfig/threekitConfig";

export const InitializationProviderThreekit = () => {
  const dispatch = useAppDispatch();
  const playerConfig = {
    onAnnotationChange: (annotations: any, parentEl: any) =>
      onAnnotationChange(annotations, parentEl, dispatch),
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const shortId = params["shortId"];

  const projects = {
    products: {
      [THREEKIT_ENV]: {
        configurationId: shortId,
      },
    },
  };

  return (
    <ThreekitProvider
      threekitEnv={THREEKIT_ENV}
      //@ts-ignore
      playerConfig={playerConfig}
      //@ts-ignore
      project={!!shortId ? projects : {}}
    >
      <MyRouterProvider />
      <GlobalStyle />
    </ThreekitProvider>
  );
};

export const App = () => {
  return (
    <Provider store={store}>
      <InitializationProviderThreekit />
    </Provider>
  );
};
