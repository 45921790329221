import { useAppSelector } from "../../../../../hooks/useStoreHooks";
import { getSummaryCabinetsInfo, getSummaryFinishingInfo, getTotalPrice } from "../../../../../store/selectors/summarySelectors";
import NotImage from "./../../../../../assets/images/Cabinets/not_image.png";
import s from "./Products.module.scss";

export const Products = ({ ...props }) => {
  const { snapshotsObj } = props;
  const arrSummaryCabinetsInfo = useAppSelector(getSummaryCabinetsInfo);
  const arrSummaryFinishingInfo = useAppSelector(getSummaryFinishingInfo);
  const totalPrice = useAppSelector(getTotalPrice);
  return (
    <div className={s.productsList}>
      <div className={s.blockTitle}>What you need</div>
      <table>
        <thead>
          <tr>
            <th>Line # </th>
            <th>Image</th>
            <th>Qty</th>
            <th>Model #</th>
            <th>Color</th>
            <th>Description</th>
            <th>MSRP</th>
            <th>Home Depot Part #</th>
            <th>Lowes Part #</th>
            <th>Wayfair Part #</th>
          </tr>
        </thead>
        <tbody>
          {!!arrSummaryCabinetsInfo.length &&
            arrSummaryCabinetsInfo.map((cabinetsInfo, indx) => {
              return (
                <tr key={cabinetsInfo["ModelNumber"]}>
                  <td>{indx + 1}</td>
                  <td>
                    <img
                      className={s.img}
                      src={
                        cabinetsInfo["thumbnail"] &&
                        cabinetsInfo["thumbnail"] !== "https://thumbnail_xyz"
                          ? `${cabinetsInfo["thumbnail"]}`
                          : NotImage
                      }
                      alt={cabinetsInfo["Description"]}
                    />
                  </td>
                  <td>({cabinetsInfo["count"]})</td>
                  <td>{cabinetsInfo["ModelNumber"]}</td>
                  <td>{cabinetsInfo["Color"]}</td>
                  <td>{cabinetsInfo["Description"]}</td>
                  <td>{cabinetsInfo["MSRP"] !== "TBD" ? `$${Number(cabinetsInfo["MSRP"]) * cabinetsInfo["count"]}` : "TBD"}</td>
                  <td
                    style={{
                      borderLeft: "solid 1px #000",
                      borderRight: "solid 1px #000",
                      color: "blue",
                    }}
                  >
                    <a href={cabinetsInfo["Home Depot Part Link"]}>Coming Soon</a>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid 1px #000",
                      borderRight: "solid 1px #000",
                      color: "blue",
                    }}
                  >
                    <a href={cabinetsInfo["Lowes Part Link"]}>Coming Soon</a>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid 1px #000",
                      borderRight: "solid 1px #000",
                      color: "blue",
                    }}
                  >
                    <a href={cabinetsInfo["Wayfair Part Link"]}>Coming Soon</a>
                  </td>
                </tr>
              );
            })
          }

          {!!arrSummaryFinishingInfo.length &&
            arrSummaryFinishingInfo.map((cabinetsInfo, indx) => {
              return (
                <tr key={cabinetsInfo["ModelNumber"]}>
                  <td>{arrSummaryCabinetsInfo.length + indx + 1}</td>
                  <td>
                    <img
                      className={s.img}
                      src={
                        cabinetsInfo["thumbnail"] &&
                        cabinetsInfo["thumbnail"] !== "https://thumbnail_xyz"
                          ? `${cabinetsInfo["thumbnail"]}`
                          : NotImage
                      }
                      alt={cabinetsInfo["Description"]}
                    />
                  </td>
                  <td>({cabinetsInfo["count"]})</td>
                  <td>{cabinetsInfo["ModelNumber"]}</td>
                  <td>{cabinetsInfo["Color"]}</td>
                  <td>{cabinetsInfo["Description"]}</td>
                  <td>{cabinetsInfo["MSRP"] !== "TBD" ? `$${Number(cabinetsInfo["MSRP"]) * cabinetsInfo["count"]}` : "TBD"}</td>
                  <td
                    style={{
                      borderLeft: "solid 1px #000",
                      borderRight: "solid 1px #000",
                      color: "blue",
                    }}
                  >
                    <a href={cabinetsInfo["Home Depot Part Link"]}>Coming Soon</a>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid 1px #000",
                      borderRight: "solid 1px #000",
                      color: "blue",
                    }}
                  >
                    <a href={cabinetsInfo["Lowes Part Link"]}>Coming Soon</a>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid 1px #000",
                      borderRight: "solid 1px #000",
                      color: "blue",
                    }}
                  >
                    <a href={cabinetsInfo["Wayfair Part Link"]}>Coming Soon</a>
                  </td>
                </tr>
              );
            })
          }

          {!!!arrSummaryCabinetsInfo.length && !!!arrSummaryFinishingInfo.length && (
            <tr>
              <td colSpan={10}>Cabinets are missing. Start building the kitchen.</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td>Totals</td>
            <td>{arrSummaryCabinetsInfo.length + arrSummaryFinishingInfo.length}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{!isNaN(totalPrice) ? `$ ${totalPrice}` : "TBD"}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
