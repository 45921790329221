import { Store } from "@reduxjs/toolkit";
import {
  IConfigurationArray,
  IConfigurationAsset,
  ISceneResult,
} from "@threekit-tools/treble/dist/types";
import { updateDecorativeEnd } from "../../functionsConfigurator/cabinets/configuration/decorativePanel";
import { updateMoulding } from "../../functionsConfigurator/cabinets/configuration/moulding";
import { getNewAllObjectsLocationForRemoved } from "../../functionsConfigurator/cabinets/getNewAllObjectsLocationForRemoved";
import {
  ActiveAndNewValuesThreekitT,
  checkBlindCornerId,
  getObjNewValuesAttributeThreekit,
} from "../../functionsConfigurator/cabinets/getObjActiveAndNewValuesAttributeThreekit";
import { setPositionCabinetOnWall, setPositionNewCabinetsWallOnWall } from "../../functionsConfigurator/cabinets/cabinetsWall/moving/setPositionCabinetOnWall";
import { getNumberNodeThreekitFromName } from "../../functionsConfigurator/general";
import { getSinkSizeFromCabinetSinkId } from "../../functionsUI/appliances";
import { getProductInfoFilteredByPosition } from "../../functionsUI/cabinets/addModels";
import { isOptionIdMolding } from "../../functionsUI/cabinets/moulding";
import { TYPE_REDUCER } from "../../utils/constants/actionTypes";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";
import { APPLIANCES_MAX_LENGTH, CABINETS_BASE_MAX_LENGTH, CABINETS_ISLAND_MAX_LENGTH, CABINETS_WALL_MAX_LENGTH, PRODUCT_POSITIONS_KEYS } from "../../utils/constants/cabinets";
import {
  ModelsName_NodesT,
  NODES_THREEKIT,
  WallItemT,
} from "../../utils/constants/nodesNamesThreekit";
import {
  getAttributeThreekit,
  getFullConfigurationAttribute,
  getItemNodeFromNullModel,
  getValueAttributeThreekit,
} from "../../utils/threekit/general/getFunctions";
import {
  setConfigurationForOneCabinetType,
  setConfigurationFull,
  setDoorStyleForAllCabinetsType,
} from "../../functionsConfigurator/cabinets/configuration/stylingCabinets";
import {
  getActiveSettingUI,
  getKeySectionActive,
  getProductInfo,
  getValueOptionDecorativeEndCabinetsBase,
  getValueOptionDecorativeEndCabinetsIsland,
} from "../selectors/settingsUISelectors";
import {
  removeOptionArrValues,
  setAllSettingConfig,
  setCabinetControls,
  updateSettings,
} from "../slices/settingsUI/settingsUISlice";
import {
  ArrRemovedArrOptionsSettingsT,
  ArrUpdateSettingsT,
} from "../slices/settingsUI/typesSettingsUISlice";
import { setPositionCabinetsBaseOnWall, setPositionNewCabinetsBaseOnWall } from "../../functionsConfigurator/cabinets/cabinetsBase/moving/setPositionCabinetsBaseOnWall";
import {
  setConfigurationFullAll,
  setRotationThreekit,
  setTranslationThreekit,
} from "../../utils/threekit/general/setFunctions";
import { emulationMoveCabinetIsland } from "../../functionsConfigurator/cabinets/cabinetsIsland/moving/emulationMoveCabinetIsland";
import { updateDecorativeEndAppliances } from "../../functionsConfigurator/cabinets/configuration/decorativePanelAppliances";
import { getItemNumberFromMetadataNullName } from "../../functionsConfigurator/cabinets/metadata";
import { setModalVisible } from "../slices/modalsSlice/modalsSlice";
import { MODAL_KEYS } from "../slices/modalsSlice/modalsSliceT";
import { isOptionIdFridgePanels } from "../../functionsUI/cabinets/decorativePanel";
import { updateFrigePanels } from "../../functionsConfigurator/cabinets/configuration/fridgePanels";
import { getWallNameForAddedCabinetsBase } from "../../functionsConfigurator/cabinets/cabinetsBase/getWallForAddedCabinetsBase";
import { getWallNameForAddedCabinetsWall } from "../../functionsConfigurator/cabinets/cabinetsWall/getWallForAddedCabinetsWall";
import { getKeys } from "../../utils/other/getObjKeysFromType";
import { updateDimensionsCabinetsBase } from "../../functionsConfigurator/dimensions/dimensionsCabinetsBase";
import { updateDimensionsCabinetsWall } from "../../functionsConfigurator/dimensions/dimensionsCabinetsWall";
import { selectLastModel } from "../../functionsConfigurator/cabinets/selectLastModel";
import { checkCountertopCabinetsBase } from "../../functionsConfigurator/cabinets/configuration/countertop";

export const threekitMiddleware: any =
  (store: Store) => (next: any) => (action: any) => {
    switch (action.type) {
      case TYPE_REDUCER.UPDATE_SETTINGS_UI_AND_THREEKIT: {
        const payloadParams = action.payload as ArrUpdateSettingsT;

        payloadParams.forEach((param) => {
          const { optionId, value } = param;

          if (optionId.includes("DecorativeEnd")) {
            updateDecorativeEnd(undefined, param);
          }

          if (isOptionIdMolding(optionId)) {
            updateMoulding(param);
          }

          if (optionId.includes("Frame")) {
            if (typeof value === "string")
              setDoorStyleForAllCabinetsType(value, optionId);
          }

          if (optionId.includes("Color") && optionId.includes("AllCabinets")) {
            if (typeof value === "string")
              setDoorStyleForAllCabinetsType(value, optionId);
          } else if (
            optionId.includes("Color") &&
            !optionId.includes("AllCabinets")
          ) {
            if (typeof value === "string")
              setConfigurationForOneCabinetType(optionId, value);
          }

          if (isOptionIdFridgePanels(optionId)) {
            if (typeof value === "boolean")
              updateFrigePanels(value);
          }

        });

        store.dispatch(updateSettings(payloadParams));

        break;
      }

      case TYPE_REDUCER.ADD_MODELS: {
        store.dispatch(setModalVisible({modalId: MODAL_KEYS.LOADER, value: true}));
        const arrParams = action.payload as ArrUpdateSettingsT;
        const storeCopy = JSON.parse(JSON.stringify(store.getState()));
        const activeSectionKey = getKeySectionActive(storeCopy);
        const activeSettingUI = getActiveSettingUI(storeCopy);

        let allPromiceSetConfiguration: any[] = [];

        let valuesAttributeThreekitCabinetsBase: ActiveAndNewValuesThreekitT = {
          activeValues: getFullConfigurationAttribute(
            PRODUCT_POSITIONS_KEYS.BASE_CABINET
          ),
          newValues: [],
        };
        let valuesAttributeThreekitCabinetsWall: ActiveAndNewValuesThreekitT = {
          activeValues: getFullConfigurationAttribute(
            ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL
          ),
          newValues: [],
        };
        let valuesAttributeThreekitCabinetsIsland: ActiveAndNewValuesThreekitT =
          {
            activeValues: getFullConfigurationAttribute(
              PRODUCT_POSITIONS_KEYS.ISLAND_CABINET
            ),
            newValues: [],
          };
        let valuesAttributeThreekitAppliances: ActiveAndNewValuesThreekitT = {
          activeValues: getFullConfigurationAttribute(
            PRODUCT_POSITIONS_KEYS.APPLIANCES
          ),
          newValues: [],
        };
        let targetWallCabinetsBase: WallItemT | undefined = undefined;
        let targetWallCabinetsWall: WallItemT | undefined = undefined;

        arrParams.forEach((itemNewSetting) => {
          const { optionId, value, parentSectionId } = itemNewSetting;

          const productInfo = getProductInfo(storeCopy)(value);
          if (productInfo.length < 1) return;

          const objProductInfoFilteredByPosition =
            getProductInfoFilteredByPosition(productInfo);

          // Cabinets Island
          if (optionId === PRODUCT_POSITIONS_KEYS.ISLAND_CABINET) {
            const newValues = getObjNewValuesAttributeThreekit(
              objProductInfoFilteredByPosition[
                PRODUCT_POSITIONS_KEYS.BASE_CABINET
              ],
              ATTRIBUTES_NAMES_THREEKIT.CABINETS_ISLAND,
              activeSettingUI
            );
            valuesAttributeThreekitCabinetsIsland["newValues"] = [
              ...valuesAttributeThreekitCabinetsIsland["newValues"],
              ...newValues,
            ];
            return;
          }

          // Cabinets Base
          if (
            !!objProductInfoFilteredByPosition[
              PRODUCT_POSITIONS_KEYS.BASE_CABINET
            ]
          ) {

            targetWallCabinetsBase = getWallNameForAddedCabinetsBase(
              objProductInfoFilteredByPosition[
                PRODUCT_POSITIONS_KEYS.BASE_CABINET
              ]
            );

            if (targetWallCabinetsBase === undefined) {
              store.dispatch(setModalVisible({modalId: MODAL_KEYS.LOADER, value: false}));
              store.dispatch(setModalVisible({
                modalId: MODAL_KEYS.MESSAGE,
                value: true,
                message: "New Cabinets Base cannot be added to a room. Please make room for new cabinets."
              }));
              return;
            };

            const newValues = getObjNewValuesAttributeThreekit(
              objProductInfoFilteredByPosition[
                PRODUCT_POSITIONS_KEYS.BASE_CABINET
              ],
              ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE,
              activeSettingUI
            );
            valuesAttributeThreekitCabinetsBase["newValues"] = [
              ...valuesAttributeThreekitCabinetsBase["newValues"],
              ...newValues,
            ];
          }

          // Cabinets Wall
          if (
            !!objProductInfoFilteredByPosition[
              PRODUCT_POSITIONS_KEYS.WALL_CABINET
            ]
          ) {

            targetWallCabinetsWall = getWallNameForAddedCabinetsWall(
              objProductInfoFilteredByPosition[
                PRODUCT_POSITIONS_KEYS.WALL_CABINET
              ],
            );

            if (targetWallCabinetsWall === undefined) {
              store.dispatch(setModalVisible({modalId: MODAL_KEYS.LOADER, value: false}));
              store.dispatch(setModalVisible({
                modalId: MODAL_KEYS.MESSAGE,
                value: true,
                message: "New Cabinets Wall cannot be added to a room. Please make room for new cabinets."
              }));
              return;
            };

            if (productInfo[0]["label"].includes("Upper") && productInfo[0]["label"].includes("Pantry")) {
              store.dispatch(setModalVisible({
                modalId: MODAL_KEYS.MESSAGE,
                value: true,
                message: "The 18\" and 24\" Upper Pantry wall cabinets can only be placed above floor cabinets of the appropriate size (18\" or 24\"). Please move the Upper Pantry cabinet to the appropriate location."
              }));
            }

            const newValues = getObjNewValuesAttributeThreekit(
              objProductInfoFilteredByPosition[
                PRODUCT_POSITIONS_KEYS.WALL_CABINET
              ],
              ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL,
              activeSettingUI
            );
            valuesAttributeThreekitCabinetsWall["newValues"] = [
              ...valuesAttributeThreekitCabinetsWall["newValues"],
              ...newValues,
            ];
          }

          // Appliances
          if (
            !!objProductInfoFilteredByPosition[
              PRODUCT_POSITIONS_KEYS.APPLIANCES
            ]
          ) {
            const newValues = getObjNewValuesAttributeThreekit(
              objProductInfoFilteredByPosition[
                PRODUCT_POSITIONS_KEYS.APPLIANCES
              ],
              ATTRIBUTES_NAMES_THREEKIT.APPLIANCES,
              activeSettingUI
            );
            valuesAttributeThreekitAppliances["newValues"] = [
              ...valuesAttributeThreekitAppliances["newValues"],
              ...newValues,
            ];
          }
        });

        // Cabinets Base
        if (valuesAttributeThreekitCabinetsBase["newValues"].length > 0) {
          const attributeThreekit = getAttributeThreekit(ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE);
          // @ts-ignore
          const attributeMaxLength = attributeThreekit.maxLength !== undefined ? attributeThreekit.maxLength : CABINETS_BASE_MAX_LENGTH;
          if (valuesAttributeThreekitCabinetsBase["activeValues"].length < attributeMaxLength) {

            allPromiceSetConfiguration.push(
              setConfigurationFull(ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE, [
                ...valuesAttributeThreekitCabinetsBase["activeValues"],
                ...valuesAttributeThreekitCabinetsBase["newValues"],
              ])
            );

          } else {

            store.dispatch(setModalVisible({
              modalId: MODAL_KEYS.MESSAGE,
              value: true,
              message: `You cannot add more than ${attributeMaxLength} Cabinets Base to a room.`
            }));
            return store.dispatch(setModalVisible({modalId: MODAL_KEYS.LOADER, value: false}));

          }
          
        }

        // Cabinets Wall
        if (valuesAttributeThreekitCabinetsWall["newValues"].length > 0) {
          const attributeThreekit = getAttributeThreekit(ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL);
          // @ts-ignore
          const attributeMaxLength = attributeThreekit.maxLength !== undefined ? attributeThreekit.maxLength : CABINETS_WALL_MAX_LENGTH;
          if (valuesAttributeThreekitCabinetsWall["activeValues"].length < attributeMaxLength) {

            allPromiceSetConfiguration.push(
              setConfigurationFull(ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL, [
                ...valuesAttributeThreekitCabinetsWall["activeValues"],
                ...valuesAttributeThreekitCabinetsWall["newValues"],
              ])
            );

          } else {

            store.dispatch(setModalVisible({
              modalId: MODAL_KEYS.MESSAGE,
              value: true,
              message: `You cannot add more than ${attributeMaxLength} Cabinets Wall to a room.`
            }));
            return store.dispatch(setModalVisible({modalId: MODAL_KEYS.LOADER, value: false}));

          }
        }

        // Cabinets Island
        if (valuesAttributeThreekitCabinetsIsland["newValues"].length > 0) {
          const attributeThreekit = getAttributeThreekit(ATTRIBUTES_NAMES_THREEKIT.CABINETS_ISLAND);
          // @ts-ignore
          const attributeMaxLength = attributeThreekit.maxLength !== undefined ? attributeThreekit.maxLength : CABINETS_ISLAND_MAX_LENGTH;
          if (valuesAttributeThreekitCabinetsIsland["activeValues"].length < attributeMaxLength) {

            allPromiceSetConfiguration.push(
              setConfigurationFull(ATTRIBUTES_NAMES_THREEKIT.CABINETS_ISLAND, [
                ...valuesAttributeThreekitCabinetsIsland["activeValues"],
                ...valuesAttributeThreekitCabinetsIsland["newValues"],
              ])
            );

          } else {

            store.dispatch(setModalVisible({
              modalId: MODAL_KEYS.MESSAGE,
              value: true,
              message: `You cannot add more than ${attributeMaxLength} Cabinets Island to a room.`
            }));
            return store.dispatch(setModalVisible({modalId: MODAL_KEYS.LOADER, value: false}));

          }

        }

        // Appliances
        if (valuesAttributeThreekitAppliances["newValues"].length > 0) {
          const attributeThreekit = getAttributeThreekit(ATTRIBUTES_NAMES_THREEKIT.APPLIANCES);
          // @ts-ignore
          const attributeMaxLength = attributeThreekit.maxLength !== undefined ? attributeThreekit.maxLength : APPLIANCES_MAX_LENGTH;
          if (valuesAttributeThreekitAppliances["activeValues"].length < attributeMaxLength) {
            
            allPromiceSetConfiguration.push(
              setConfigurationFull(ATTRIBUTES_NAMES_THREEKIT.APPLIANCES, [
                ...valuesAttributeThreekitAppliances["activeValues"],
                ...valuesAttributeThreekitAppliances["newValues"],
              ])
            );

          } else {

            store.dispatch(setModalVisible({
              modalId: MODAL_KEYS.MESSAGE,
              value: true,
              message: `You cannot add more than ${attributeMaxLength} Appliances to a room.`
            }));
            return store.dispatch(setModalVisible({modalId: MODAL_KEYS.LOADER, value: false}));

          }

        }

        // const arrParamsFromParentSectionId: ArrUpdateSettingsT = [];
        // arrParams.forEach((param) => {

        //   const { optionId, value, parentSectionId } = param;

        //   if (Array.isArray(value) && (value.includes("1011") || value.includes("1015"))) {
        //     if (value.includes("1011")) {
        //       arrParamsFromParentSectionId.push({
        //         optionId: "Cabinets Appliances",
        //         value: [getSinkSizeFromCabinetSinkId("1011") as string],
        //         parentSectionId: "Appliances",
        //       })
        //     }
        //     if (value.includes("1015")) {
        //       arrParamsFromParentSectionId.push({
        //         optionId: "Cabinets Appliances",
        //         value: [getSinkSizeFromCabinetSinkId("1015") as string],
        //         parentSectionId: "Appliances",
        //       })
        //     }
        //   }

        //   arrParamsFromParentSectionId.push({
        //     ...param,
        //     parentSectionId: !!param["parentSectionId"] ? param["parentSectionId"] : activeSectionKey as string,
        //   })

        // });

        const arrParamsUpdated = arrParams.reduce(
          (arrParamsAcc: ArrUpdateSettingsT, param) => {
            const { optionId, value, parentSectionId } = param;
            let tempArr: ArrUpdateSettingsT = [];
            if (
              Array.isArray(value) &&
              (value.includes("1011") || value.includes("1015"))
            ) {
              if (value.includes("1011")) {
                tempArr.push({
                  optionId: "Cabinets Appliances",
                  value: [getSinkSizeFromCabinetSinkId("1011") as string],
                  parentSectionId: "Appliances",
                });
              }
              if (value.includes("1015")) {
                tempArr.push({
                  optionId: "Cabinets Appliances",
                  value: [getSinkSizeFromCabinetSinkId("1015") as string],
                  parentSectionId: "Appliances",
                });
              }
            }

            // check id Blind Corner cabinet
            if (Array.isArray(value)) {
              const indxIdBlindCorner = value.findIndex((item) =>
                checkBlindCornerId(item)
              );
              if (indxIdBlindCorner !== -1) {
                value[indxIdBlindCorner] =
                  value[indxIdBlindCorner].split("_")[0];
              }
            }

            tempArr.push({
              ...param,
              parentSectionId: !!param["parentSectionId"]
                ? param["parentSectionId"]
                : (activeSectionKey as string),
            });

            return [...arrParamsAcc, ...tempArr];
          },
          []
        );

        Promise.all(allPromiceSetConfiguration).then(async () => {
          if (valuesAttributeThreekitCabinetsWall["newValues"].length > 0) {
            if (targetWallCabinetsWall === undefined) return;
            // setPositionCabinetOnWall(valuesAttributeThreekitCabinetsWall, targetWallCabinetsWall);
            setPositionNewCabinetsWallOnWall(valuesAttributeThreekitCabinetsWall, targetWallCabinetsWall);
            updateMoulding();
            updateDimensionsCabinetsWall();
            selectLastModel(NODES_THREEKIT.MODEL_CABINET_WALL);
          }

          if (valuesAttributeThreekitCabinetsBase["newValues"].length > 0) {
            if (targetWallCabinetsBase === undefined) return;
            setPositionNewCabinetsBaseOnWall(valuesAttributeThreekitCabinetsBase, targetWallCabinetsBase);
            // setPositionCabinetsBaseOnWall(valuesAttributeThreekitCabinetsBase, targetWallCabinetsBase);
            setTimeout(() => {
              updateDecorativeEnd(PRODUCT_POSITIONS_KEYS.BASE_CABINET);
            }, 1);
            updateDimensionsCabinetsBase();
            selectLastModel(NODES_THREEKIT.MODEL_CABINET_BASE);
          }

          if (valuesAttributeThreekitCabinetsIsland["newValues"].length > 0) {
            emulationMoveCabinetIsland(
              valuesAttributeThreekitCabinetsIsland["newValues"].length,
              NODES_THREEKIT.MODEL_CABINET_ISLAND
            );
            setTimeout(() => {
              updateDecorativeEnd(PRODUCT_POSITIONS_KEYS.ISLAND_CABINET);
            }, 1);
            selectLastModel(NODES_THREEKIT.MODEL_CABINET_ISLAND);
          }

          if (valuesAttributeThreekitAppliances["newValues"].length > 0) {
            let decorativeEndCabinetsBaseValue =
              getValueOptionDecorativeEndCabinetsBase(storeCopy);
            let decorativeEndCabinetsIslandValue =
              getValueOptionDecorativeEndCabinetsIsland(storeCopy);

            emulationMoveCabinetIsland(
              valuesAttributeThreekitAppliances["newValues"].length,
              NODES_THREEKIT.MODEL_APPLIANCES
            );
            setTimeout(() => {
              updateDecorativeEndAppliances(
                decorativeEndCabinetsBaseValue,
                decorativeEndCabinetsIslandValue
              );
            }, 1);
            selectLastModel(NODES_THREEKIT.MODEL_APPLIANCES);
          }

          store.dispatch(updateSettings(arrParamsUpdated));
          store.dispatch(setModalVisible({modalId: MODAL_KEYS.LOADER, value: false}));
        });

        break;
      }

      // case TYPE_REDUCER.REMOVE_MODELS: {
      //   const arrNamesNullModel = action.payload as ModelsName_NodesT[];

      //   const arrIndexNullModelRemoved = arrNamesNullModel.map(
      //     (nameNullModel) => getNumberNodeThreekitFromName(nameNullModel)
      //   );
      //   const nameThreekitAttribute = arrNamesNullModel[0]
      //     .split("_")
      //     .slice(1, -1)
      //     .join(" ");
      //   let optionIdUI = nameThreekitAttribute;
      //   const regexpNullPointName = arrNamesNullModel[0]
      //     .split("_")
      //     .slice(0, -1)
      //     .join("_");
      //   const arrAttributeValueCurrent = getValueAttributeThreekit(
      //     nameThreekitAttribute
      //   ) as IConfigurationArray;
      //   const arrAttributeValueUpdated = arrAttributeValueCurrent.filter(
      //     (_: any, index: number) => !arrIndexNullModelRemoved.includes(index)
      //   );

      //   getNewAllObjectsLocationForRemoved(
      //     arrAttributeValueUpdated,
      //     arrIndexNullModelRemoved,
      //     `${regexpNullPointName}_`
      //   ).then((objectNullModels: any) => {
      //     // console.log('objectNullModels --- ==== ',objectNullModels);
      //     Object.keys(objectNullModels).forEach((nameNullModel) => {
      //       let rotation = objectNullModels[nameNullModel]["rotation"];
      //       let transition = objectNullModels[nameNullModel]["transition"];

      //       if (rotation) {
      //         setRotationThreekit({
      //           name: nameNullModel,
      //           value: rotation,
      //         });
      //       }

      //       if (transition) {
      //         setTranslationThreekit({
      //           name: nameNullModel,
      //           value: transition,
      //         });
      //       }
      //     });
      //   });

      //   const idsRemovedModelsUI = arrNamesNullModel.map((nameNullModel) => {
      //     // const itemModel = getItemNodeFromNullModel({name: nameNullModel});
      //     // const idModelUI = itemModel["name"].split("_")[0];
      //     const idModel = getItemNumberFromMetadataNullName(nameNullModel);
      //     return idModel;
      //   });

      //   const arrRemovedProductsUI: ArrRemovedArrOptionsSettingsT = [
      //     {
      //       optionId: optionIdUI,
      //       value: idsRemovedModelsUI,
      //       removedIndexes: arrIndexNullModelRemoved,
      //     },
      //   ];

      //   setConfigurationFull(
      //     nameThreekitAttribute,
      //     arrAttributeValueUpdated
      //   ).then(async (res) => {
      //     console.log("res--- ==== ", res);
      //     store.dispatch(removeOptionArrValues(arrRemovedProductsUI));
      //     //@ts-ignore
      //     window.threekit.player.selectionSet.clear();
      //     store.dispatch(
      //       setCabinetControls({
      //         visible: false,
      //         selectedModelName: "",
      //       })
      //     );
      //   });

      //   break;
      // }

      case TYPE_REDUCER.REMOVE_MODELS: {
        const arrNamesNullModel = action.payload as ModelsName_NodesT[];






        let objRemovedCabinetsByGroup: {
          [key in PRODUCT_POSITIONS_KEYS]?: ModelsName_NodesT[]
        } = {}

        arrNamesNullModel.forEach((nullNameModel) => {
          const nameThreekitAttribute = nullNameModel
            .split("_")
            .slice(1, -1)
            .join(" ") as PRODUCT_POSITIONS_KEYS;

          if (objRemovedCabinetsByGroup.hasOwnProperty(nameThreekitAttribute)) {
            objRemovedCabinetsByGroup[nameThreekitAttribute]?.push(nullNameModel);
          } else {
            objRemovedCabinetsByGroup[nameThreekitAttribute] = [nullNameModel];
          }

        })




        // console.log("objRemovedCabinetsByGroup --- ==== ", objRemovedCabinetsByGroup);





        const arrNamesAttributesUpdated = getKeys(objRemovedCabinetsByGroup);
        let objCabinetsAttributesUpdated: {
          [key in PRODUCT_POSITIONS_KEYS]?: IConfigurationArray
        } = {}
        let arrRemovedProductsUI: ArrRemovedArrOptionsSettingsT = [];

        arrNamesAttributesUpdated.forEach((cabinetsAttributeName) => {

          const arrNamesRemovedCabinets = objRemovedCabinetsByGroup[cabinetsAttributeName]?.slice().sort();

          if (arrNamesRemovedCabinets === undefined) return;

          const arrIndexesRemovedCabinetsForAttribute = arrNamesRemovedCabinets.map(
            (nameNullModel) => getNumberNodeThreekitFromName(nameNullModel)
          );
          const arrAttributeValueCurrent = getValueAttributeThreekit(
            cabinetsAttributeName
          ) as IConfigurationArray;
          const arrAttributeValueUpdated = arrAttributeValueCurrent.filter(
            (_: any, index: number) => !arrIndexesRemovedCabinetsForAttribute.includes(index)
          );
          const regexpNullPointName = arrNamesRemovedCabinets[0]
            .split("_")
            .slice(0, -1)
            .join("_");
          


          // ------------------------


          objCabinetsAttributesUpdated[cabinetsAttributeName] = arrAttributeValueUpdated;


          // ------------------------


          const idsRemovedModelsUI = arrNamesRemovedCabinets.map((nameNullModel) => {
            const idModel = getItemNumberFromMetadataNullName(nameNullModel);
            return idModel;
          });
          const optionIdUI = cabinetsAttributeName;
          arrRemovedProductsUI.push({
              optionId: optionIdUI,
              value: idsRemovedModelsUI,
              removedIndexes: arrIndexesRemovedCabinetsForAttribute,
          })


          // ------------------------


          getNewAllObjectsLocationForRemoved(
            arrAttributeValueUpdated,
            arrIndexesRemovedCabinetsForAttribute,
            `${regexpNullPointName}_`
          ).then((objectNullModels: any) => {
            // console.log('objectNullModels --- ==== ',objectNullModels);
            Object.keys(objectNullModels).forEach((nameNullModel) => {
              let rotation = objectNullModels[nameNullModel]["rotation"];
              let transition = objectNullModels[nameNullModel]["transition"];
  
              if (rotation) {
                setRotationThreekit({
                  name: nameNullModel,
                  value: rotation,
                });
              }
  
              if (transition) {
                setTranslationThreekit({
                  name: nameNullModel,
                  value: transition,
                });
              }
            });
          });



          
        })

        // console.log("objCabinetsAttributesUpdated --- ==== ", objCabinetsAttributesUpdated);
        // console.log("arrRemovedProductsUI --- ==== ", arrRemovedProductsUI);

        setConfigurationFullAll(objCabinetsAttributesUpdated).then(async (res) => {
          store.dispatch(removeOptionArrValues(arrRemovedProductsUI));
          //@ts-ignore
          window.threekit.player.selectionSet.clear();
          store.dispatch(
            setCabinetControls({
              visible: false,
              selectedModelName: "",
            })
          );

          if (objCabinetsAttributesUpdated.hasOwnProperty("Cabinets Wall"))
            checkCountertopCabinetsBase();
          
        });

        break;
      }

      case TYPE_REDUCER.REMOVE_ALL_MODELS: {
        setConfigurationFullAll({
          [ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE]: [],
          [ATTRIBUTES_NAMES_THREEKIT.CABINETS_ISLAND]: [],
          [ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL]: [],
          [ATTRIBUTES_NAMES_THREEKIT.APPLIANCES]: [],
        });

        //@ts-ignore
        const allCabinetsBase = window.threekit.player.scene.getAll({
          name: `${NODES_THREEKIT.MODEL_CABINET_BASE}*`,
        });
        //@ts-ignore
        const allCabinetsIsland = window.threekit.player.scene.getAll({
          name: `${NODES_THREEKIT.MODEL_CABINET_ISLAND}*`,
        });
        //@ts-ignore
        const allCabinetsWall = window.threekit.player.scene.getAll({
          name: `${NODES_THREEKIT.MODEL_CABINET_WALL}*`,
        });
        //@ts-ignore
        const allAppliances = window.threekit.player.scene.getAll({
          name: `${NODES_THREEKIT.MODEL_APPLIANCES}*`,
        });
        const allValues = [
          ...Object.values(allCabinetsBase),
          ...Object.values(allCabinetsIsland),
          ...Object.values(allCabinetsWall),
          ...Object.values(allAppliances),
        ];

        allValues.forEach((node: any) => {
          setTranslationThreekit({
            name: node["name"],
            value: { x: 0, y: 0, z: 0 },
          });
          setRotationThreekit({
            name: node["name"],
            value: { x: 0, y: 0, z: 0 },
          });
        });

        store.dispatch(setAllSettingConfig());

        break;
      }
    }

    let result = next(action);

    switch (action.type) {
      case TYPE_REDUCER.ADD_MODELS: {
        // console.log('TYPE_REDUCER.ADD_MODELS NEXT --- ==== ',);
        break;
      }
    }
    return result;
  };
