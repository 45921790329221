import AddingItemsToYourRoom from "./../../assets/images/Modals/Help/Adding items to your room.png";
import Cabinet from "./../../assets/images/Modals/Help/icons/cabinet.svg";
import Island from "./../../assets/images/Modals/Help/icons/island.svg";
import Appliances from "./../../assets/images/Modals/Help/icons/appliances.svg";
import Delete from "./../../assets/images/Modals/Help/icons/delete.svg";
import Clone from "./../../assets/images/Modals/Help/icons/clone.svg";
import CabinetBuild from "./../../assets/images/Modals/Help/icons/cabinet_build.svg";
import CabinetColor from "./../../assets/images/Modals/Help/icons/cabinet_color.svg";

export interface accordionHelp3D {
  id: number;
  label: string;
  content: any;
}

// Select an item in the room
// Use pop-up menu to       Delete or        Duplicate that iem

export const helpData3D: accordionHelp3D[] = [
  {
    id: Math.floor(100000 + Math.random() * 900000),
    label: "Adding items to your room",
    content: (
      <>
        <ol>
          <li>Select <img src={Cabinet} alt="" /> <b>Cabinet</b>, <img src={Island} alt="" /> <b>Island</b> or <img src={Appliances} alt="" /> <b>Appliance</b> in the Navigation</li>
          <li>Select an item</li>
          <li>The item will be placed in your room. You can relocate the item by dragging it to other visible walls.</li>
        </ol>
      </>
    ),
  },
  {
    id: Math.floor(100000 + Math.random() * 900000),
    label: "Removing/ Duplicating an item",
    content: (
      <>
        <ol>
          <li>Select an item in the room</li>
          <li>Use pop-up menu to <img src={Delete} alt="" /> <b>Delete</b> or <img src={Clone} alt="" /> <b>Duplicate</b> that iem</li>
        </ol>
      </>
    ),
  },
  {
    id: Math.floor(100000 + Math.random() * 900000),
    label: "Changing Cabinet Style",
    content: (
      <>
        <p>You can edit the style of the cabinet by using <img src={CabinetBuild} alt="" /> <b>Cabinet Build</b> and <img src={CabinetColor} alt="" /> <b>Cabinet Color</b> option in the Navigation</p>
        <p>Cabinet of the same kind will all share the same style. You can also use the <b>Apply to all</b> toggle to have same style for all Cabinet type. Individual cabinet stying isn not available.</p>
        <div>
          <p><img src={CabinetBuild} alt="" /> <b>Cabinet Build</b></p>
          <ul>
            <li>Frame Option</li>
            <li>Decorative end</li>
            <li>
              <ul>
                <li>A decorative end is an applied panel, mimicking your door style, that is attached to the exposed end of a run of cabinetry.</li>
              </ul>
            </li>
          </ul>
        </div>
        <p><img src={CabinetColor} alt="" /> <b>Cabinet Color</b></p>
      </>
    ),
  },
];