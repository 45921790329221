// scss
import s from "./Logo.module.scss";

// images
import MainMenuLogo from "./../../assets/images/Scott Living 2021_Logo-Horiz.png";

export const Logo = () => {
  return (
    <div className={s.mainLogo}>
      <img src={MainMenuLogo} alt="" />
    </div>
  );
};
