import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";
import { CabinetsAndFeatures_NodesT, ModelCabinetWallT, ModelsName_NodesT, PlaneCabinetsWallT } from "../../utils/constants/nodesNamesThreekit";
import { getBoxHeightThreekit, getInstanceIdAssetFromNullModel, getTranslationThreekit } from "../../utils/threekit/general/getFunctions";
import { getConfiguratorModelFromIdModel, getConfiguratorModelFromNullName } from "../cabinets/configuration/decorativePanel";
import { AllWallsIntervalsWallT, WallRangeT, getIntervalsWallCabinetsForAllWalls } from "../intervals/getIntervalsInfoOnWall";
import { isOnDimensions } from "./generalDimensions"
import { setTranslationThreekit, setVisible } from "../../utils/threekit/general/setFunctions";
import { getKeys } from "../../utils/other/getObjKeysFromType";
import { getWallNameFromPlaneName } from "../wallsAndFloor/getGeneralWallInfo";
import { isNullNameModelCabinetWallT } from "../cabinets/cabinetsWall/checkCabinetsWall";
import { getTypeConnectingBottomForModelFromIntervals, getTypeConnectingTopForModelFromIntervals } from "../cabinets/cabinetsWall/getTypeConnectingCabinetsWall";
import { checkIfCornerCabinetFromNullName } from "../cabinets/cabinetsBase/checkCornersCabinetsBase";
import { checkCornerCabinetInCornerFromIntervals } from "../intervals/checkCornerCabinetInCornerFromIntervals";
import { setNestedConfigurationFromNullName } from "../../utils/threekit/general/nestedConfigurator";

const WIDTH_DIMENTION_POINTS = {
  START: "Line Dimensions Width Start",
  END: "Line Dimensions Width End",
}
const EMPTY_LEFT_DIMENTION = {
  LINE: "Line Dimensions Empty Left",
  START: "Line Dimensions Empty Left Start",
  END: "Line Dimensions Empty Left End",
}
const EMPTY_RIGHT_DIMENTION = {
  LINE: "Line Dimensions Empty Right",
  START: "Line Dimensions Empty Right Start",
  END: "Line Dimensions Empty Right End",
}
const EMPTY_TOP_DIMENTION = {
  LINE: "Line Dimensions Empty Top",
  START: "Line Dimensions Empty Top Start",
  END: "Line Dimensions Empty Top End",
}
const EMPTY_BOTTOM_DIMENTION = {
  LINE: "Line Dimensions Empty Bottom",
  START: "Line Dimensions Empty Bottom Start",
  END: "Line Dimensions Empty Bottom End",
}

const getModelPositionY = (nameCabinetWall: CabinetsAndFeatures_NodesT) => {
  const position = getTranslationThreekit({name: nameCabinetWall});
  return position["y"];
}

/**
 * Позиціонує нижню точку для лінійки моделі "Line Dimensions Empty Bottom"(розміщена в асеті моделі) на рівні підлоги,
 * Ця лінійка визначає відстань від низу моделі до підлоги.
 *
 * @param {CabinetsAndFeatures_NodesT} nameCabinetWall Name для Model Null з Threekit.
 * @param {number} modelPositionY Позиція по Y для моделі (висота, на якій розташований низ моделі).
 */
const setPositionYPointEmptyBottomStart = (nameCabinetWall: CabinetsAndFeatures_NodesT, modelPositionY: number) => {
  const instanceIdAsset = getInstanceIdAssetFromNullModel({name: nameCabinetWall});
  const curentPositionPointEmptyBottomStart = getTranslationThreekit({
    from: instanceIdAsset,
    name: EMPTY_BOTTOM_DIMENTION.START,
  });
  setTranslationThreekit({
    from: instanceIdAsset,
    name: EMPTY_BOTTOM_DIMENTION.START,
    value: {
      ...curentPositionPointEmptyBottomStart,
      y: -modelPositionY,
    }
  })
}

const getPositionYPointEmptyTopEnd = (planeName: PlaneCabinetsWallT, posYModel: number) => {
  const wallname = getWallNameFromPlaneName(planeName);
  const heightWall = getBoxHeightThreekit({name: wallname});
  return heightWall - posYModel;
}

/**
 * Позиціонує верхню точку для лінійки моделі "Line Dimensions Empty Top End"(розміщена в асеті моделі) на рівні стелі,
 * Ця лінійка визначає відстань від верху моделі до стелі.
 *
 * @param {CabinetsAndFeatures_NodesT} nameCabinetWall Name для Model Null з Threekit.
 * @param {number} positionYPointEmptyTopEnd Позиція по Y для верхньої точки лінійки "Line Dimensions Empty Top End" (висота, на якій розташована стеля).
 */
const setPositionYPointEmptyTopEnd = (
  nameCabinetWall: CabinetsAndFeatures_NodesT,
  positionYPointEmptyTopEnd: number
) => {
  const instanceIdAsset = getInstanceIdAssetFromNullModel({name: nameCabinetWall});
  const curentPositionPointEmptyBottomStart = getTranslationThreekit({
    from: instanceIdAsset,
    name: EMPTY_TOP_DIMENTION.END,
  });
  setTranslationThreekit({
    from: instanceIdAsset,
    name: EMPTY_TOP_DIMENTION.END,
    value: {
      ...curentPositionPointEmptyBottomStart,
      y: positionYPointEmptyTopEnd,
    }
  })
}

const checkVisibilityTopLine = (
  nullNameCabinetWall: CabinetsAndFeatures_NodesT,
  intervalsCabinetsWall: AllWallsIntervalsWallT
) => {

  // Перевірка типу змінної nullNameCabinetWall
  if (!isNullNameModelCabinetWallT(nullNameCabinetWall)) return;

  const arrTypeConnectingTop = getTypeConnectingTopForModelFromIntervals(
    nullNameCabinetWall,
    intervalsCabinetsWall
  );
  const instanceIdAsset = getInstanceIdAssetFromNullModel({name: nullNameCabinetWall});

  if (arrTypeConnectingTop.includes("front")) {
    setVisible({
      from: instanceIdAsset,
      name: EMPTY_TOP_DIMENTION.LINE,
      value: false
    })
  } else {
    setVisible({
      from: instanceIdAsset,
      name: EMPTY_TOP_DIMENTION.LINE,
      value: true
    })
  }

}

const checkVisibilityBottomLine = (
  nullNameCabinetWall: CabinetsAndFeatures_NodesT,
  intervalsCabinetsWall: AllWallsIntervalsWallT
) => {

  // Перевірка типу змінної nullNameCabinetWall
  if (!isNullNameModelCabinetWallT(nullNameCabinetWall)) return;

  const arrTypeConnectingBottom = getTypeConnectingBottomForModelFromIntervals(
    nullNameCabinetWall,
    intervalsCabinetsWall
  );
  const instanceIdAsset = getInstanceIdAssetFromNullModel({name: nullNameCabinetWall});

  if (arrTypeConnectingBottom.includes("front")) {
    setVisible({
      from: instanceIdAsset,
      name: EMPTY_BOTTOM_DIMENTION.LINE,
      value: false
    })
  } else {
    setVisible({
      from: instanceIdAsset,
      name: EMPTY_BOTTOM_DIMENTION.LINE,
      value: true
    })
  }

}

const updateVisibilityVerticalLinesDimensions = (
  nullNameCabinetWall: CabinetsAndFeatures_NodesT,
  intervalsCabinetsWall: AllWallsIntervalsWallT
) => {
  checkVisibilityTopLine(nullNameCabinetWall, intervalsCabinetsWall);
  checkVisibilityBottomLine(nullNameCabinetWall, intervalsCabinetsWall);
};

export const updateDimensionsCabinetsWall = async () => {

  // @ts-ignore
  await window.threekit.player.evaluate();

  if (!isOnDimensions()) return;

  const intervalsCabinetsWall = getIntervalsWallCabinetsForAllWalls();
  const arrPlanesNames = getKeys(intervalsCabinetsWall);
  let arrPromices: Promise<any>[] = [];
  let cornerModelsInfo: {[key in CabinetsAndFeatures_NodesT]: {
    [ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_LEFT]: number,
    [ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_RIGHT]: number
  }} = {}

  arrPlanesNames.forEach((planeName) => {

    const arrIntervalsOnWall = intervalsCabinetsWall[planeName];

    if (arrIntervalsOnWall.length < 2) return;

    let prevObjInterval: WallRangeT | undefined = undefined;
    let isFirstIntervalEmpty: boolean = false;

    arrIntervalsOnWall.forEach((objInterval, index) => {
      const { empty, name, range } = objInterval;

      const nextObjInterval: WallRangeT | undefined = arrIntervalsOnWall[index + 1];

      if (index === 0 && empty) {
        isFirstIntervalEmpty = true;
      }

      if (!empty && name !== undefined) {
        // заповнений проміжок

        let objNewConfiguration = {
          [ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_LEFT]: 0,
          [ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_RIGHT]: 0
        };

        if (
          isFirstIntervalEmpty &&
          prevObjInterval !== undefined
        ) {
          const sizePrevEmptyInterval = prevObjInterval["range"][1] - prevObjInterval["range"][0];
          objNewConfiguration[ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_LEFT] = sizePrevEmptyInterval;
        } else {
          objNewConfiguration[ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_LEFT] = 0;
        }

        if (nextObjInterval !== undefined && nextObjInterval["empty"]) {
          const sizeNextEmptyInterval = nextObjInterval["range"][1] - nextObjInterval["range"][0];
          objNewConfiguration[ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_RIGHT] = sizeNextEmptyInterval;
        } else {
          objNewConfiguration[ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_RIGHT] = 0;
        }

        // налаштовуємо та робимо перевірки для кутових шкафів
        if (checkIfCornerCabinetFromNullName(name)) {

          const isCornerCabinetInCorner = checkCornerCabinetInCornerFromIntervals(
            intervalsCabinetsWall,
            name
          );

          arrPromices.push(setNestedConfigurationFromNullName({
            nullName: name,
            attributeName: ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL,
            configuration: {[ATTRIBUTES_NAMES_THREEKIT.IS_MODEL_CORNER_IN_CORNER]: isCornerCabinetInCorner}
          }));

          // Перевіряємо чи не був раніше доданий пустий проміжок зліва
          // (коли модель стойть в куті і вже провірялась на іншій стіні)
          if (cornerModelsInfo.hasOwnProperty(name)) {

            if (
              cornerModelsInfo[name][ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_LEFT] !== 0
            ) {
              objNewConfiguration[ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_LEFT] =
                cornerModelsInfo[name][ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_LEFT];
            }

            if (
              cornerModelsInfo[name][ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_RIGHT] !== 0
            ) {
              objNewConfiguration[ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_RIGHT] =
                cornerModelsInfo[name][ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_RIGHT];
            }

          } else {
            cornerModelsInfo[name] = objNewConfiguration;
          }

        }

        arrPromices.push(setNestedConfigurationFromNullName({
          nullName: name,
          attributeName: ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL,
          configuration: objNewConfiguration
        }));

        const modelPositionY = getModelPositionY(name);
        setPositionYPointEmptyBottomStart(name, modelPositionY);

        const dimensionTop = getPositionYPointEmptyTopEnd(planeName, modelPositionY);
        setPositionYPointEmptyTopEnd(name, dimensionTop);

        updateVisibilityVerticalLinesDimensions(name, intervalsCabinetsWall);

      }

      if (index > 0) {
        isFirstIntervalEmpty = false;
      }

      prevObjInterval = objInterval;

    })

  })

  return Promise.all(arrPromices)

}