import { CabinetsAndFeatures_NodesT, NODES_THREEKIT } from "../../utils/constants/nodesNamesThreekit";
import { getItemNodeFromNullModel } from "../../utils/threekit/general/getFunctions";

// ***** is Fridge or OTR Cabinet Wall *****
export const isItemNameOTR = (modelName: string): boolean => {
  return modelName.includes("OTR Wall Cabinet")
}

export const isOTRCabinetWall = (nullName: CabinetsAndFeatures_NodesT) => {
  const itemNodeFromNullModel = getItemNodeFromNullModel({name: nullName});
  return isItemNameOTR(itemNodeFromNullModel["name"]);
}


// ***** is Upper Pantry Cabinet Wall *****
export const isItemNamePantry = (modelName: string): boolean => {
  return modelName.includes("Upper Pantry")
}

export const isUpperPantryCabinetWall = (nullName: CabinetsAndFeatures_NodesT) => {
  const itemNodeFromNullModel = getItemNodeFromNullModel({name: nullName});
  return isItemNamePantry(itemNodeFromNullModel["name"]);
}

// ***** is Appliances *****
export const isNullNameAppliances = (nullName: string): boolean => {
  return nullName.includes(NODES_THREEKIT.MODEL_APPLIANCES);
}

export const isItemNameRefrigerator = (itemName: string): boolean => {
  return itemName.includes("Refrigerator");
}

export const isNullNameAppliancesFridge = (nullName: string): boolean => {
  const itemNodeFromNullModel = getItemNodeFromNullModel({name: nullName});
  return isItemNameRefrigerator(itemNodeFromNullModel["name"]);
}

// ***** is Cabinet Base *****
export const isNullNameCabinetBase = (nullName: string): boolean => {
  return nullName.includes(NODES_THREEKIT.MODEL_CABINET_BASE);
}