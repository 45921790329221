import {
  IConfiguration,
  IConfigurationAsset,
  IConfigurationArray,
  IDisplayAttributeArray,
  IDisplayAttributeArrayValue,
} from "@threekit-tools/treble/dist/types";
import { ProductArrT, ProductI } from "../../utils/constants/cabinets";
import {
  getAttributeThreekit,
  getFullConfigurationAttribute,
  getValuesAttributeThreekit,
} from "../../utils/threekit/general/getFunctions";
import { ArrActiveSettingT } from "../../store/slices/settingsUI/typesSettingsUISlice";
import {
  getConfigurationForCabinetMaterial,
  getConfigurationForDoorStyle,
} from "./configuration/stylingCabinets";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";
import { getItemNumberFromMetadata } from "./metadata";

export const getCabinetConfiguration = (
  currentCabinetsValues: IConfigurationAsset[]
): IConfiguration => {
  if (
    currentCabinetsValues.length < 1 ||
    !!!currentCabinetsValues[0]["configuration"]
  )
    return {};

  const currentConfiguration = currentCabinetsValues[0][
    "configuration"
  ] as IConfiguration;
  let newConfiguration: IConfiguration = {};

  Object.keys(currentConfiguration).forEach((configurationKey: string) => {
    if (
      configurationKey.includes("material") ||
      configurationKey.includes("Style")
    ) {
      newConfiguration = {
        ...newConfiguration,
        [configurationKey]: currentConfiguration[configurationKey],
      };
    }
  });

  return newConfiguration;
};

export const getConfigurationForNewCabinetFromSettingUI = (
  activeSettingUI: ArrActiveSettingT,
  nameAttribute: ATTRIBUTES_NAMES_THREEKIT
) => {
  let configurationFromUI = {};
  const cabinetBuild = activeSettingUI.find(
    (setting) => setting.sectionId === "CabinetBuild"
  );
  const cabinetColor = activeSettingUI.find(
    (setting) => setting.sectionId === "CabinetColor"
  );
  if (cabinetBuild) {
    const listOptionsObj = cabinetBuild["listOptionsObj"];
    Object.keys(listOptionsObj).forEach((key: any) => {
      if (key.includes("AllCabinets_Frame")) {
        const value: any = listOptionsObj[key].value;
        const configurationForDoorStyle = getConfigurationForDoorStyle(value);
        configurationFromUI = {
          ...configurationFromUI,
          ...configurationForDoorStyle,
        };
      }
    });
  }
  if (cabinetColor) {
    const listOptionsObj = cabinetColor["listOptionsObj"];
    Object.keys(listOptionsObj).forEach((key: any) => {
      if (
        key.includes("BaseCabinets_Color") &&
        nameAttribute === ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE
      ) {
        const value: any = listOptionsObj[key].value;
        const configurationForCabinetMaterial =
          getConfigurationForCabinetMaterial(value);
        configurationFromUI = {
          ...configurationFromUI,
          ...configurationForCabinetMaterial,
        };
      }
      if (
        key.includes("WallCabinets_Color") &&
        nameAttribute === ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL
      ) {
        const value: any = listOptionsObj[key].value;
        const configurationForCabinetMaterial =
          getConfigurationForCabinetMaterial(value);
        configurationFromUI = {
          ...configurationFromUI,
          ...configurationForCabinetMaterial,
        };
      }
      if (
        key.includes("IslandCabinets_Color") &&
        nameAttribute === ATTRIBUTES_NAMES_THREEKIT.CABINETS_ISLAND
      ) {
        const value: any = listOptionsObj[key].value;
        const configurationForCabinetMaterial =
          getConfigurationForCabinetMaterial(value);
        configurationFromUI = {
          ...configurationFromUI,
          ...configurationForCabinetMaterial,
        };
      }
    });
  }
  if (nameAttribute === ATTRIBUTES_NAMES_THREEKIT.CABINETS_ISLAND) {
    configurationFromUI = {
      ...configurationFromUI,
      isVisibleConnectorsCabinetsBase: false,
      isVisibleConnectorsCabinetsIsland: true,
    };
  }
  return configurationFromUI;
};

interface ArrAttributeThreekitValuesI {
  values: IDisplayAttributeArrayValue[];
  value: IConfigurationArray;
}
export const getObjAttributeThreekitValues = (
  nameAttribute: string
): ArrAttributeThreekitValuesI => {
  // є проблема з типом IThreekitDisplayAttribute тому ми використовуємо IDisplayAttributeAsset
  const attributeThreekitInfo = getAttributeThreekit(
    nameAttribute
  ) as IDisplayAttributeArray;
  const attributeThreekitValues = attributeThreekitInfo["values"];
  const currentValues = getFullConfigurationAttribute(nameAttribute);
  // const currentConfiguration = getCabinetConfiguration(currentValues);
  return {
    values: attributeThreekitValues,
    value: currentValues,
  };
};

export type ConfigurationNewModelT = {
  assetId: string;
  configuration?: any;
};
export type ActiveAndNewValuesThreekitT = {
  activeValues: ConfigurationNewModelT[];
  newValues: ConfigurationNewModelT[];
};
export const getObjActiveAndNewValuesAttributeThreekit = (
  arrProductsInfo: ProductArrT | undefined,
  nameAttribute: string
): ActiveAndNewValuesThreekitT => {
  const resultObj: ActiveAndNewValuesThreekitT = {
    activeValues: [],
    newValues: [],
  };

  if (!!!arrProductsInfo) return resultObj;

  const objAttributeThreekitValues =
    getObjAttributeThreekitValues(nameAttribute);

  resultObj["activeValues"] = objAttributeThreekitValues["value"];

  arrProductsInfo.forEach((productInfo) => {
    const threekitValue = getThreekitProductValue(
      objAttributeThreekitValues["values"],
      productInfo
    );
    if (!!threekitValue) {
      resultObj["newValues"].push({
        assetId: threekitValue["assetId"],
        // configuration: currentConfiguration,
      });
    }
  });

  return resultObj;
};

export const checkBlindCornerId = (idProductUI: string): boolean =>
  idProductUI.includes("1016");

/**
 * Функція шукає значення атрибуту по id з UI, щоб додати в активні значення атрибуту типу массив.
 *
 * @param {IConfigurationArrayValue[]} attributeThreekitValues Всі можливі значенн я атрибуту, типу масив.
 * @param {ProductI} productInfo Об'єкт продукту з UI.
 * @returns {IConfigurationArrayValue | undefined} Якщо значення знайдене, то повертаэ значення атрибуту.
 */
const getThreekitProductValue = (
  attributeThreekitValues: IDisplayAttributeArrayValue[],
  productInfo: ProductI
): IDisplayAttributeArrayValue | undefined => {
  return attributeThreekitValues.find((threekitValueItem: any) => {
    const nameThreekit = threekitValueItem["name"];
    const itemNumberFromMetadata = getItemNumberFromMetadata(threekitValueItem);

    if (checkBlindCornerId(productInfo["id"])) {
      let wordsArr = productInfo["label"].split(" ");
      const sideBlindCorner = wordsArr[wordsArr.length - 1];

      if (itemNumberFromMetadata === undefined)
        return (
          threekitValueItem["name"].includes(productInfo["id"].split("_")[0]) &&
          threekitValueItem["name"].includes(sideBlindCorner)
        );

      return (
        productInfo["id"].includes(itemNumberFromMetadata) &&
        nameThreekit.includes(sideBlindCorner)
      );
    }

    if (itemNumberFromMetadata === undefined)
      return threekitValueItem["name"].includes(productInfo["id"]);

    return itemNumberFromMetadata === productInfo["id"];
  });
};

export const getObjNewValuesAttributeThreekit = (
  arrProductsInfo: ProductArrT | undefined,
  nameAttribute: ATTRIBUTES_NAMES_THREEKIT,
  activeSettingUI: ArrActiveSettingT
): ConfigurationNewModelT[] => {
  const newValues: ConfigurationNewModelT[] = [];

  if (!!!arrProductsInfo) return newValues;

  const attributeThreekitValues = getValuesAttributeThreekit(nameAttribute);

  arrProductsInfo.forEach((productInfo) => {
    const threekitValue = getThreekitProductValue(
      attributeThreekitValues,
      productInfo
    );
    const configurationForNewCabinet =
      getConfigurationForNewCabinetFromSettingUI(
        activeSettingUI,
        nameAttribute
      );
    if (!!threekitValue) {
      newValues.push({
        assetId: threekitValue["assetId"],
        configuration: configurationForNewCabinet,
      });
    }
  });

  return newValues;
};

export const getValuesAttributeFromProducts = (
  arrProductsInfo: ProductArrT | undefined,
  nameAttribute: ATTRIBUTES_NAMES_THREEKIT,
): IDisplayAttributeArrayValue[] => {
  const arrValues: IDisplayAttributeArrayValue[] = [];

  if (!!!arrProductsInfo) return arrValues;

  const attributeThreekitValues = getValuesAttributeThreekit(nameAttribute);

  arrProductsInfo.forEach((productInfo) => {
    const threekitValue = getThreekitProductValue(
      attributeThreekitValues,
      productInfo
    );
    if (threekitValue !== undefined) {
      arrValues.push(threekitValue);
    }
  });

  return arrValues;
}
