import { PRODUCT_POSITIONS_KEYS } from "../../utils/constants/cabinets";
import { WallItemT } from "../../utils/constants/nodesNamesThreekit";
import { getKeys } from "../../utils/other/getObjKeysFromType";
import { getPlaneNameFromWallName } from "../wallsAndFloor/getWallPlanesInfo";
import { ArrWallRangesT, getIntervalsWallCabinetsForAllWalls } from "./getIntervalsInfoOnWall";
import { getIntervalsBaseCabinetsForAllWalls } from "./getIntervalsInfoOnWallBase";

interface WallSummaryObjIntervals {
  [PRODUCT_POSITIONS_KEYS.BASE_CABINET]: ArrWallRangesT;
  [PRODUCT_POSITIONS_KEYS.WALL_CABINET]: ArrWallRangesT;
}
type SummaryObjIntervalsForAllWallsT = {
  [key in WallItemT]: WallSummaryObjIntervals;
}

export const getSummaryObjIntervalsForAllWalls = (): SummaryObjIntervalsForAllWallsT => {
  const intervalsCabinetsBase = getIntervalsBaseCabinetsForAllWalls();
  const intervalsCabinetsWall = getIntervalsWallCabinetsForAllWalls();
  const keysIntervalsCabinetsBase = getKeys(intervalsCabinetsBase);

  const resultObj = keysIntervalsCabinetsBase.reduce((accumulator: SummaryObjIntervalsForAllWallsT, wallName: WallItemT) => {
    const planeName = getPlaneNameFromWallName(wallName);
    return {
      ...accumulator,
      [wallName]: {
        [PRODUCT_POSITIONS_KEYS.BASE_CABINET]: intervalsCabinetsBase[wallName],
        [PRODUCT_POSITIONS_KEYS.WALL_CABINET]: intervalsCabinetsWall[planeName],
      }
    }
  }, {})

  return resultObj;
}