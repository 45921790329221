// images
import IconCabinets from "./../../assets/images/svg/Menu/3D/Cabinets.svg"
import IconIsland from "./../../assets/images/svg/Menu/3D/Island.svg"
import IconAppliances from "./../../assets/images/svg/Menu/3D/Appliances.svg"
import CabinetBuild from "./../../assets/images/svg/Menu/3D/Cabinet Build.svg"
import CabinetColor from "./../../assets/images/svg/Menu/3D/Cabinet Color.svg"

export interface MainMenuItem3DI {
  key: string;
  text: string;
  imgUrl: string;
}

export type MainMenu3DT = MainMenuItem3DI[]

export const mainMenu3D: MainMenu3DT = [
  {
    key: 'Cabinets',
    text: 'Cabinets',
    imgUrl: IconCabinets,
  },
  {
    key: 'Island',
    text: 'Island',
    imgUrl: IconIsland,
  },
  {
    key: 'Appliances',
    text: 'Appliances',
    imgUrl: IconAppliances,
  },
  {
    key: 'CabinetBuild',
    text: 'Cabinet Build',
    imgUrl: CabinetBuild,
  },
  {
    key: 'CabinetColor',
    text: 'Cabinet Color',
    imgUrl: CabinetColor,
  },
]