import { IConfiguration, IConfigurationArray } from "@threekit-tools/treble/dist/types";
import { ArrSummaryPriceDataTableT, ObjSummaryCabinetsT } from "../../functionsUI/summaryInfo/getArrSummaryProductsForCabinets";
import { getColorValueDataTableFromColorUI } from "../../functionsUI/сompareColorUIAndColorDataTable";
import { getFrameValueDataTableFromFrameUI } from "../../functionsUI/сompareFrameUIAndFrameDataTable";
import { ArrPriceDataTableT } from "../../store/slices/dataTablesSlice/dataTablesSliceT";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";
import { ColorOptionsValuesT } from "../../utils/constants/colorsRoom";
import { FramedOptionsValuesT } from "../../utils/constants/framedOptions";
import { getFullConfigurationAttribute } from "../../utils/threekit/general/getFunctions";
import { ATTRIBUTES_FILLER_STRIP } from "../cabinets/configuration/fillerStripOTR";
import { THREEKIT_IMAGES_URL } from "../../utils/threekit/threekitConfig/threekitConfig";

const ID_FILLER_STRIP = "1110";

export const getSummaryFillerStripOTR = (
  priceDatatable: ArrPriceDataTableT,
  frameValue: FramedOptionsValuesT,
  colorValue: ColorOptionsValuesT
): ArrSummaryPriceDataTableT => {

  const convertedFrameValue = getFrameValueDataTableFromFrameUI(frameValue);
  const convertedColorValue = getColorValueDataTableFromColorUI(colorValue);
  const fullConfigurationCabinetsWall = getFullConfigurationAttribute(
    ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL
  ) as IConfigurationArray;

  let objSummaryFillerStripOTR: ObjSummaryCabinetsT = {};

  fullConfigurationCabinetsWall.forEach((attributeFullValue) => {
    const configuration = attributeFullValue["configuration"] as IConfiguration;
    if (
      !!configuration &&
      !!configuration[ATTRIBUTES_FILLER_STRIP.FILLER_STRIP] &&
      configuration[ATTRIBUTES_FILLER_STRIP.FILLER_STRIP] === "yes"
    ) {

      const id = ID_FILLER_STRIP;

      let count = 1;

      if (objSummaryFillerStripOTR.hasOwnProperty(id)) {
        objSummaryFillerStripOTR[id]["count"] =
          objSummaryFillerStripOTR[id]["count"] + count;
        return;
      }

      const productInfoFromDataTable = priceDatatable.find(
        (objProduct) =>
          objProduct["ItemNumber"] === id &&
          objProduct["Framed"] === convertedFrameValue &&
          objProduct["Color"] === convertedColorValue
      );

      if (!!productInfoFromDataTable) {
        objSummaryFillerStripOTR[id] = {
          ...productInfoFromDataTable,
          thumbnail: `${THREEKIT_IMAGES_URL}${productInfoFromDataTable["thumbnail"]}`,
          count: count,
        };
      }

    }
  });

  return Object.values(objSummaryFillerStripOTR);
};