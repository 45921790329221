import PresetIcon1 from "./../../assets/room-list/Preset_Shape.svg";
import PresetIcon2 from "./../../assets/room-list/Preset_Shape-1.svg";
import PresetIcon3 from "./../../assets/room-list/Preset_Shape-2.svg";
import PresetIcon4 from "./../../assets/room-list/Preset_Shape-3.svg";
import PresetIcon5 from "./../../assets/room-list/Preset_Shape-4.svg";
import PresetIcon6 from "./../../assets/room-list/Preset_Shape-5.svg";

type WallTemplateConnectorsT = string;
export interface WallTemplateI {
  _tkSpace_connections: WallTemplateConnectorsT;
  _tkSpace_startX: number;
  _tkSpace_startZ: number;
  _tkSpace_endX: number;
  _tkSpace_endZ: number;
  _tkSpace_wallHeight: number;
  _tkSpace_wallHeightLocal: number;
  _tkSpace_lockHeight: boolean;
  _tkSpace_wallThickness: number;
  _tkSpace_wallThicknessLocal: number;
  _tkSpace_lockThickness: boolean;
  _tkSpace_isVisibleWall: boolean;
  _tkSpace_visibility?: any;
}
export type ArrWallTemplateT = WallTemplateI[];

export interface InitialTemplateI {
  id: string;
  icon: string;
  title?: string;
  data: ArrWallTemplateT;
}
export type InitialTemplatesListT = InitialTemplateI[];

export const roomTemplate01: ArrWallTemplateT = [
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 0,
    _tkSpace_startZ: 0,
    _tkSpace_endX: 4.572,
    _tkSpace_endZ: 0,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: true,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 4.572,
    _tkSpace_startZ: 0,
    _tkSpace_endX: 4.572,
    _tkSpace_endZ: 4.572,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: false,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 4.572,
    _tkSpace_startZ: 4.572,
    _tkSpace_endX: 0,
    _tkSpace_endZ: 4.572,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: false,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 0,
    _tkSpace_startZ: 4.572,
    _tkSpace_endX: 0,
    _tkSpace_endZ: 0,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: false,
  },
];

export const roomTemplate02: ArrWallTemplateT = [
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 0,
    _tkSpace_startZ: 0,
    _tkSpace_endX: 4.572,
    _tkSpace_endZ: 0,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: true,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 4.572,
    _tkSpace_startZ: 0,
    _tkSpace_endX: 4.572,
    _tkSpace_endZ: 4.572,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: false,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 4.572,
    _tkSpace_startZ: 4.572,
    _tkSpace_endX: 0,
    _tkSpace_endZ: 4.572,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: false,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 0,
    _tkSpace_startZ: 4.572,
    _tkSpace_endX: 0,
    _tkSpace_endZ: 0,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: true,
  },
];

export const roomTemplate03: ArrWallTemplateT = [
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 0,
    _tkSpace_startZ: 0,
    _tkSpace_endX: 4.572,
    _tkSpace_endZ: 0,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: true,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 4.572,
    _tkSpace_startZ: 0,
    _tkSpace_endX: 4.572,
    _tkSpace_endZ: 4.572,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: true,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 4.572,
    _tkSpace_startZ: 4.572,
    _tkSpace_endX: 0,
    _tkSpace_endZ: 4.572,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: false,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 0,
    _tkSpace_startZ: 4.572,
    _tkSpace_endX: 0,
    _tkSpace_endZ: 0,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: false,
  },
];

export const roomTemplate04: ArrWallTemplateT = [
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 0,
    _tkSpace_startZ: 0,
    _tkSpace_endX: 4.572,
    _tkSpace_endZ: 0,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: true,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 4.572,
    _tkSpace_startZ: 0,
    _tkSpace_endX: 4.572,
    _tkSpace_endZ: 4.572,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: true,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 4.572,
    _tkSpace_startZ: 4.572,
    _tkSpace_endX: 0,
    _tkSpace_endZ: 4.572,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: false,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 0,
    _tkSpace_startZ: 4.572,
    _tkSpace_endX: 0,
    _tkSpace_endZ: 0,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: true,
  },
];

export const roomTemplate05: ArrWallTemplateT = [
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 0,
    _tkSpace_startZ: 0,
    _tkSpace_endX: 4.572,
    _tkSpace_endZ: 0,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: true,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 4.572,
    _tkSpace_startZ: 0,
    _tkSpace_endX: 4.572,
    _tkSpace_endZ: 4.572,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: true,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 4.572,
    _tkSpace_startZ: 4.572,
    _tkSpace_endX: 0,
    _tkSpace_endZ: 4.572,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: true,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 0,
    _tkSpace_startZ: 4.572,
    _tkSpace_endX: 0,
    _tkSpace_endZ: 0,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: true,
  },
];

export const roomTemplate06: ArrWallTemplateT = [
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 0,
    _tkSpace_startZ: 0,
    _tkSpace_endX: 4.572,
    _tkSpace_endZ: 0,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: true,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 4.572,
    _tkSpace_startZ: 0,
    _tkSpace_endX: 4.572,
    _tkSpace_endZ: 4.572,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: false,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 4.572,
    _tkSpace_startZ: 4.572,
    _tkSpace_endX: 0,
    _tkSpace_endZ: 4.572,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: true,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 0,
    _tkSpace_startZ: 4.572,
    _tkSpace_endX: 0,
    _tkSpace_endZ: 0,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: false,
  },
];

export const roomTemplateTest: ArrWallTemplateT = [
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 0,
    _tkSpace_startZ: 0,
    _tkSpace_endX: 3.49504,
    _tkSpace_endZ: 0,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: true,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 3.49504,
    _tkSpace_startZ: 0,
    _tkSpace_endX: 3.49504,
    _tkSpace_endZ: 2.1463,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: false,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 3.49504,
    _tkSpace_startZ: 2.1463,
    _tkSpace_endX: 0,
    _tkSpace_endZ: 2.1463,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: true,
  },
  {
    _tkSpace_connections: "[]",
    _tkSpace_startX: 0,
    _tkSpace_startZ: 2.1463,
    _tkSpace_endX: 0,
    _tkSpace_endZ: 0,
    _tkSpace_wallHeight: 3,
    _tkSpace_wallHeightLocal: 0,
    _tkSpace_lockHeight: true,
    _tkSpace_wallThickness: 0.1143,
    _tkSpace_wallThicknessLocal: 0,
    _tkSpace_lockThickness: true,
    _tkSpace_isVisibleWall: false,
  },
];

export const initialTemplatesList: InitialTemplatesListT = [
  {
    id: `preset_${Math.floor(100000 + Math.random() * 900000)}`,
    icon: PresetIcon1,
    title : "Single Wall, Open Concept",
    data: roomTemplate01,
  },
  {
    id: `preset_${Math.floor(100000 + Math.random() * 900000)}`,
    icon: PresetIcon2,
    title : "Corner Left, Open Concept ",
    data: roomTemplate02,
  },
  {
    id: `preset_${Math.floor(100000 + Math.random() * 900000)}`,
    icon: PresetIcon3,
    title : "Corner Right, Open Concept",
    data: roomTemplate03,
  },
  {
    id: `preset_${Math.floor(100000 + Math.random() * 900000)}`,
    icon: PresetIcon4,
    title : "U Shape,  Open Concept",
    data: roomTemplate04,
  },
  {
    id: `preset_${Math.floor(100000 + Math.random() * 900000)}`,
    icon: PresetIcon5,
    title : "Rectangular Shape",
    data: roomTemplate05,
  },
  {
    id: `preset_${Math.floor(100000 + Math.random() * 900000)}`,
    icon: PresetIcon6,
    title : "Galley Shape",
    data: roomTemplate06,
  },
];