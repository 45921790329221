import React, { useState, useEffect, useLayoutEffect } from "react";
import s from "./InputFeetAndInches.module.scss";
import { InperialUnitsI, convertFeetAndInchesToMeters, convertMetersToFeetAndInches } from "../../../functionsConfigurator/configurator2D/units";
import { useDebouncedFunction } from "../../../hooks/useDebouncedFunction";

type InputFeetAndInchesT = {
  valueDefault: any;
  onCountChange: (value: number) => void;
};

export interface InperialUnitsStringI {
  feet: string;
  inches: string;
}

const InputFeetAndInches = ({
  valueDefault,
  onCountChange,
}: InputFeetAndInchesT) => {

  const [imperialValue, setImperialValue] = useState<InperialUnitsI>({feet: 0, inches: 0});

  const handleFeetChange = (value: string) => {
    const newValue = parseFloat(value);
    if (
      onCountChange !== undefined &&
      typeof newValue === "number" &&
      !Number.isNaN(newValue)
    ) {
      const newImperialValue = {
        feet: newValue,
        inches: imperialValue["inches"]
      };
      onCountChange(convertFeetAndInchesToMeters(newImperialValue));
    }
  };

  const debouncedFeetValueDispatching = useDebouncedFunction(
    handleFeetChange,
    500
  );

  function onChangeFeetValue(event: React.ChangeEvent<HTMLInputElement>) {
    const numericValue = event.target.value.replace(/\D/g, ''); // видаляємо все, крім цифр
    const newImperialValue = {
      feet: numericValue,
      inches: imperialValue["inches"]
    };
    setImperialValue(newImperialValue);
    debouncedFeetValueDispatching(numericValue);
  }

  const handleInchesChange = (value: string) => {
    const newValue = parseFloat(value);
    if (
      onCountChange !== undefined &&
      typeof newValue === "number" &&
      !Number.isNaN(newValue)
    ) {
      const newImperialValue = {
        feet: imperialValue["feet"],
        inches: newValue
      };
      onCountChange(convertFeetAndInchesToMeters(newImperialValue));
    }
  };

  const debouncedInchesValueDispatching = useDebouncedFunction(
    handleInchesChange,
    500
  );

  function onChangeInchesValue(event: React.ChangeEvent<HTMLInputElement>) {
    const numericValue = event.target.value.replace(/,+/g, '.').replace(/[^0-9.]/g, '');
    const newImperialValue = {
      feet: imperialValue["feet"],
      inches: numericValue
    };
    setImperialValue(newImperialValue);
    debouncedInchesValueDispatching(numericValue);
  }

  useLayoutEffect(() => {
    const valueFeetInches = convertMetersToFeetAndInches(valueDefault);
    setImperialValue({
      feet: String(valueFeetInches["feet"]),
      inches: String(valueFeetInches["inches"]),
    });
  }, [])

  return (
    <div className={s.container}>
      <div className={s.inputs}>
        <div className={s.boxNumber}>
          <input
            type="text"
            id="feet"
            name="feet"
            value={imperialValue["feet"]}
            onChange={onChangeFeetValue}
          />
          <span>ft</span>
        </div>
        <div className={s.boxNumber}>
          <input
            type="text"
            id="inches"
            name="inches"
            value={imperialValue["inches"]}
            onChange={onChangeInchesValue}
          />
          <span>inch</span>
        </div>
      </div>
    </div>
  );
};

export default InputFeetAndInches;
