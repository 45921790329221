import { IConfiguration, IConfigurationArray, IConfigurationAttribute } from "@threekit-tools/treble/dist/types";
import { getSummaryObjIntervalsForAllWalls } from "../intervals/getSummaryObjIntervals";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";
import { IntervalWallI, checkEqualsCoordsWalls, getIntervalWallRounding } from "../configurator2D/wallsGeneral";
import { isEqual } from "lodash";
import { ModelsName_NodesT, NODES_THREEKIT, WallItemT } from "../../utils/constants/nodesNamesThreekit";
import { getKeys } from "../../utils/other/getObjKeysFromType";
import { PRODUCT_POSITIONS_KEYS } from "../../utils/constants/cabinets";
import { isFeaturesModelNullName } from "../features/general";
import { Dispatch } from "@reduxjs/toolkit";
import { removeModels } from "../../store/actions/player.action";
import { getModelsBaseNullOnWall } from "./getNodesCabinets";
import { getArrWallsMovedToCenterCoordinates } from "../wallsAndFloor/buildWallFromData";
import { moveCabinetBaseOnWall } from "./cabinetsBase/moving/moveAllCabinetsBaseOnWall";
import * as THREE from "three";
import { getNumberNodeThreekitFromName } from "../general";
import { getPlaneNameFromWallName } from "../wallsAndFloor/getWallPlanesInfo";
import { getModelsWallNullOnPlane } from "../intervals/getIntervalsInfoOnWall";

function isIConfigurationArray(obj: IConfigurationAttribute): obj is IConfigurationArray {
  return typeof obj === 'object' && obj !== null && 'arrayProperty' in obj;
}

type ObjWallsCheckEditedT = {
  [key in WallItemT]: {
    edited: boolean;
  }
}

const getObjWallsEdited = (
  oldConfiguration2D: IConfiguration,
  newConfiguration2D: IConfiguration,
): ObjWallsCheckEditedT => {

  const oldWallsConfiguration = oldConfiguration2D[ATTRIBUTES_NAMES_THREEKIT.WALLS];
  const newWallsConfiguration = newConfiguration2D[ATTRIBUTES_NAMES_THREEKIT.WALLS];

  let objWallsCheckEdited: ObjWallsCheckEditedT = {}

  if (Array.isArray(oldWallsConfiguration) && Array.isArray(newWallsConfiguration)) {

    oldWallsConfiguration.forEach((configurationAssetOld, index) => {

      const wallName = `${NODES_THREEKIT.WALL_ITEM}${index}`;
      const oldWallConfiguration = configurationAssetOld["configuration"];

      if (oldWallConfiguration === undefined) {
        return new Error("Not Found configuration for value attribute \"Walls\"")
      }

      const coordsWallOld = getIntervalWallRounding(configurationAssetOld);

      let isEqualCoordsWall = false;
      let isEqualConfiguration = false;

      newWallsConfiguration.forEach((configurationAssetNew) => {

        // якщо співпадіння по координатах знайдено, то інші стіни не перевіряємо
        if (isEqualCoordsWall) return;

        const newWallConfiguration = configurationAssetNew["configuration"];

        if (newWallConfiguration === undefined) {
          return new Error("Not Found configuration for value attribute \"Walls\"")
        }

        const coordsWallNew = getIntervalWallRounding(configurationAssetNew);

        isEqualCoordsWall = checkEqualsCoordsWalls(coordsWallOld, coordsWallNew);

        // існує нова стіна, координати якої співпадають з координатами старої стіни
        // отже перевіряємо умови на повне співпадіння конфігурацій стін
        if (isEqualCoordsWall)
          isEqualConfiguration = isEqual(oldWallConfiguration, newWallConfiguration);
          

      });

      if (isEqualCoordsWall && isEqualConfiguration) {
        return objWallsCheckEdited = {
          ...objWallsCheckEdited,
          [wallName]: {
            edited: false,
          }
        }
      }

      return objWallsCheckEdited = {
        ...objWallsCheckEdited,
        [wallName]: {
          edited: true,
        }
      }

    });
  }

  return objWallsCheckEdited;

}

const getRemovedModelsList = (
  objWallsEdited: ObjWallsCheckEditedT
): ModelsName_NodesT[] => {
  const summaryObjIntervalsForAllWalls = getSummaryObjIntervalsForAllWalls();
  const keysObjWallsEdited = getKeys(objWallsEdited);
  let arrRemovedModels: ModelsName_NodesT[] = []
  keysObjWallsEdited.forEach((wallName) => {
    if (objWallsEdited[wallName]["edited"]) {
      const wallIntervalsCabinetsBase = summaryObjIntervalsForAllWalls[wallName][PRODUCT_POSITIONS_KEYS.BASE_CABINET];
      const wallIntervalsCabinetsWall = summaryObjIntervalsForAllWalls[wallName][PRODUCT_POSITIONS_KEYS.WALL_CABINET];
      const allWallIntervals = [ ...wallIntervalsCabinetsBase, ...wallIntervalsCabinetsWall ];
      allWallIntervals.forEach((objInterval) => {
        if (
          !objInterval["empty"] &&
          objInterval["name"] !== undefined &&
          !isFeaturesModelNullName(objInterval["name"])
        ) {
          const nameModel = objInterval["name"] as ModelsName_NodesT;
          arrRemovedModels.push(nameModel);
        }
      })
    }
  })
  return arrRemovedModels;
}

function areLinesParallel(line1: IntervalWallI, line2: IntervalWallI): boolean {
  // Перевірка, чи різниці координат на вісі X та Y однакові для обох ліній
  const deltaX1 = line1.end.x - line1.start.x;
  const deltaY1 = line1.end.y - line1.start.y;

  const deltaX2 = line2.end.x - line2.start.x;
  const deltaY2 = line2.end.y - line2.start.y;

  // Паралельність ліній можна визначити за умовою, що векторні співвідношення різниць координат однакові
  const isParallel = deltaX1 * deltaY2 === deltaX2 * deltaY1;

  return isParallel;
}

function distanceBetweenParallelLines(line1: IntervalWallI, line2: IntervalWallI): number {
  // Перевірка, чи лінії паралельні
  const deltaX1 = line1.end.x - line1.start.x;
  const deltaY1 = line1.end.y - line1.start.y;

  const deltaX2 = line2.end.x - line2.start.x;
  const deltaY2 = line2.end.y - line2.start.y;

  // Перевірка паралельності за векторним співвідношенням
  if (deltaX1 * deltaY2 === deltaX2 * deltaY1) {
    // Обчислення відстані між точкою (line1.start) і прямою, якою є лінія line2
    const numerator = Math.abs(
      (line2.end.y - line2.start.y) * line1.start.x -
        (line2.end.x - line2.start.x) * line1.start.y +
        line2.end.x * line2.start.y -
        line2.end.y * line2.start.x
    );

    const denominator = Math.sqrt(
      Math.pow(line2.end.y - line2.start.y, 2) +
        Math.pow(line2.end.x - line2.start.x, 2)
    );

    // Відстань визначається як модуль числового значення виразу
    const distance = numerator / denominator;

    return distance;
  } else {
    // Якщо лінії не паралельні, повертаємо NaN (Not a Number)
    return NaN;
  }
}

function directionVectorFromAToB(pointA: THREE.Vector2, pointB: THREE.Vector2): THREE.Vector2 {
  const directionVector = new THREE.Vector2(
    pointB.x - pointA.x,
    pointB.y - pointA.y
  );

  return directionVector;
}

interface moveModelsOnWallsI {
  oldConfiguration2D: IConfiguration,
  newConfiguration2D: IConfiguration,
  objWallsEdited: ObjWallsCheckEditedT,
}

const moveModelsOnWallsNotEdited = ({
  oldConfiguration2D,
  newConfiguration2D,
  objWallsEdited
}: moveModelsOnWallsI) => {

  Object.entries(objWallsEdited).forEach(entryWallsEdited => {
    if (!entryWallsEdited[1]["edited"]) {
      const wallName = entryWallsEdited[0] as WallItemT;
      const indexWall = getNumberNodeThreekitFromName(wallName);

      if (
        oldConfiguration2D[ATTRIBUTES_NAMES_THREEKIT.WALLS] === undefined ||
        !Array.isArray(oldConfiguration2D[ATTRIBUTES_NAMES_THREEKIT.WALLS]) ||
        newConfiguration2D[ATTRIBUTES_NAMES_THREEKIT.WALLS] === undefined ||
        !Array.isArray(newConfiguration2D[ATTRIBUTES_NAMES_THREEKIT.WALLS])
      ) return;

      const arrWallsMovedToCenterCoordinatesOLD = getArrWallsMovedToCenterCoordinates(oldConfiguration2D[ATTRIBUTES_NAMES_THREEKIT.WALLS]);
      const arrWallsMovedToCenterCoordinatesNEW = getArrWallsMovedToCenterCoordinates(newConfiguration2D[ATTRIBUTES_NAMES_THREEKIT.WALLS]);

      const oldWallConfiguration = arrWallsMovedToCenterCoordinatesOLD[indexWall];
      const newWallConfiguration = arrWallsMovedToCenterCoordinatesNEW[indexWall];

      const coordsWallOld = getIntervalWallRounding(oldWallConfiguration);
      const coordsWallNew = getIntervalWallRounding(newWallConfiguration);

      const isLinesParallel = areLinesParallel(coordsWallOld, coordsWallNew);
      const distance = distanceBetweenParallelLines(coordsWallOld, coordsWallNew);

      if (isLinesParallel && Math.abs(distance) > 0) {
        const vectorDirectionMove = directionVectorFromAToB(coordsWallOld["start"], coordsWallNew["start"]);
  
        const modelsBaseNullOnWall = getModelsBaseNullOnWall(wallName);
        const modelsWallNullOnWall = getModelsWallNullOnPlane(getPlaneNameFromWallName(wallName));
        const allModdelsOnWall = [ ...modelsBaseNullOnWall, ...modelsWallNullOnWall ];
  
        allModdelsOnWall.forEach((nullNameModel) => {
          moveCabinetBaseOnWall(nullNameModel, new THREE.Vector3(vectorDirectionMove["x"], 0, vectorDirectionMove["y"]).normalize(), distance)
        })
      }
      
    }
  })
}

export const updateRoomCabinets = (
  oldConfiguration2D: IConfiguration,
  newConfiguration2D: IConfiguration,
  dispatch: Dispatch
) => {
  const objWallsEdited = getObjWallsEdited(oldConfiguration2D, newConfiguration2D);
  const removedModelsList = getRemovedModelsList(objWallsEdited);
  
  moveModelsOnWallsNotEdited({
    oldConfiguration2D,
    newConfiguration2D,
    objWallsEdited
  })
  dispatch(removeModels(removedModelsList));
}