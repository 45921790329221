import React from "react";

export const Wall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="42"
      viewBox="0 0 90 42"
      fill="none"
    >
      <rect x="6" y="17" width="78" height="8" fill="#1F2944" />
      <circle cx="8" cy="21" r="5.5" fill="white" stroke="#36738E" />
      <circle cx="8" cy="21" r="2" fill="#36738E" />
      <circle cx="82" cy="21" r="5.5" fill="white" stroke="#36738E" />
      <circle cx="82" cy="21" r="2" fill="#36738E" />
    </svg>
  );
};
