import { createSelector } from "@reduxjs/toolkit";
import {
  ArrDependencySettingT,
  SECTIONS_KEYS,
  dependencyT,
} from "../../types/UI/dynamic-rendering.interfaces";
import {
  CategoryOption,
  groupCabinetsOptions,
  ProductArrT,
  ProductI,
  PRODUCT_GROUP_ALL_T,
  PRODUCT_POSITIONS_KEYS,
  PRODUCT_SECTION_GROUP_ALL_T,
  CategoryOptionImagesT,
} from "../../utils/constants/cabinets";
import {
  convertArrToSelectValues,
  getArrGroupsForType,
  getArrTypesFromArrGroups,
} from "../../functionsUI/configurationDataUI";
import {
  CabinetControlsI,
  listActiveSettingT,
  valueActiveOptionT,
} from "../slices/settingsUI/typesSettingsUISlice";
import { RootState } from "../store";
import { getUniqueArr } from "../../utils/other/arrData";
import { isProductGroupAppliances } from "../../functionsUI/appliances";
import { IUnits } from "@threekit-tools/treble/dist/hooks/useSpaces/types";
import { isSinkCabinetFilled } from "./appliancesSelectors";
import { ArrWallTemplateT, InitialTemplatesListT } from "../../utils/constants/presetsRoom";
import { FRAMED_OPTIONS_VALUES, FramedOptionsValuesT } from "../../utils/constants/framedOptions";
import { getFrameValueDataTableFromFrameUI } from "../../functionsUI/сompareFrameUIAndFrameDataTable";
import { getColorValueDataTableFromColorUI } from "../../functionsUI/сompareColorUIAndColorDataTable";
import { COLORS_VALUES, ColorOptionsValuesT } from "../../utils/constants/colorsRoom";
import { ArrPriceDataTableT } from "../slices/dataTablesSlice/dataTablesSliceT";
import { IConfiguration } from "@threekit-tools/treble/dist/types";

// підчистити не потрібне !#!

export const getKeySectionActive = (state: RootState) =>
  state["paramsConfiguration"]["sections"]["activeSection"];

export const getSettingConfig = (state: RootState) =>
  state["paramsConfiguration"]["settingUI"];

export const getAllProducts = (state: RootState): ProductArrT =>
  state["paramsConfiguration"]["allProducts"];

export const getInitialWalls = (state: RootState): ArrWallTemplateT =>
  state["paramsConfiguration"]["room2D"]["initialWalls"];

export const getPresetsRoomsList = (state: RootState): InitialTemplatesListT =>
  state["paramsConfiguration"]["room2D"]["presets"]["allPresets"];

export const getActivePresetRoom = (state: RootState): string =>
  state["paramsConfiguration"]["room2D"]["presets"]["active"];

export const getActiveSettingUI = (state: RootState) =>
  state["paramsConfiguration"]["activeSettingUI"];

export const getPriceDataTable = (state: RootState): ArrPriceDataTableT =>
  state["dataTables"]["priceTable"];

export const getSavedConfiguration2D = (state: RootState): IConfiguration =>
  state["paramsConfiguration"]["room2D"]["savedConfiguration2D"];

export const getValueFrameOption = (state: RootState): FramedOptionsValuesT => {
  return getValueOption({
    idOption: "AllCabinets_Frame",
    sectionId: "CabinetBuild",
  })(state) as FramedOptionsValuesT;
};

export const getValueOptionCabinetsBase = (state: RootState) => {
  return getValueOption({
    idOption: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    sectionId: "Cabinets",
  })(state) as string[];
};
export const getValueOptionCabinetsWall = (state: RootState) => {
  return getValueOption({
    idOption: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    sectionId: "Cabinets",
  })(state) as string[];
};
export const getValueOptionCabinetsIsland = (state: RootState) => {
  return getValueOption({
    idOption: PRODUCT_POSITIONS_KEYS.ISLAND_CABINET,
    sectionId: "Island",
  })(state) as string[];
};
export const getValueOptionDecorativeEndCabinetsBase = (state: RootState) => {
  return getValueOption({
    idOption: "CabinetsBase_DecorativeEnd",
    sectionId: "CabinetBuild",
  })(state) as boolean;
};
export const getValueOptionDecorativeEndCabinetsIsland = (state: RootState) => {
  return getValueOption({
    idOption: "CabinetsIsland_DecorativeEnd",
    sectionId: "CabinetBuild",
  })(state) as boolean;
};
export const getValueOptionFrigePanels = (state: RootState) => {
  return getValueOption({
    idOption: "CabinetsWall_FridgePanels",
    sectionId: "CabinetBuild",
  })(state) as boolean;
};
export const ID_PARAMETER_UPPER_MOULDING_IN_UI = "CabinetsWall_UpperMolding";
export const ID_PARAMETER_LOWER_MOULDING_IN_UI = "CabinetsWall_LowerMolding";
export const ID_PARAMETER_UPPER_MOULDING_SIZI_IN_UI = "CabinetsWall_UpperMoldingSize";
export const getValueOptionUpperMolding = (state: RootState): boolean => {
  const valueOption = getValueOption({
    idOption: ID_PARAMETER_UPPER_MOULDING_IN_UI,
    sectionId: "CabinetBuild",
  })(state);

  if (typeof valueOption !== "boolean") return false;

  return valueOption;
};
export const getValueOptionLowerMolding = (state: RootState): boolean => {
  const valueOption = getValueOption({
    idOption: ID_PARAMETER_LOWER_MOULDING_IN_UI,
    sectionId: "CabinetBuild",
  })(state);

  if (typeof valueOption !== "boolean") return false;

  return valueOption;
};
export const getValueOptionUpperMoldingSize = (state: RootState): string => {
  const valueOption = getValueOption({
    idOption: ID_PARAMETER_UPPER_MOULDING_SIZI_IN_UI,
    sectionId: "CabinetBuild",
  })(state);

  if (typeof valueOption !== "string") return "";

  return valueOption;
};
export const getValueOptionColorCabinetsBase = (state: RootState) => {
  return getValueOption({
    idOption: "BaseCabinets_Color",
    sectionId: "CabinetColor",
  })(state) as ColorOptionsValuesT;
};
export const getValueOptionColorCabinetsWall = (state: RootState) => {
  return getValueOption({
    idOption: "WallCabinets_Color",
    sectionId: "CabinetColor",
  })(state) as ColorOptionsValuesT;
};
export const getValueOptionColorCabinetsIsland = (state: RootState) => {
  return getValueOption({
    idOption: "IslandCabinets_Color",
    sectionId: "CabinetColor",
  })(state) as ColorOptionsValuesT;
};

// типізувати, перейти на find !#!
export const getActiveSection = createSelector(
  getSettingConfig,
  getKeySectionActive,
  (settingUI, SectionKey) => {
    let selectedSectionKey = SectionKey;

    let activeSection = settingUI.filter(
      (setting: any) => setting["key"] === selectedSectionKey
    );

    return activeSection[0] && activeSection[0]["setting"];
  }
);

export const getOptionsActiveSection = (state: RootState) => {
  const activeSection = getKeySectionActive(state);
  const activeSettingUI = getActiveSettingUI(state);

  let activeOptionsInSection = activeSettingUI.filter(
    (listActiveSetting) =>
      listActiveSetting["sectionId"] === activeSection
  )[0];

  return activeOptionsInSection;
};

// export type valueOption = {
//   selectValue: string;
//   isError: boolean;
// };
export type getValueOptionObjT = {
  idOption: string;
  sectionId?: any;
};

// подивитись до типізації у ф-ціях !#!
// спробувати видалити !#!
// export const getValueOptionObj =
//   ({ idOption, sectionId }: getValueOptionObjT) =>
//   (state: RootState): valueOption => {
//     let selectValue: string = "";

//     // const listErrorProp = geErrorProps({ idOption: idOptions })(state);
//     // let isError: boolean = listErrorProp.length > 0;
//     let isError: boolean = false;

//     let activeSectionOptions =
//       getOptionsActiveSection(state);

//     if (!!sectionId) {
//       const activeSettingUI: listActiveSettingT[] = getActiveSettingUI(state);
//       activeSectionOptions = activeSettingUI.filter(
//         (listActiveSetting: listActiveSettingT) =>
//           listActiveSetting["sectionId"] === sectionId
//       )[0];
//     }

//     if (!!!activeSectionOptions) return { selectValue: '', isError: isError };

//     const option = activeSectionOptions["listOptionsObj"][idOption];

//     if (option) selectValue = option["value"];

//     return { selectValue: selectValue, isError: isError };
//   };

export const getValueOption =
  ({ idOption, sectionId }: getValueOptionObjT) =>
  (state: RootState): valueActiveOptionT => {
    let selectValue: valueActiveOptionT = "";

    let activeSectionOptions = getOptionsActiveSection(state);

    if (!!sectionId) {
      const activeSettingUI: listActiveSettingT[] = getActiveSettingUI(state);
      activeSectionOptions = activeSettingUI.filter(
        (listActiveSetting: listActiveSettingT) =>
          listActiveSetting["sectionId"] === sectionId
      )[0];
    }

    if(activeSectionOptions === undefined) return selectValue;

    const option = activeSectionOptions["listOptionsObj"][idOption];

    if (option) selectValue = option["value"];

    return selectValue;
  };

type getTypeDependencySettingsT = {
  dependencyType: dependencyT;
  dependencySettings?: ArrDependencySettingT;
};
const getTypeDependencySettings = ({
  dependencySettings,
  dependencyType,
}: getTypeDependencySettingsT): ArrDependencySettingT => {
  if (!dependencySettings) return [];
  const dependency = dependencySettings.filter(
    (dependency) => dependency["dependencyType"] === dependencyType
  );
  return dependency;
};

export const getStatusDependencySettings =
  (dependencySettings: ArrDependencySettingT | undefined) => (state: RootState): boolean => {
    if (!dependencySettings) return true;
    if (dependencySettings.length < 1) return true;

    const visibleDependency = getTypeDependencySettings({
      dependencySettings,
      dependencyType: "isVisible",
    });
    const includeDependency = getTypeDependencySettings({
      dependencySettings,
      dependencyType: "isInclude",
    });
    // const includeSustemSetting = getTypeDependencySettings({
    //   dependencySettings,
    //   dependencyType: "isIncludeSustemSetting",
    // });

    let isVisibleDependency = false;
    let isIncludeDependency = false;
    // let isIncludeSustemSetting = false;

    if (!isVisibleDependency) {
      visibleDependency.forEach((dependency) => {
        const dependencyValue = dependency["isValue"];

        let valueOption = getValueOption({
          idOption: dependency["idParams"],
        })(state);

        if (String(valueOption)) {
          if (
            Array.isArray(dependencyValue) &&
            dependencyValue.includes(valueOption)
          ) {
            isIncludeDependency = true;
          }

          if (dependencyValue === valueOption) {
            isIncludeDependency = true;
          }
        }
      });
    }

    if (!isIncludeDependency) {
      includeDependency.forEach((dependency) => {
        const dependencyValue = dependency["isValue"];

        let valueOption = getValueOption({
          idOption: dependency["idParams"],
        })(state);

        if (String(valueOption)) {
          const isArrayDep = Array.isArray(dependencyValue);

          if (
            isArrayDep &&
            !Array.isArray(valueOption) &&
            dependencyValue.includes(valueOption)
          )
            isIncludeDependency = true;

          if (isArrayDep && Array.isArray(valueOption)) {
            valueOption.forEach((item) => {
              if (dependencyValue.includes(item)) isIncludeDependency = true;
            });
          }

          if (dependencyValue === valueOption) isIncludeDependency = true;
        }
      });
    }

    // if (!isIncludeSustemSetting) {
    //   includeSustemSetting.forEach((dependency) => {
    //     const dependencyValue = dependency["isValue"];

    //     let infoProperty = getInfoLogicSetting(dependency["idParams"])(state);
    //     if (infoProperty) {
    //       const isArrayDep = Array.isArray(dependencyValue);

    //       if (
    //         isArrayDep &&
    //         !Array.isArray(infoProperty["value"]) &&
    //         dependencyValue.includes(infoProperty["value"])
    //       )
    //         isIncludeDependency = true;
    //       if (isArrayDep && Array.isArray(infoProperty["value"])) {
    //         infoProperty["value"].forEach((item) => {
    //           if (dependencyValue.includes(item)) isIncludeDependency = true;
    //         });
    //       }
    //       if (dependencyValue === infoProperty["value"])
    //         isIncludeDependency = true;
    //     }
    //   });
    // }

    return isVisibleDependency || isIncludeDependency;
  };

export const getCurrentPosition = (state: RootState): PRODUCT_POSITIONS_KEYS => {
  const sectionId = getKeySectionActive(state);
  let position: PRODUCT_POSITIONS_KEYS;
  switch (sectionId) {
    case "Cabinets":
      position = getValueOption({ idOption: "PositionCabinet" })(
        state
      ) as PRODUCT_POSITIONS_KEYS;
      break;
    case "Island":
      position = PRODUCT_POSITIONS_KEYS.ISLAND_CABINET;
      break;
    case "Appliances":
      position = PRODUCT_POSITIONS_KEYS.APPLIANCES;
      break;
    default:
      position = PRODUCT_POSITIONS_KEYS.BASE_CABINET;
  }
  return position;
}

export const getFilteringValuesForGroup =
  (groupValues: CategoryOption[], id: string) =>
  (
    state: RootState
  ): { filteringValues: CategoryOption[]; isVisibleGroup: boolean } => {
    const { listOptionsObj, sectionId } = getOptionsActiveSection(state);
    const allProducts = getAllProducts(state);
    let isVisibleGroup = true;

    let position = getCurrentPosition(state);

    const valueType = listOptionsObj[`FilterGroup_${position.replace(/\s/g, "")}_Type`]
      ? listOptionsObj[`FilterGroup_${position.replace(/\s/g, "")}_Type`]["value"]
      : undefined;
    const valueWidth = listOptionsObj[`FilterGroup_${position.replace(/\s/g, "")}_Width`]
      ? listOptionsObj[`FilterGroup_${position.replace(/\s/g, "")}_Width`]["value"]
      : undefined;

    if (!!valueType && valueType !== "All" && id !== valueType)
      isVisibleGroup = false;

    if (valueWidth === "All")
      return { filteringValues: groupValues, isVisibleGroup };

    let filteringValues: CategoryOption[] = [];
    groupValues.forEach((groupValue: CategoryOption) => {
      const productIndex = allProducts.findIndex((product: ProductI) => {
        const isGroup = product["group"] === groupValue["value"];
        const isWidth = product["size"]["width"] === Number(valueWidth);
        return isGroup && isWidth;
      });
      if (productIndex !== -1) filteringValues.push(groupValue);
    });

    // для Appliances
    if (isProductGroupAppliances(id)) {
      filteringValues = [];
      groupValues.forEach((groupValue: CategoryOption) => {
        const product = allProducts.find((product: ProductI) => {
          const isGroup = product["group"] === groupValue["value"];
          const isWidth = product["size"]["width"] === Number(valueWidth);
          return isGroup && isWidth;
        });
        if (!!product && product["id"] === String(groupValue["id"])) {
          filteringValues.push(groupValue);
        }
      });
    }

    return { filteringValues, isVisibleGroup };
  };

export const getFilteringProductsValues = (id?: string) => (state: RootState): ProductArrT => {
  const { listOptionsObj, sectionId } = getOptionsActiveSection(state);
  const allProducts = getAllProducts(state);

  let valuePositionCabinet = listOptionsObj[`PositionCabinet`]
    ? listOptionsObj[`PositionCabinet`]["value"]
    : undefined;
  const valueCategoryCabinet = listOptionsObj[`CategoryCabinet`]
    ? listOptionsObj[`CategoryCabinet`]["value"]
    : undefined;
  const valueWidthProduts = listOptionsObj[`WidthProduts`]
    ? listOptionsObj[`WidthProduts`]["value"]
    : undefined;

  if (sectionId === "Island")
    valuePositionCabinet = PRODUCT_POSITIONS_KEYS.BASE_CABINET;

  let filteringProducts = allProducts.filter(
    (product: ProductI) => {
      const isPosition = product["position"] === valuePositionCabinet;
      const isCategory = product["group"] === valueCategoryCabinet;
      const isWidth =
        valueWidthProduts !== "All"
          ? product["size"]["width"] === Number(valueWidthProduts)
          : true;
      return isPosition && isCategory && isWidth;
    }
  );

  // Check disabled products
  // # disabled sink cabinets
  // const activeSinkCabinet = isSinkCabinetFilled(state);
  // if (activeSinkCabinet !== undefined) {
  //   filteringProducts = filteringProducts.map((product) => {
  //     if (product["value"].includes("Sink")) {
  //       return {
  //         ...product,
  //         disabled: true,
  //       }
  //     }
  //     return product;
  //   })
  // }

  return filteringProducts;
};

export const getActiveValuesForSelectStandart =
  (id: string, valuesDefault: any[]) => (state: RootState) => {
    const { listOptionsObj, sectionId } = getOptionsActiveSection(state);
    const allProducts = getAllProducts(state);
    let valuesArr: any[] = [];

    let position = getCurrentPosition(state);

    const valueType = listOptionsObj[`FilterGroup_${position.replace(/\s/g, "")}_Type`]
      ? listOptionsObj[`FilterGroup_${position.replace(/\s/g, "")}_Type`]["value"]
      : undefined;
    // const valueType = listOptionsObj[`FilterGroup_${position.replace(/\s/g, "")}_Type`]["value"];
    const valueWidth = listOptionsObj[`FilterGroup_${position.replace(/\s/g, "")}_Width`]
      ? listOptionsObj[`FilterGroup_${position.replace(/\s/g, "")}_Width`]["value"]
      : undefined;
    const valueCategoryCabinet = listOptionsObj[`CategoryCabinet`]
      ? listOptionsObj[`CategoryCabinet`]["value"]
      : undefined;

    const arrGroupsForType = getArrGroupsForType(position, valueType as PRODUCT_SECTION_GROUP_ALL_T);

    if (id.includes("Width")) {
      
      valuesArr.push({ label: "All", value: "All" });

      let uniqueWidth = new Set<string | number>();
      allProducts.forEach((product: ProductI) => {
        if (!!valueCategoryCabinet) {
          if (product["group"] === valueCategoryCabinet) {
            uniqueWidth.add(product["size"]["width"]);
          }
          return;
        }

        if (
          arrGroupsForType.length > 0 &&
          arrGroupsForType.includes(product["group"])
        )
          uniqueWidth.add(product["size"]["width"]);
      });

      valuesArr = [
        ...valuesArr,
        ...convertArrToSelectValues(Array.from(uniqueWidth)),
      ];

      return valuesArr;
    }

    if (id.includes("Type")) {
      
      valuesArr.push({ label: "All", value: "All" });

      if (valueWidth === "All" && position === PRODUCT_POSITIONS_KEYS.APPLIANCES) {
        return valuesArr = [
          ...valuesArr,
          ...convertArrToSelectValues(Object.keys(groupCabinetsOptions[position])),
        ];
      }

      if (valueWidth === "All") return valuesDefault;
      
      if (position === PRODUCT_POSITIONS_KEYS.ISLAND_CABINET) position = PRODUCT_POSITIONS_KEYS.BASE_CABINET;

      let uniqueGroup = new Set<PRODUCT_GROUP_ALL_T>();
      allProducts.forEach((product: ProductI) => {
        if (
          !!valueWidth &&
          product["size"]["width"] === valueWidth &&
          product["position"] === position
        )
          uniqueGroup.add(product["group"]);
      });

      const arrTypesFromArrGroups = getArrTypesFromArrGroups(
        position,
        Array.from(uniqueGroup)
      );

      valuesArr = [
        ...valuesArr,
        ...convertArrToSelectValues(getUniqueArr(arrTypesFromArrGroups)),
      ];

      return valuesArr;
    }

    return valuesDefault;
  };

export const getMeasurement = (state: RootState): IUnits =>
  state["paramsConfiguration"]["measurement"];

export const getProductInfo = (state: RootState) => (value: valueActiveOptionT): ProductArrT => {
  const allProducts = getAllProducts(state);
  const productsInfo = allProducts.filter((product) => {
    if (Array.isArray(value))
      return value.includes(product["id"]);
    return product["id"] === value;
  });
  return productsInfo;
}

export const getPlayerObjectOptions = (state: RootState): CabinetControlsI => {
  return state["paramsConfiguration"]["player"]["objectOptions"];
}

export const getColorValueForCabinetsPosition = (state: RootState) => {
  let colorValue = "";
  const keySectionActive = getKeySectionActive(state);
  if (keySectionActive === SECTIONS_KEYS.CABINETS) {
    const optionsActiveSection = getOptionsActiveSection(state);
    const positionCabinets = Object.values(optionsActiveSection["listOptionsObj"]).find((objParam) => {
      return objParam["optionId"] === "PositionCabinet"
    })
    if (!!positionCabinets && positionCabinets["value"] === PRODUCT_POSITIONS_KEYS.BASE_CABINET) {
      colorValue = getValueOptionColorCabinetsBase(state);
    } else {
      colorValue = getValueOptionColorCabinetsWall(state);
    }
  } else if (keySectionActive === SECTIONS_KEYS.ISLAND) {
    colorValue = getValueOptionColorCabinetsIsland(state);
  }
  return colorValue;
}

export const getImgLinkForProduct = (productId: string) => (state: RootState) => {
  const frameValue = getValueFrameOption(state);
  const priceDataTable = getPriceDataTable(state);
  let colorValue = getColorValueForCabinetsPosition(state);
  let imgLink = "";

  if (productId.includes("1016")) {
    productId = "1016"
  }

  priceDataTable.forEach((objTableRow) => {
    if (
      objTableRow["ItemNumber"] === productId &&
      objTableRow["Framed"] ===
        getFrameValueDataTableFromFrameUI(frameValue) &&
      objTableRow["Color"] ===
        getColorValueDataTableFromColorUI(colorValue)
    ) {
      imgLink = objTableRow["thumbnail"];
    }
  });
  return imgLink;
};

export const getImgLinkForCategoryGroup = (images: CategoryOptionImagesT) => (state: RootState) => {
  const frameValue = getValueFrameOption(state);
  const colorValue = getColorValueForCabinetsPosition(state);
  let imgLink = "";
  if (frameValue === FRAMED_OPTIONS_VALUES.FRAMED && colorValue === COLORS_VALUES.WHITE) {
    imgLink = images["framedWhite"]
  } else if (frameValue === FRAMED_OPTIONS_VALUES.FRAMED && colorValue === COLORS_VALUES.GREY) {
    imgLink = images["framedGrey"]
  } else if (frameValue === FRAMED_OPTIONS_VALUES.FRAMELESS && colorValue === COLORS_VALUES.WHITE) {
    imgLink = images["framelessWhite"]
  } else if (frameValue === FRAMED_OPTIONS_VALUES.FRAMELESS && colorValue === COLORS_VALUES.GREY) {
    imgLink = images["framelessGrey"]
  } else {
    imgLink = images["framedWhite"];
  }
  return imgLink;
};