import {
  IConfigurationAsset,
  ICoordinates,
  IDisplayAttributeArray,
  IDisplayAttributeAsset,
  IQuaternion,
  ISceneQuery,
  ISceneResult,
  IThreekitDisplayAttribute,
  PLUG_TYPES,
  IConfigurationArrayValue,
  IMetadata,
  IConfiguration,
  IDisplayAttributeArrayValue,
  IThreekitConfigurator,
} from "@threekit-tools/treble/dist/types";
import { Coordinates2DI } from "../../../store/slices/settingsUI/typesSettingsUISlice";
import * as THREE from "three";

export interface GetQueryThreekitI {
  from?: string;
  name?: string;
  id?: string;
  key?: string;
}

export const getObjGetQueryParamsThreekit = (
  data: GetQueryThreekitI
): GetQueryThreekitI => {
  let generatedObjQuery: GetQueryThreekitI = {};

  if (!!data["from"]) generatedObjQuery["from"] = data["from"];

  if (!!data["id"]) {
    generatedObjQuery["id"] = data["id"];
  } else if (!!data["name"]) {
    generatedObjQuery["name"] = data["name"];
  }

  return generatedObjQuery;
};

export const getSceneInstanceId = () => window.threekit.player.instanceId;
export const getSceneAssetId = () => window.threekit.player.assetId;

export const getConfigurationThreekit = (): IConfiguration => {
  return window.threekit.configurator.getConfiguration();
};

export const getAppliedConfigurationThreekit = (): any => {
  //@ts-ignore
  return window.threekit.player.player.configurator.appliedConfiguration;
};

export const getConfigurationThreekitAttribute = (attribute: string): any => {
  return getConfigurationThreekit()[attribute];
};

export const getAppliedConfigurationThreekitAttribute = (
  attribute: string
): any => {
  return getAppliedConfigurationThreekit()[attribute];
};

export const getFullConfiguration = () => {
  //@ts-ignore
  return window.threekit.player.player.configurator.getFullConfiguration();
};

export const getFullConfigurationAttribute = (attributeName: string) => {
  return getFullConfiguration()[attributeName];
};


export const getNodeFromName = (name: string): ISceneResult => {
  //@ts-ignore
  return window.threekit.player.scene.get({ name });
};

export const getNodeFromId = (id: string): ISceneResult => {
  //@ts-ignore
  return window.threekit.player.scene.get({ id });
};

export const getNodeThreekit = (data: GetQueryThreekitI): ISceneResult => {
  const objQuery = { ...getObjGetQueryParamsThreekit(data) };
  return getThreekitInfo(objQuery) as ISceneResult;
}

export const getEvalNodeFromId = (itemId: string): ISceneResult => {
  //@ts-ignore
  return window.threekit.player.scene.get({ id: itemId, evalNode: true });
};
export const getEvalNodeFromName = (name: string): ISceneResult => {
  //@ts-ignore
  return window.threekit.player.scene.get({ name, evalNode: true });
};

export const getEvalNode = (data: GetQueryThreekitI): ISceneResult => {
  const objQuery = { ...getObjGetQueryParamsThreekit(data), evalNode: true };
  return getThreekitInfo(objQuery) as ISceneResult;
}

export const getNodeIdFromName = (name: string): string => {
  return getNodeFromName(name).id;
};

export const getIdNodeThreekit = (data: GetQueryThreekitI): string => {
  return getNodeThreekit(data).id;
};

export const getNameNodeThreekit = (data: GetQueryThreekitI): string => {
  return getNodeThreekit(data)["name"];
};

export const getInstanceIdFromProxyNode = (
  itemId: string
): string | undefined => {
  const node = getNodeFromId(itemId);
  //@ts-ignore
  if (!!node["plugs"]["Proxy"] && node["plugs"]["Proxy"]?.length > 0) {
    //@ts-ignore
    const instanceId = node["plugs"]["Proxy"][0]["asset"]["assetId"];
    return instanceId;
  }
  return undefined;
};

export const getInstanceIdFromProxyEvalNode = (itemId: string): string => {
  const evalNode = getEvalNodeFromId(itemId);
  //@ts-ignore
  const instanceId = evalNode["Proxy"]["proxy"]["instanceId"];
  return instanceId;
};

export const getInstanceIdAssetFromNullModel = (data: GetQueryThreekitI): string => {
  try {
    const modelEvalNode = getEvalNode(data);

    //@ts-ignore
    if (!modelEvalNode["Null"]["link"]) {
      return window.threekit.player.instanceId;
    }

    const instanceId =
      //@ts-ignore
      modelEvalNode["Null"]["link"]["Proxy"]["proxy"]["instanceId"];

    return instanceId;
  } catch (error) {
    console.error("Виникла помилка при отриманні instanceId з Null моделі:", error);
    return "";
  }
};

export const getNullAssetIdForModel = (data: GetQueryThreekitI): string => {
  // const node = getNodeFromName(nameNullModel);
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "Null",
    //@ts-ignore
    property: "asset",
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjGetQueryParamsThreekit(data) };
  //@ts-ignore
  return getThreekitInfo(objQuery);
};

export const getInstanceIdAsset = async (
  idParrentModel: string
): Promise<string> => {
  //@ts-ignore
  const instanceId = await window.threekit.player.player.getAssetInstance({
    plug: "Null",
    property: "asset",
    id: idParrentModel,
  });
  return instanceId;
};

export const getThreekitInfo = (objQuery: ISceneQuery) => {
  //@ts-ignore
  return window.threekit.player.scene.get(objQuery);
};

export const getMeshesLayoutContainer = (
  nameLayoutContainer: string
): string[] => {
  const objQuery: ISceneQuery = {
    from: getSceneInstanceId(),
    name: nameLayoutContainer,
    //@ts-ignore
    plug: "LayoutContainer",
    //@ts-ignore
    property: "containerMeshes",
  };
  //@ts-ignore
  return getThreekitInfo(objQuery);
};

/* ===== Attributes START ===== */
export const getAttributesThreekit = () => {
  return window.threekit.configurator.getAttributes();
}
export const getDisplayAttributesThreekit = () => {
  return window.threekit.configurator.getDisplayAttributes();
};

export const getAttributeThreekit = (name: string) => {
  const allAttributes = getDisplayAttributesThreekit();
  const attribute = allAttributes.filter(
    (item: any) => item["name"] === name
  )[0];
  return attribute;
};

export const getValueAttributeThreekit = (name: string) => {
  const attribute = getAttributeThreekit(name) as IThreekitDisplayAttribute;
  return attribute["value"];
};

export const getValuesAttributeThreekit = (name: string): IDisplayAttributeArrayValue[] => {
  const attribute = getAttributeThreekit(name) as IDisplayAttributeArray;
  return attribute["values"];
};

export const getValueObjAttributeThreekit = (
  name: string,
  valueName: string
): IConfigurationAsset => {
  // є проблема з типом IThreekitDisplayAttribute тому ми використовуємо IDisplayAttributeAsset
  const attribute = getAttributeThreekit(name) as IDisplayAttributeAsset;
  // if (!attribute) return undefined;
  let valueAttribute = attribute["values"].filter(
    (item) => item["name"] === valueName
  )[0];
  // if (!valueAttribute) return undefined;
  return valueAttribute as IConfigurationAsset;
};
/* ===== Attributes END ===== */


export const getVisibleThreekit = (data: GetQueryThreekitI) => {
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "Properties",
    //@ts-ignore
    property: "visible",
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjGetQueryParamsThreekit(data) };
  return getThreekitInfo(objQuery);
}

export const getTranslationThreekit = (
  data: GetQueryThreekitI
): ICoordinates => {
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "Transform",
    //@ts-ignore
    property: "translation",
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjGetQueryParamsThreekit(data) };
  //@ts-ignore
  return getThreekitInfo(objQuery);
};

export const getRotationThreekit = (data: GetQueryThreekitI): ICoordinates => {
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "Transform",
    //@ts-ignore
    property: "rotation",
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjGetQueryParamsThreekit(data) };
  //@ts-ignore
  return getThreekitInfo(objQuery);
};

export const getScaleThreekit = (data: GetQueryThreekitI): ICoordinates => {
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "Transform",
    //@ts-ignore
    property: "scale",
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjGetQueryParamsThreekit(data) };
  //@ts-ignore
  return getThreekitInfo(objQuery);
};

export const getBoxWidthThreekit = (data: GetQueryThreekitI): number => {
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "PolyMesh",
    //@ts-ignore
    property: "width",
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjGetQueryParamsThreekit(data) };
  //@ts-ignore
  return getThreekitInfo(objQuery);
};

export const getBoxHeightThreekit = (data: GetQueryThreekitI): number => {
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "PolyMesh",
    //@ts-ignore
    property: "height",
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjGetQueryParamsThreekit(data) };
  //@ts-ignore
  return getThreekitInfo(objQuery);
};

export const getBoxDepthThreekit = (data: GetQueryThreekitI): number => {
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "PolyMesh",
    //@ts-ignore
    property: "depth",
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjGetQueryParamsThreekit(data) };
  //@ts-ignore
  return getThreekitInfo(objQuery);
};

export const getWorldTransformEvalNode = (name: string): THREE.Matrix4 => {
  const evalNode = getEvalNodeFromName(name);
  //@ts-ignore
  return evalNode.worldTransform;
};

export const getWorldTransformEvalNodeFromId = (id: string) => {
  const evalNode = getEvalNodeFromId(id);
  //@ts-ignore
  return evalNode.worldTransform;
};

export const getBoundingBoxEvalNode = (name: string): THREE.Box3 => {
  const evalNode = getEvalNodeFromName(name);
  //@ts-ignore
  return evalNode.getBoundingBox();
};

// повертає BoundingBox об'єкта до його трансформації (поворот, ререміщення)
export const getRelativeTransformBoundingBoxEvalNode = (name: string): THREE.Box3 => {
  const evalNode = getEvalNodeFromName(name);
  //@ts-ignore
  return evalNode.getBoundingBox({
    //@ts-ignore
    relativeTransform: evalNode.worldTransform,
  });
};

export type ObjAllNodesQueryT = {
  [key in string]: ISceneResult;
}
export const getAllNodeThreekit = (name: string): ObjAllNodesQueryT => {
  //@ts-ignore
  return window.threekit.player.scene.getAll({ name: `${name}*` });
};
export const getAllEvalNodeThreekit = (name: string): ObjAllNodesQueryT => {
  //@ts-ignore
  return window.threekit.player.scene.getAll({
    name: `${name}*`,
    evalNode: true,
  });
};

export const getPositionCamera = (): ICoordinates =>
  window.threekit.player.camera.getPosition();
export const getPositionQuatCamera = (): IQuaternion =>
  window.threekit.player.camera.getQuaternion();

export const getWorldPositionToScreen = (
  coordinates3D: ICoordinates
): Coordinates2DI =>
  //@ts-ignore
  window.threekit.player.worldPositionToScreen(coordinates3D);

export const getItemNodeFromNullModel = (data: GetQueryThreekitI): ISceneResult => {
  const objQuery = { ...getObjGetQueryParamsThreekit(data) };
  //@ts-ignore
  const assetIdNullForModel = getNullAssetIdForModel(objQuery);
  const itemModel = getNodeFromId(assetIdNullForModel);
  return itemModel;
};

export const getItemEvalNodeFromNullModel = (data: GetQueryThreekitI): ISceneResult => {
  const objQuery = { ...getObjGetQueryParamsThreekit(data) };
  //@ts-ignore
  const assetIdNullForModel = getNullAssetIdForModel(objQuery);
  const modelEvalNode = getEvalNode({ id: assetIdNullForModel });
  return modelEvalNode;
};


// Metadata START
export const getValuesMetadataItemFromNullModel = (data: GetQueryThreekitI) => {
  const objQuery = { ...getObjGetQueryParamsThreekit(data) };
  //@ts-ignore
  const assetIdNullForModel = getNullAssetIdForModel(objQuery);
  const itemModelEvalNode = getEvalNodeFromId(assetIdNullForModel);
  return itemModelEvalNode["configurator"]["metadata"];
};
export const getValuesMetadataFromNode = (node: ISceneResult) => {
  //@ts-ignore
  return node["metadata"];
};
export const getNodeMetadataValue = (data: GetQueryThreekitI): string => {
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "Properties",
    //@ts-ignore
    key: data?.key,
    //@ts-ignore
    property: "value",
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjGetQueryParamsThreekit(data) };
  //@ts-ignore
  return getThreekitInfo(objQuery);
}

// Metadata END


export const getConfiguratorFromNullLinkProxy = (data: GetQueryThreekitI) => {
  const objQuery = { ...getObjGetQueryParamsThreekit(data) };
  //@ts-ignore
  const assetIdNullForModel = getInstanceIdAssetFromNullModel(data);
  const modelEvalNode = getEvalNode({ id: assetIdNullForModel });
  return modelEvalNode["configurator"];
};
