// images
import HomeDepot from "./../../assets/images/Modals/Distributor/Home_Depot.png"
import Lowes from "./../../assets/images/Modals/Distributor/Lowes.png"
import Wayfair from "./../../assets/images/Modals/Distributor/Wayfair.png"

export interface RadioOptionI {
  id: number,
  value: string;
  label: string;
  img: string;
}

export const DistributorsOptions: RadioOptionI[] = [
  {
    id: Math.floor(100000 + Math.random() * 900000),
    value: "Home Depot",
    label: "Home Depot",
    img: HomeDepot,
  },
  {
    id: Math.floor(100000 + Math.random() * 900000),
    value: "Lowes",
    label: "Lowes",
    img: Lowes,
  },
  {
    id: Math.floor(100000 + Math.random() * 900000),
    value: "Wayfair",
    label: "Wayfair",
    img: Wayfair,
  },
]