// scss
import { getCollectionTitleForPDF } from "../../../../../functionsUI/summaryInfo/getCollectionTitleForPDF";
import { useAppSelector } from "../../../../../hooks/useStoreHooks";
import { getValueFrameOption } from "../../../../../store/selectors/settingsUISelectors";
import s from "./Collection.module.scss";

export const Collection = ({ ...props }) => {
  const { drawingId, projectName } = props;
  const valueFrameOption = useAppSelector(getValueFrameOption);
  const collectionTitle = getCollectionTitleForPDF(valueFrameOption);
  return (
    <div className={s.contentProducts_collection}>
      <div className={s.collectionName}>
        <div className={s.blockTitle}>Collection</div>
        <h3>{collectionTitle}</h3>
      </div>
      <div className={s.generalInfo}>
        <table>
          <tbody>
            <tr>
              <th>Date</th>
              <td>{new Date().toISOString().slice(0, 10)}</td>
            </tr>
            <tr>
              <th>Drawing #</th>
              <td>{drawingId}</td>
            </tr>
            <tr>
              <th>Project Name</th>
              <td>{projectName}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
