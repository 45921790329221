import React, { useEffect, useRef, useState } from "react";
import s from "./ProductsList.module.scss";
import Cabinet01 from "./../../../../assets/images/Cabinets/Cabinet_01.png";
import NotImage from "./../../../../assets/images/Cabinets/not_image.png";
import { DistributorLinks, DistributorLinkT } from "./../DistributorLinks/DistributorLinks";
import { useAppSelector } from "../../../../hooks/useStoreHooks";
import { getSummaryCabinetsInfo } from "../../../../store/selectors/summarySelectors";

export const ProductsList = ({ ...props }) => {
  const arrSummaryCabinetsInfo = useAppSelector(getSummaryCabinetsInfo);
  return (
    <div className={s.productsListWrap}>
      <h4 className={s.titleProductsList}>Cabinets</h4>
      <div className={s.blockTitle}>What you need</div>
      <div className={s.productsList}>
        {!!arrSummaryCabinetsInfo.length &&
          arrSummaryCabinetsInfo.map((cabinetsInfo) => {
            const distributorsLinks: DistributorLinkT[] = [
              {
                text: "Home Depot",
                link: cabinetsInfo["Home Depot Part Link"],
              },
              {
                text: "Lowes",
                link: cabinetsInfo["Lowes Part Link"],
              },
              {
                text: "Lowes Wayfair",
                link: cabinetsInfo["Wayfair Part Link"],
              },
            ];

            return (
              <div className={s.productsItem} key={`${cabinetsInfo["ItemNumber"]}${cabinetsInfo["Framed"]}${cabinetsInfo["Color"]}`}>
                <div className={s.imgWrap}>
                  <img
                    src={
                      cabinetsInfo["thumbnail"] &&
                      cabinetsInfo["thumbnail"] !== "https://thumbnail_xyz"
                        ? `${cabinetsInfo["thumbnail"]}`
                        : NotImage
                    }
                    alt={cabinetsInfo["Description"]}
                  />
                </div>
                <div className={s.textContent}>
                  <div className={s.descrWrap}>
                    <p className={s.productTitle}>
                      {cabinetsInfo["Description"]}
                    </p>
                    <div className={s.productInfoRow}>
                      <p>Model #:</p>
                      <p>{cabinetsInfo["ModelNumber"]}</p>
                    </div>
                    <div className={s.productInfoRow}>
                      <p>Color:</p>
                      <p>{cabinetsInfo["Color"]}</p>
                    </div>
                    <DistributorLinks values={distributorsLinks} />
                    {/* <p className={s.productDescr}>
                      TEST 1Drawer, 1 Door Base Cabinet, 24" x 40" x 4", LUM
                      Lighting, , Dimmable, OM Audio, Electrical Outlet, USB
                      Charging Ports, Left Hinge
                    </p> */}
                  </div>
                  <div className={s.countAndPrice}>
                    <div className={s.countWrap}>X {cabinetsInfo["count"]}</div>
                    <div className={s.priceWrap}>
                      <span>MSRP: </span>
                      {cabinetsInfo["MSRP"] !== "TBD" ? `$${Number(cabinetsInfo["MSRP"]) * cabinetsInfo["count"]}` : "TBD"}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        {!!!arrSummaryCabinetsInfo.length && (
          <p>Cabinets are missing. Start building the kitchen.</p>
        )}

      </div>
    </div>
  );
};
