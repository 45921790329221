import React from 'react'
import { Switch } from "antd";
import styled from "styled-components";

//@ts-ignore
import s from './SwitchStandardBase.module.scss'
 
type SwitchStandardT = {
    onChange: any
    checked?: boolean
}

let StyledSelect = styled(Switch)` 
    &.ant-switch{
        background: #BFBFBF !important;
    } 
    &.ant-switch-checked{
        background: #1F2944 !important;
    }
    &.ant-switch .ant-switch-inner {
        padding-inline-start: 0;
        padding-inline-end: 0;
    }
    &.ant-switch:focus {
        box-shadow: none !important;
    } 
    &.ant-switch-checked:focus{
        box-shadow: none !important;
    }
     &.ant-switch .ant-click-animating-node{
        box-shadow: none !important;
        -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1)  !important;
    }
    @keyframes waveEffect{
        100% {box-shadow: 0 0 0 6px #425d76; !important; }
    }
`;

export const SwitchStandardBase = ({ ...props }: SwitchStandardT) => { 
    const { onChange, checked } = props; 
    return (
        <StyledSelect
            checked={checked}
            onChange={(value: any) => onChange(value)}
        />
    )
}

