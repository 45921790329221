import { isOptionIdLowerMolding, isOptionIdUpperMolding, isOptionIdUpperMoldingSize } from "../../../functionsUI/cabinets/moulding";
import { getValueOptionLowerMolding, getValueOptionUpperMolding, getValueOptionUpperMoldingSize } from "../../../store/selectors/settingsUISelectors";
import { UpdateSettingsT } from "../../../store/slices/settingsUI/typesSettingsUISlice";
import store from "../../../store/store";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../utils/constants/attributesThreekit";
import { ATTRIBUTE_NAMES } from "../../../utils/constants/attributesThreekitRoomBuilder";
import { ModelCabinetWallT } from "../../../utils/constants/nodesNamesThreekit";
import { getKeys } from "../../../utils/other/getObjKeysFromType";
import { setNestedConfigurationFromNullName } from "../../../utils/threekit/general/nestedConfigurator";
import { getMouldingBottomConfigurationAllCabinets } from "../cabinetsWall/getMouldingBottomConfiguration";
import { getMouldingTopConfigurationAllCabinets, ObjTopMouldingConfigurationT } from "../cabinetsWall/getMouldingTopConfiguration";
import { getConfiguratorModelFromNullName } from "./decorativePanel";

export const enum ATTRIBUTES_MOULDING {
  MOULDING_TOP = "Moulding Upper",
  MOULDING_TOP_STYLE = "Moulding Upper Location",
  MOULDING_BOTTOM = "Moulding Lower",
  MOULDING_BOTTOM_STYLE = "Moulding Lower Location",
  MOULDING_CONNECTION_LEFT = "Moulding connection left",
  MOULDING_CONNECTION_RIGHT = "Moulding connection right",
  MOULDING_CONNECTION_TO_PANTRY = "Moulding connection to pantry",
}

export type MouldingTopValuesT = "no" | "3in" | "6in";
export type MouldingTopStyleValuesT = "Left" | "Right" | "Center" | "Alone";
export type MouldingBottomValuesT = "no" | "yes";
export type MouldingBottomStyleValuesT = "Left" | "Right" | "Center" | "Alone";
export type MouldingConnectionLeftValuesT = "no" | "yes";
export type MouldingConnectionRightValuesT = "no" | "yes";
export type MouldingConnectionToPantryValuesT = "no" | "yes";
export type MouldingConfigurationT = {
  [ATTRIBUTES_MOULDING.MOULDING_TOP]?: MouldingTopValuesT,
  [ATTRIBUTES_MOULDING.MOULDING_TOP_STYLE]?: MouldingTopStyleValuesT,
  [ATTRIBUTES_MOULDING.MOULDING_BOTTOM]?: MouldingBottomValuesT,
  [ATTRIBUTES_MOULDING.MOULDING_BOTTOM_STYLE]?: MouldingBottomStyleValuesT,
  [ATTRIBUTES_MOULDING.MOULDING_CONNECTION_LEFT]?: MouldingConnectionLeftValuesT,
  [ATTRIBUTES_MOULDING.MOULDING_CONNECTION_RIGHT]?: MouldingConnectionRightValuesT,
  [ATTRIBUTES_MOULDING.MOULDING_CONNECTION_TO_PANTRY]?: MouldingConnectionToPantryValuesT,
}

// const mergeObjMouldingConfigurations = (
//   obj1: ObjTopMouldingConfigurationT,
//   obj2: ObjTopMouldingConfigurationT
// ): ObjTopMouldingConfigurationT => {
//   const mergedObj: ObjTopMouldingConfigurationT = {} as ObjTopMouldingConfigurationT;
//   for (const key of Object.keys(obj1) as ModelCabinetWallT[]) {
//     mergedObj[key] = { ...obj1[key], ...obj2[key] };
//   }
//   return mergedObj;
// }

const mergeObjMouldingConfigurations = (
  obj1: ObjTopMouldingConfigurationT | null | undefined,
  obj2: ObjTopMouldingConfigurationT | null | undefined
): ObjTopMouldingConfigurationT => {
  const result: ObjTopMouldingConfigurationT = {} as ObjTopMouldingConfigurationT;
  
  // Об'єднуємо ключі з обох об'єктів
  const keys = [
    ...Object.keys(obj1 || {}),
    ...Object.keys(obj2 || {})
  ] as ModelCabinetWallT[];
  
  // Для кожного ключа додаємо MouldingConfiguration з двох об'єктів
  for (const key of keys) {
    result[key] = {
      ...(obj1?.[key] || {}),
      ...(obj2?.[key] || {})
    };
  }
  
  return result;
}

export const getMouldingConfiguration = (param?: UpdateSettingsT): ObjTopMouldingConfigurationT => {

  const state = store.getState();

  const isParamUpperMolding = !!param && isOptionIdUpperMolding(param["optionId"]);
  const isParamLowerMolding = !!param && isOptionIdLowerMolding(param["optionId"]);
  const isParamUpperMoldingSize = !!param && isOptionIdUpperMoldingSize(param["optionId"]);

  // отримуємо дані зі стейту по вехніх молдингах
  // Чи ввімкнений верхній молдинг
  let isUpperMoldingStore = getValueOptionUpperMolding(state) as boolean;
  const isUpperMolding = isParamUpperMolding ? param["value"] as boolean : isUpperMoldingStore;

  // отримуємо дані зі стейту по нижньому молдингу
  // Вімкнений чи вимкнений нижній молдинг
  let isLowerMoldingStore = getValueOptionLowerMolding(state) as boolean;
  const isLowerMolding = isParamLowerMolding ? param["value"] as boolean : isLowerMoldingStore;

  if ((!isUpperMolding && !isParamUpperMolding) && (!isLowerMolding && !isParamLowerMolding)) return {};

  // перевіряємо чи ввімкнений верхній молдинг в UI
  let mouldingTopConfiguration: MouldingConfigurationT = {}
  if (isUpperMolding || isParamUpperMolding) {
    // Отримуємо по данних зі стейту тип верхнього молдингу
    let sizeUpperMoldingStore = getValueOptionUpperMoldingSize(state) as MouldingTopValuesT;
    const sizeUpperMolding = isParamUpperMoldingSize ? param["value"] as MouldingTopValuesT : sizeUpperMoldingStore;

    // отримуємо налаштування конфігурації верхніх молдингів для всіх настінних шкафів
    mouldingTopConfiguration = getMouldingTopConfigurationAllCabinets(isUpperMolding, sizeUpperMolding);
  }

  // перевіряємо чи ввімкнений нижній молдинг в UI
  let mouldingBottomConfiguration: MouldingConfigurationT = {}
  if (isLowerMolding || isParamLowerMolding) {
    // отримуємо налаштування конфігурації нижніх молдингів для всіх настінних шкафів
    mouldingBottomConfiguration = getMouldingBottomConfigurationAllCabinets(isLowerMolding);
  }

  // об'єднуємо конфігурації для верхніх та нижніх молдингів
  const fullMouldingConfiguration = mergeObjMouldingConfigurations(
    mouldingTopConfiguration,
    mouldingBottomConfiguration
  );

  return fullMouldingConfiguration;
  
}

export const updateMoulding = async (param?: UpdateSettingsT) => {
  const fullMouldingConfiguration = getMouldingConfiguration(param);
  const arrNullNamesCabinetsWall = getKeys(fullMouldingConfiguration);
  arrNullNamesCabinetsWall.forEach(async (nullNameCabinetWall) => {
    const configuration = fullMouldingConfiguration[nullNameCabinetWall];
    // const configuratorModel = getConfiguratorModelFromNullName(nullNameCabinetWall);
    // await configuratorModel.setConfiguration(configuration);
    await setNestedConfigurationFromNullName({
      nullName: nullNameCabinetWall,
      attributeName: ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL,
      configuration: configuration
    })
  })
}