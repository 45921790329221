// export const CABINETS_WALL_BOTTOM_POSITION = 1.3716;
export const CABINETS_WALL_BOTTOM_POSITION = 1.5104;
export const CABINETS_WALL_STANDART_HEIGHT = 0.762;
export const CABINETS_WALL_DEPTH_12 = 0.3119978;
export const CABINETS_WALL_DEPTH_24 = 0.5918138;
export const CABINETS_BASE_HEIGHT_FROM_COUTERTOP = 0.8917707;
export const WALL_PLANE_BOTTOM_POSITION = 0.8627;

export const CORNER_EMPTY_BASE_SIZE_X = 0.6497880;
export const CORNER_EMPTY_BASE_SIZE_Z = 0.6480504;

export const CORNER_EMPTY_WALL_SIZE_X = 0.3984838;
export const CORNER_EMPTY_WALL_SIZE_Z = 0.3975967;

export const HELP_DISTANCE_CORNER_EMPTY_CORRECTED_OUTSIDE = 0.2;
export const HELP_DISTANCE_CORNER_EMPTY_CORRECTED_INSIDE = 0.005;