import { ICoordinates, ISceneResult } from "@threekit-tools/treble/dist/types";
import { CabinetsAndFeatures_NodesT, ModelCabinetWallT, ModelsName_NodesT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getTHREE } from "../../../utils/three/general/getFunctionsTHREE";
import { getEvalNode, getEvalNodeFromName, getInstanceIdAssetFromNullModel, getRelativeTransformBoundingBoxEvalNode } from "../../../utils/threekit/general/getFunctions";
import { getSizeModelRelativeTransform } from "../../intervals/getIntervalsInfoOnWall";
import { getСompletedModelsNullNames } from "./../getNodesCabinets";

/**
 * Функція шукає та повертає EvalNode коробки для настінних шкафів.
 * Цей EvalNode показує реальні розміри каркасу шкафа без дверок, молдінгів, декоративних панелей і т.д.
 *
 * @param {ModelsName_NodesT} modelName Null name моделі, для якої шукаємо розміри.
 * @returns {ISceneResult} EvalNode.
 */
const getNodeForCabinetsWallFrame = (modelName: CabinetsAndFeatures_NodesT): ISceneResult => {

  const instanceId = getInstanceIdAssetFromNullModel({
    name: modelName,
  });

  const modelBoxEvalNode = getEvalNode({
    from: instanceId,
    name: "cabinet_**_Wall_Cabinet_*",
  });

  const modelBoxEvalNodeUpperPantry = getEvalNode({
    from: instanceId,
    name: "cabinet_**_Upper_Pantry_*",
  });

  const modelBoxEvalNodeCornerEmpty = getEvalNode({
    from: instanceId,
    name: "corner_**_empty",
  });

  if (modelBoxEvalNodeUpperPantry !== undefined) 
    return modelBoxEvalNodeUpperPantry;

  if (modelBoxEvalNodeCornerEmpty !== undefined)
    return modelBoxEvalNodeCornerEmpty;

  return modelBoxEvalNode;
}

/**
 * Функція визначає розміри моделі Threekit з урахуванням Scale моделі.
 *
 * @param {ModelCabinetWallT} sizeModelBoxFromScale Розміри моделі без урахування Scale.
 * @param {ISceneResult} evalNode EvalNode для об'єкту Threekit, для якого шукаємо розміри.
 * @returns {ICoordinates} Розміри моделі Threekit з урахуванням Scale моделі.
 */
export const checkModelBoxScale = (sizeModelBoxFromScale: ICoordinates, evalNode: ISceneResult): ICoordinates => {
  //@ts-ignore
  const objModelBoxTransform = evalNode["node"]["plugs"]["Transform"];
  if (!!!objModelBoxTransform) return sizeModelBoxFromScale;
  //@ts-ignore
  const modelBoxScale = objModelBoxTransform[0]["scale"];
  return {
    x: sizeModelBoxFromScale["x"] * modelBoxScale["x"],
    y: sizeModelBoxFromScale["y"] * modelBoxScale["y"],
    z: sizeModelBoxFromScale["z"] * modelBoxScale["z"],
  }
}

/**
 * Функція для визначення розмірів моделі по її каркасу.
 * без урахування молдингів та інших навісних елементів тумби.
 *
 * @param {ModelCabinetWallT} modelName Null name моделі, для якої шукаємо розміри.
 * @returns {ICoordinates} Pозмір об'єкта.
 */
export const getSizeModelBoxFromAssetCabinetWall = (modelName: CabinetsAndFeatures_NodesT): ICoordinates => {
  const THREE = getTHREE();
  const modelBoxEvalNode = getNodeForCabinetsWallFrame(modelName);
  try {
    //@ts-ignore
    const boundingBox = modelBoxEvalNode.getBoundingBox({
      //@ts-ignore
      relativeTransform: modelBoxEvalNode.worldTransform,
    });
    const sizeModelBoxFromScale = boundingBox.getSize(new THREE.Vector3());
    const sizeModelBoxOriginal = checkModelBoxScale(sizeModelBoxFromScale, modelBoxEvalNode);
    return sizeModelBoxOriginal;
  } catch (error) {
    console.error(error);
    return getSizeModelRelativeTransform(modelName);
  }
}

export type ObjSizeCabinetsWallT = {
  [key in ModelCabinetWallT]: ICoordinates;
};
/**
 * Функція для визначення повних розмірів каркасу шкафа без дверок, молдінгів, декоративних панелей і т.д.
 * Для всіх встановлених настінних моделей
 *
 * @returns {ObjSizeCabinetsWallT} Об'ект з інформацією про розміри всіх встановлених на сцені настінних моделей.
 */
export const getSizeFullForAllCabinetsWall = (): ObjSizeCabinetsWallT => {
  const allNullForСabinetsWall =
    getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_WALL);
  return allNullForСabinetsWall.reduce(
    (objExtremePointsAcc: ObjSizeCabinetsWallT, modelName) => {
      const modelSize = getSizeModelRelativeTransform(modelName);
      return {
        ...objExtremePointsAcc,
        [modelName]: modelSize
      };
    },
    {}
  );
}

/**
 * Функція для визначення розмірів всіх встановлених настінних моделей.
 *
 * @returns {ObjSizeCabinetsWallT} Об'ект з інформацією про розміри всіх встановлених на сцені настінних моделей.
 */
export const getSizeBoxForAllCabinetsWall = (): ObjSizeCabinetsWallT => {
  const allNullForСabinetsWall =
    getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_WALL);
  return allNullForСabinetsWall.reduce(
    (objExtremePointsAcc: ObjSizeCabinetsWallT, modelName) => {
      const sizeModelBox = getSizeModelBoxFromAssetCabinetWall(modelName)
      return {
        ...objExtremePointsAcc,
        [modelName]: sizeModelBox
      };
    },
    {}
  );
}