export const THREEKIT_ENV: "preview" | "admin-fts" = "preview";
export const THREEKIT_ASSET_ID = '5430b04a-c04b-48b2-999d-f88cc0ad3b37';
export const THREEKIT_ID_DATA_TABLE_PRICE="839f647d-27af-421c-acf1-f43ea904151a";
export const API_URL="/api";

export const THREEKIT_PREVIEW_ORG_ID="803725a2-a289-48c5-9858-e9044af7233a";
export const THREEKIT_PREVIEW_PUBLIC_TOKEN="ba16cef2-2c7d-40a1-8f63-4a1014b58c11";

export const THREEKIT_ADMIN_FTS_ORG_ID="a41e78dd-a855-439b-8784-e5b75e7bf9af";
export const THREEKIT_ADMIN_FTS_PUBLIC_TOKEN="29c18232-9faa-4de2-a05f-a5b4da724c72";

const REACT_APP_THREEKIT_PREVIEW_URL="https://preview.threekit.com";
const REACT_APP_THREEKIT_PREVIEW_API="https://preview.threekit.com/api";

const REACT_APP_THREEKIT_ADMIN_FTS_URL="https://admin-fts.threekit.com";
const REACT_APP_THREEKIT_ADMIN_FTS_API="https://admin-fts.threekit.com/api";

const REACT_APP_THREEKIT_PREVIEW_IMAGES_URL="https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com";
const REACT_APP_THREEKIT_ADMIN_FTS_IMAGES_URL="https://admin-fts.threekit.com/api/images/webp/power2-4096/preview.threekit.com";

const IS_ADMIN_FTS = (THREEKIT_ENV as "admin-fts") === "admin-fts";

export const THREEKIT_ORG_ID = IS_ADMIN_FTS ? THREEKIT_ADMIN_FTS_ORG_ID : THREEKIT_PREVIEW_ORG_ID;
export const THREEKIT_PUBLIC_TOKEN = IS_ADMIN_FTS ? THREEKIT_ADMIN_FTS_PUBLIC_TOKEN : THREEKIT_PREVIEW_PUBLIC_TOKEN;

export const THREEKIT_URL = `https://${THREEKIT_ENV}.threekit.com`;
export const THREEKIT_URL_API = `${THREEKIT_URL}${API_URL}`;
export const THREEKIT_IMAGES_URL=`https://${THREEKIT_ENV}.threekit.com/api/images/webp/power2-4096/preview.threekit.com`;