export enum NODES_THREEKIT {
  WALL_ITEM = "wall_item_",

  PLANE_CABINETS_WALL = "plane_cabinets_wall_",
  PLANE_BOUNDING_WALL = "plane_bounding_",
  PLANE_BOUNDING_CEILING = "plane_bounding_ceiling_",

  LAYOUT_CONTAINER_WALLS_WRAP = "Layout_Container_Walls_Wrap",
  LAYOUT_CONTAINER_CABINET_BASE = "Layout_Container_Cabinet_Base",
  LAYOUT_CONTAINER_CABINET_WALL = "Layout_Container_Cabinet_Wall",

  FLOOR_MODEL_IN_SCENE = "Floor Model",
  FLOOR_PLANE = "Floor Plane",

  MODEL_CABINET_WALL = "Model_Cabinets_Wall_",
  MODEL_CABINET_BASE = "Model_Cabinets_Base_",
  MODEL_CABINET_ISLAND = "Model_Cabinets_Island_",
  MODEL_APPLIANCES = "Model_Appliances_",

  MODEL_WINDOW = "Windows_",
  MODEL_DOOR = "Doors_",
  MODEL_OPENING = "Openings_"
}

export enum FEATURES_INNER_NODES_THREEKIT {
  CONTAINER_POS_NULL = "Positioning Null",
}

export const NAME_BASE_CABINET_CORNER_STUB = "Corner empty Cabinets Base";
export const NAME_WALL_CABINET_CORNER_STUB = "Corner empty Wall";
export const NAME_WALL_CABINET_CORNER_STUB_36 = "Corner_36inH_wall_empty";

export type WallItemT = `${NODES_THREEKIT.WALL_ITEM}${number}`;
export type PlaneCabinetsWallT = `${NODES_THREEKIT.PLANE_CABINETS_WALL}${number}`;
export type ModelCabinetWallT = `${NODES_THREEKIT.MODEL_CABINET_WALL}${number}`;
export type ModelCabinetBaseT = `${NODES_THREEKIT.MODEL_CABINET_BASE}${number}`;
export type ModelAppliancesT = `${NODES_THREEKIT.MODEL_APPLIANCES}${number}`;
export type ModelCabinetIslandT = `${NODES_THREEKIT.MODEL_CABINET_ISLAND}${number}`;

export type ModelWindowT = `${NODES_THREEKIT.MODEL_WINDOW}${number}`;
export type ModelDoorT = `${NODES_THREEKIT.MODEL_DOOR}${number}`;
export type ModelOpeningT = `${NODES_THREEKIT.MODEL_OPENING}${number}`;

export type ModelsName_NodesT =
  | ModelCabinetWallT
  | ModelCabinetBaseT
  | ModelAppliancesT
  | ModelCabinetIslandT;
export type FeaturesName_NodesT = 
  | ModelWindowT
  | ModelDoorT
  | ModelOpeningT;
export type CabinetsAndFeatures_NodesT = ModelsName_NodesT | FeaturesName_NodesT;
export type AllName_NodesT =
  | WallItemT
  | PlaneCabinetsWallT
  | ModelCabinetWallT
  | ModelCabinetBaseT
  | ModelAppliancesT
  | ModelCabinetIslandT
  | ModelWindowT
  | ModelDoorT
  | ModelOpeningT;
