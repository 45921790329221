import { CONNECTORS_CABINET_WALL } from "../../../utils/constants/connectors";
import { ModelCabinetWallT, ModelsName_NodesT } from "../../../utils/constants/nodesNamesThreekit";
import { getKeys } from "../../../utils/other/getObjKeysFromType";
import { getInstanceIdAssetFromNullModel, getNameNodeThreekit, getTranslationThreekit } from "../../../utils/threekit/general/getFunctions";
import { setActiveTagsThreekit, setVisible } from "../../../utils/threekit/general/setFunctions";
import { isFeaturesModelNullName } from "../../features/general";
import { checkIntersectIntervalFromIntervals } from "../../intervals/intersectIntervals";
import { ArrWallRangesT, RangeT, WallRangeT, getIntervalsWallCabinetsForAllWalls } from "../../intervals/getIntervalsInfoOnWall";
import { getIntervalsBaseCabinetsForAllWalls } from "../../intervals/getIntervalsInfoOnWallBase";
import { getWallNameFromPlaneName } from "../../wallsAndFloor/getGeneralWallInfo";
import { isConnectorsSideTrue } from "../cabinetsBase/checkCabinetsBaseConnectors";
import { isUpperPantryCabinetWall } from "../checkModels";
import { CABINETS_WALL_BOTTOM_POSITION, CABINETS_WALL_STANDART_HEIGHT } from "../constatns";
import { getSizeModelBoxFromAssetCabinetWall } from "./size";
import { checkIfCornerCabinetFromNullName } from "../cabinetsBase/checkCornersCabinetsBase";
import { checkDefaultPositionCabinetsWall } from "./position";
import { getConfiguratorModelFromIdModel } from "../configuration/decorativePanel";
import { checkLockTranslationY } from "./checkFreeMovementCabinetsWall";

type SideConnectorsT = {
  leftConnectors?: boolean;
  rightConnectors?: boolean;
  leftInnerConnectors?: boolean;
  rightInnerConnectors?: boolean;
}

const updateConnectorsTagsCabinetsWall = (objSideConnectors: SideConnectorsT, instanceIdParentAsset: string, isLockTranslationY?: boolean) => {
  if (objSideConnectors["leftConnectors"] !== undefined) {
    setActiveTagsThreekit({
      from: instanceIdParentAsset,
      name: CONNECTORS_CABINET_WALL.TOP_LEFT,
      value: objSideConnectors["leftConnectors"],
    })

    if (isLockTranslationY !== undefined) {
      setActiveTagsThreekit({
        from: instanceIdParentAsset,
        name: CONNECTORS_CABINET_WALL.BOTTOM_LEFT,
        value: !isLockTranslationY,
      })
      setVisible({
        from: instanceIdParentAsset,
        name: CONNECTORS_CABINET_WALL.BOTTOM_LEFT,
        value: !isLockTranslationY,
      })
    } else {
      setActiveTagsThreekit({
        from: instanceIdParentAsset,
        name: CONNECTORS_CABINET_WALL.BOTTOM_LEFT,
        value: objSideConnectors["leftConnectors"],
      })
    }
    
  }
  if (objSideConnectors["rightConnectors"] !== undefined) {
    setActiveTagsThreekit({
      from: instanceIdParentAsset,
      name: CONNECTORS_CABINET_WALL.TOP_RIGHT,
      value: objSideConnectors["rightConnectors"],
    })
    if (isLockTranslationY !== undefined) {
      setActiveTagsThreekit({
        from: instanceIdParentAsset,
        name: CONNECTORS_CABINET_WALL.BOTTOM_RIGHT,
        value: !isLockTranslationY,
      })
      setVisible({
        from: instanceIdParentAsset,
        name: CONNECTORS_CABINET_WALL.BOTTOM_RIGHT,
        value: !isLockTranslationY,
      })
    } else {
      setActiveTagsThreekit({
        from: instanceIdParentAsset,
        name: CONNECTORS_CABINET_WALL.BOTTOM_RIGHT,
        value: objSideConnectors["rightConnectors"],
      })
    }
  }
  // if (objSideConnectors["rightInnerConnectors"] !== undefined) {
  //   setActiveTagsThreekit({
  //     from: instanceIdParentAsset,
  //     name: CONNECTORS_CABINET_WALL.TOP_RIGHT_INNER,
  //     value: objSideConnectors["rightInnerConnectors"],
  //   })
  // }
  // if (objSideConnectors["leftInnerConnectors"] !== undefined) {
  //   setActiveTagsThreekit({
  //     from: instanceIdParentAsset,
  //     name: CONNECTORS_CABINET_WALL.TOP_LEFT_INNER,
  //     value: objSideConnectors["leftInnerConnectors"],
  //   })
  // }
}

const getFakeRightInterval = (
  range: RangeT,
  widthModel: number,
): RangeT => [ range[1] + 0.05, range[1] + widthModel + 0.05 ];

const getFakeLeftInterval = (
  range: RangeT,
  widthModel: number,
): RangeT => [ range[0] - widthModel - 0.05, range[0] - 0.05 ];

// (Upper Pantry)
// перевіряємо моделі на активність конекторів для виділеної моделі Upper Pantry
const checkConnectorsForUpperPantry = (
  isUpperPantrySelectedModel: boolean,
  rangeIterModel: RangeT,
  widthSelectedModel: number,
  arrBaseIntervals: ArrWallRangesT,
  side: "left" | "right"
): boolean => {
  if (!isUpperPantrySelectedModel) return true;

  const fakeInterval =
    side === "left"
      ? getFakeLeftInterval(rangeIterModel, widthSelectedModel)
      : getFakeRightInterval(rangeIterModel, widthSelectedModel);

  const isIntersectsIntervals = checkIntersectIntervalFromIntervals(
    fakeInterval,
    arrBaseIntervals
  );

  if (isIntersectsIntervals) return false;

  return true;
};

const checkDefaultHeightForModelInterval = (
  objInterval: WallRangeT | null | undefined,
) => {

  if (objInterval === undefined || objInterval === null) return true;

  const { empty, range, name } = objInterval;

  if (empty || name === undefined) return true;

  return checkDefaultPositionCabinetsWall(name);

}

export const checkCabinetsWallConnectors = (idSelectedModel: string) => {

  const nullNameSelectedModel = getNameNodeThreekit({id: idSelectedModel}) as ModelsName_NodesT;
  const sizeSelectedModel = getSizeModelBoxFromAssetCabinetWall(nullNameSelectedModel);
  const isUpperPantrySelectedModel = isUpperPantryCabinetWall(nullNameSelectedModel);

  const intervalsCabinetsWallForAllWalls = getIntervalsWallCabinetsForAllWalls();
  const intervalsCabinetsBaseForAllWalls = getIntervalsBaseCabinetsForAllWalls();

  const keysIntervalsCabinetsWallForAllWalls = getKeys(
    intervalsCabinetsWallForAllWalls
  );

  keysIntervalsCabinetsWallForAllWalls.forEach((planeName) => {

    const arrWallIntervals =
      intervalsCabinetsWallForAllWalls[planeName];
    const arrBaseIntervals =
      intervalsCabinetsBaseForAllWalls[getWallNameFromPlaneName(planeName)];

    let prevInterval: WallRangeT | null = null;
    let prevFilledInterval: WallRangeT | null = null;

    arrWallIntervals.forEach((interval, indx) => {

      let { empty, range, name } = interval;
      const nextInterval = arrWallIntervals[indx + 1];
      const nextFilledInterval = nextInterval !== undefined && nextInterval["empty"] ? arrWallIntervals[indx + 2] : arrWallIntervals[indx + 1];
      let objSideConnectors: SideConnectorsT = {}

      if (!empty && !!name && !isFeaturesModelNullName(name)) {

        const instanceIdAssetModel = getInstanceIdAssetFromNullModel({name: name});

        // якщо настінна модель знаходить виже або нижче дефолтної позиції,
        // то вмикаємо всі конектори
        const positionModel = getTranslationThreekit({name});
        const sizeModel = getSizeModelBoxFromAssetCabinetWall(name);
        if (Math.abs((positionModel["y"] + sizeModel["y"]) - (CABINETS_WALL_BOTTOM_POSITION + CABINETS_WALL_STANDART_HEIGHT)) > 0.005) {
          objSideConnectors["leftConnectors"] = true;
          objSideConnectors["rightConnectors"] = true;
          prevInterval = interval;
          prevFilledInterval = interval;
          return updateConnectorsTagsCabinetsWall(objSideConnectors, instanceIdAssetModel)
        }

        // якщо це перша модель на стіні, і вона є кутовою моделлю
        // то перевіряємо тільки праву сторону
        if (indx === 0 && checkIfCornerCabinetFromNullName(name)) {

          if (
            isConnectorsSideTrue(
              nextInterval,
              nextFilledInterval,
              nullNameSelectedModel,
              sizeSelectedModel["x"]
            )
          ) {
            objSideConnectors["rightConnectors"] = true;
          } else {
            objSideConnectors["rightConnectors"] = false;
          }

          prevInterval = interval;
          prevFilledInterval = interval;
          return updateConnectorsTagsCabinetsWall(objSideConnectors, instanceIdAssetModel)

        }

        // якщо це остання модель на стіні, і вона є кутовою моделлю
        // то перевіряємо тільки ліву сторону
        if (indx === arrWallIntervals.length - 1 && checkIfCornerCabinetFromNullName(name)) {

          if (
            isConnectorsSideTrue(
              prevInterval,
              prevFilledInterval,
              nullNameSelectedModel,
              sizeSelectedModel["x"]
            )
          ) {
            objSideConnectors["leftConnectors"] = true
          } else {
            objSideConnectors["leftConnectors"] = false;
          }

          prevInterval = interval;
          prevFilledInterval = interval;
          return updateConnectorsTagsCabinetsWall(objSideConnectors, instanceIdAssetModel)

        }

        // якщо це є виділеною моделлю, то вмикаємо для неї всі конектори
        if (name === nullNameSelectedModel) {

          objSideConnectors["leftConnectors"] = true;
          objSideConnectors["rightConnectors"] = true;
          prevInterval = interval;
          prevFilledInterval = interval;
          const isLockTranslationY = checkLockTranslationY(nullNameSelectedModel);
          return updateConnectorsTagsCabinetsWall(objSideConnectors, instanceIdAssetModel, isLockTranslationY)

        }

        // перевіряємо ліву сторону
        const isConnectorsLeft = isConnectorsSideTrue(
          prevInterval,
          prevFilledInterval,
          nullNameSelectedModel,
          sizeSelectedModel["x"]
        )
        const isConnectorsForUpperPantryLeft = checkConnectorsForUpperPantry(
          isUpperPantrySelectedModel,
          range,
          sizeSelectedModel["x"],
          arrBaseIntervals,
          "left",
        );
        const isPrevFilledIntervalOnDefaultHeight = checkDefaultHeightForModelInterval(prevFilledInterval);
        if (
          isConnectorsLeft && isConnectorsForUpperPantryLeft || !isPrevFilledIntervalOnDefaultHeight
        ) {
          objSideConnectors["leftConnectors"] = true
        } else {
          objSideConnectors["leftConnectors"] = false;

          // Check inner connector Right
          if (sizeSelectedModel["x"] > sizeModel["x"]) {
            objSideConnectors["rightInnerConnectors"] = false;
          } else {
            objSideConnectors["rightInnerConnectors"] = true;
          }

        }
        
        // перевіряємо праву сторону
        const isConnectorsRight = isConnectorsSideTrue(
          nextInterval,
          nextFilledInterval,
          nullNameSelectedModel,
          sizeSelectedModel["x"]
        )
        const isConnectorsForUpperPantryRight = checkConnectorsForUpperPantry(
          isUpperPantrySelectedModel,
          range,
          sizeSelectedModel["x"],
          arrBaseIntervals,
          "right",
        );
        const isNextFilledIntervalOnDefaultHeight = checkDefaultHeightForModelInterval(nextFilledInterval);
        if (
          isConnectorsRight && isConnectorsForUpperPantryRight || !isNextFilledIntervalOnDefaultHeight
        ) {
          objSideConnectors["rightConnectors"] = true;
        } else {
          objSideConnectors["rightConnectors"] = false;

          // Check inner connector Right
          if (sizeSelectedModel["x"] > sizeModel["x"]) {
            objSideConnectors["leftInnerConnectors"] = false;
          } else {
            objSideConnectors["leftInnerConnectors"] = true;
          }

        }

        updateConnectorsTagsCabinetsWall(objSideConnectors, instanceIdAssetModel);

        prevFilledInterval = interval;

      }

      if (!empty && !!name && isFeaturesModelNullName(name)) {
        prevFilledInterval = interval;
      }

      prevInterval = interval;

    })
  })

}