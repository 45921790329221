import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { WallItemT } from "../../utils/constants/nodesNamesThreekit";
import { getTHREE } from "../../utils/three/general/getFunctionsTHREE";
import { getWorldTransformEvalNode } from "../../utils/threekit/general/getFunctions";
import { getSizeModelRelativeTransform } from "../intervals/getIntervalsInfoOnWall";
import { moveCoordsByVector } from "./buildWallFromData";
import { getStartEndCoordsPlane } from "./getWallPlanesInfo";

/**
 * Повертає координати початку і кінця для стіни.
 * Координати розташовані всередині стіни
 *
 * @param {WallItemT} wallName Name стіни.
 * @return {[ICoordinates, ICoordinates]} Координати початку і кінця для стіни.
 */
export const getStartEndPointWall = (
  wallName: WallItemT
): [ICoordinates, ICoordinates] => {
  const THREE = getTHREE();

  const wallSize = getSizeModelRelativeTransform(wallName);
  const worldTransformWall = getWorldTransformEvalNode(wallName);
  const wallPos = new THREE.Vector3().setFromMatrixPosition(worldTransformWall);
  const wallDirPositiveX = new THREE.Vector3(1, 0, 0).transformDirection(
    worldTransformWall
  );
  const wallDirNegativeX = new THREE.Vector3(-1, 0, 0).transformDirection(
    worldTransformWall
  );
  const wallDir = new THREE.Vector3(0, 0, 1).transformDirection(worldTransformWall);

  // отримуємо координати зліва і справа для стіни, з урахуванням того,
  // що точки мають бути з внутрішньої сторони кімнати
  let wallCoordsLeft = moveCoordsByVector(
    wallPos,
    wallDirNegativeX,
    Number((wallSize["x"] / 2 - wallSize["z"]).toFixed(1))
  );
  let wallCoordsRight = moveCoordsByVector(
    wallPos,
    wallDirPositiveX,
    Number((wallSize["x"] / 2 - wallSize["z"]).toFixed(1))
  );

  // зміщуємо координати всередину кімнати на половину стіни
  wallCoordsLeft = moveCoordsByVector(
    wallCoordsLeft,
    wallDir,
    Number((wallSize["z"]/2).toFixed(1))
  );
  wallCoordsRight = moveCoordsByVector(
    wallCoordsRight,
    wallDir,
    Number((wallSize["z"]/2).toFixed(1))
  );

  // const [currentPlaneCoordsLeft, currentPlaneCoordsRight] =
  //   getStartEndCoordsPlane("plane_cabinets_wall_0");

  // console.log('START END --- ==== ',[wallCoordsLeft, wallCoordsRight]);
  // console.log('START END PLANE --- ==== ',[currentPlaneCoordsLeft, currentPlaneCoordsRight]);

  return [wallCoordsLeft, wallCoordsRight];
};
