import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";
import { WallRangeT } from "../intervals/getIntervalsInfoOnWall";
import { getIntervalsBaseCabinetsForAllWalls } from "../intervals/getIntervalsInfoOnWallBase";
import { isOnDimensions } from "./generalDimensions";
import { getInstanceIdAssetFromNullModel, getTranslationThreekit } from "../../utils/threekit/general/getFunctions";
import { CabinetsAndFeatures_NodesT, ModelAppliancesT, NODES_THREEKIT } from "../../utils/constants/nodesNamesThreekit";
import { setTranslationThreekit, setVisible } from "../../utils/threekit/general/setFunctions";
import { getNestedConfiguratorFromNullName, setNestedConfigurationFromNullName } from "../../utils/threekit/general/nestedConfigurator";
import { checkIfCornerCabinetFromNullName } from "../cabinets/cabinetsBase/checkCornersCabinetsBase";
import { checkCornerCabinetInCornerFromIntervals } from "../intervals/checkCornerCabinetInCornerFromIntervals";
import { isNullNameModelApliancesT } from "../cabinets/appliances/checkTypeApliances";
import { getAttributeNameFromNullName } from "../cabinets/getAttributeNameFromNullName";
import { getСompletedModelsNullNames } from "../cabinets/getNodesCabinets";

const WIDTH_DIMENTION = {
  LINE: "Line Dimensions Width",
  START: "Line Dimensions Width Start",
  END: "Line Dimensions Width End",
}
const EMPTY_LEFT_DIMENTION = {
  LINE: "Line Dimensions Empty Left",
  START: "Line Dimensions Empty Left Start",
  END: "Line Dimensions Empty Left End",
}
const EMPTY_RIGHT_DIMENTION = {
  LINE: "Line Dimensions Empty Right",
  START: "Line Dimensions Empty Right Start",
  END: "Line Dimensions Empty Right End",
}

interface setPositionXForPointParamsI {
  instanceIdAsset: string;
  positionX: number;
  nameMovedPoint: string;
}
/**
 * Позиціонує зовнішні точки для лінійок("Line Dimensions Empty Left", "Line Dimensions Empty Right") моделі,
 * Ця лінійка визначає розмір пустого проміжку зліва або справа від моделі.
 * Позиції внутрішніх точок для цих лінійок позиціонується в кастомному скрипті для асету моделі.
 *
 * @param {setPositionXForPointParamsI} objParams Параметри.
 */
const setPositionXForPointLineDimensionsEmpty = (
  objParams: setPositionXForPointParamsI
) => {
  const { instanceIdAsset, positionX, nameMovedPoint } = objParams;
  const curentPositionPointEmptyBottomStart = getTranslationThreekit({
    from: instanceIdAsset,
    name: nameMovedPoint,
  });
  setTranslationThreekit({
    from: instanceIdAsset,
    name: nameMovedPoint,
    value: {
      ...curentPositionPointEmptyBottomStart,
      x: positionX,
    }
  })
}
interface updateLineDimensionsEmptyParamsI {
  nameNullCabinetBase: CabinetsAndFeatures_NodesT;
  positionX: number;
  nameLineDimensions: string;
  nameMovedPoint: string;
}
/**
 * Позиціонує зовнішні точки для лінійок("Line Dimensions Empty Left", "Line Dimensions Empty Right") моделі (setPositionXForPointLineDimensionsEmpty),
 * та показує або приховує ці лінійки
 *
 * @param {updateLineDimensionsEmptyParamsI} objParams Параметри.
 */
const updateLineDimensionsEmpty = (
  objParams: updateLineDimensionsEmptyParamsI
) => {
  const { nameNullCabinetBase, positionX, nameLineDimensions, nameMovedPoint } = objParams;
  const instanceIdAsset = getInstanceIdAssetFromNullModel({name: nameNullCabinetBase});
  if (Math.abs(positionX) > 0) {
    setPositionXForPointLineDimensionsEmpty({ instanceIdAsset, positionX, nameMovedPoint });
    setVisible({
      from: instanceIdAsset,
      name: nameLineDimensions,
      value: true
    });
  } else {
    setVisible({
      from: instanceIdAsset,
      name: nameLineDimensions,
      value: false
    });
  }
}

const checkAppliancesNotOnWalls = (
  apliancesListOnWalls: ModelAppliancesT[]
): Promise<any[]> => {
  let arrPromices: Promise<any[]>[] = [];
  const allСompletedNullForAppliances = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_APPLIANCES
  );
  allСompletedNullForAppliances.forEach((nullNameAppliance) => {
    if (apliancesListOnWalls.includes(nullNameAppliance as ModelAppliancesT)) return;
    const nestedConfigurator = getNestedConfiguratorFromNullName({
      nullName: nullNameAppliance,
      attributeName: ATTRIBUTES_NAMES_THREEKIT.APPLIANCES
    })
    const configuration = nestedConfigurator?.getConfiguration();
    if (
      configuration !== undefined &&
      (configuration[ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_LEFT] !== 0 ||
      configuration[ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_RIGHT] !== 0)
    ) {
      arrPromices.push(setNestedConfigurationFromNullName({
        nullName: nullNameAppliance,
        attributeName: ATTRIBUTES_NAMES_THREEKIT.APPLIANCES,
        configuration: {
          [ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_LEFT]: 0,
          [ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_RIGHT]: 0,
        }
      }));
    }
  })

  return Promise.all(arrPromices)
}

export const updateDimensionsCabinetsBase = async () => {

  // @ts-ignore
  await window.threekit.player.evaluate();

  if (!isOnDimensions()) return;

  const intervalsCabinetsBase = getIntervalsBaseCabinetsForAllWalls();
  let arrPromices: Promise<any>[] = [];
  let cornerModelsInfo: {[key in CabinetsAndFeatures_NodesT]: {
    [ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_LEFT]: number,
    [ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_RIGHT]: number
  }} = {};
  let apliancesListOnWalls: ModelAppliancesT[] = []

  Object.values(intervalsCabinetsBase).forEach((arrIntervalsOnWall) => {

    if (arrIntervalsOnWall.length < 2) return;

    let prevObjInterval: WallRangeT | undefined = undefined;
    let isFirstIntervalEmpty: boolean = false;

    arrIntervalsOnWall.forEach((objInterval, index) => {
      const { empty, name, range } = objInterval;

      const nextObjInterval: WallRangeT | undefined = arrIntervalsOnWall[index + 1];

      if (index === 0 && empty) {
        isFirstIntervalEmpty = true;
      }

      if (!empty && name !== undefined) {
        // заповнений проміжок
        // const configuratorModel = getConfiguratorModelFromNullName(name);

        // Test code START
        // let sizeCabinetBox: ICoordinates = {x: 0, y: 0, z: 0};
        // if (isNullNameModelCabinetBaseT(name)) {
        //   sizeCabinetBox = getSizeModelBoxFromAssetCabinetBase(name);
        // }
        // const halfWidthModel = Number((sizeCabinetBox["x"]/2).toFixed(4));
        // let lineEmptyLeftStartX: number = 0;
        // let lineEmptyRightEndX: number = 0;
        // Test code END

        let objNewConfiguration = {
          [ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_LEFT]: 0,
          [ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_RIGHT]: 0
        };

        if (
          isFirstIntervalEmpty &&
          prevObjInterval !== undefined
        ) {
          const sizePrevEmptyInterval = prevObjInterval["range"][1] - prevObjInterval["range"][0];
          // Test code START
          // lineEmptyLeftStartX = (halfWidthModel + sizePrevEmptyInterval) * -1;
          // Test code END
          objNewConfiguration[ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_LEFT] = sizePrevEmptyInterval;
        } else {
          // Test code START
          // lineEmptyLeftStartX = 0;
          // Test code END
          objNewConfiguration[ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_LEFT] = 0;
        }

        if (nextObjInterval !== undefined && nextObjInterval["empty"]) {
          const sizeNextEmptyInterval = nextObjInterval["range"][1] - nextObjInterval["range"][0];
          // Test code START
          // lineEmptyRightEndX = (halfWidthModel + sizeNextEmptyInterval);
          // Test code END
          objNewConfiguration[ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_RIGHT] = sizeNextEmptyInterval;
        } else {
          // Test code START
          // lineEmptyRightEndX = 0
          // Test code END
          objNewConfiguration[ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_RIGHT] = 0;
        }

        // налаштовуємо та робимо перевірки для кутових шкафів Lazy Suzan та Corner Empty
        if (checkIfCornerCabinetFromNullName(name)) {

          const isCornerCabinetInCorner = checkCornerCabinetInCornerFromIntervals(
            intervalsCabinetsBase,
            name
          );

          arrPromices.push(setNestedConfigurationFromNullName({
            nullName: name,
            attributeName: ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE,
            configuration: {[ATTRIBUTES_NAMES_THREEKIT.IS_MODEL_CORNER_IN_CORNER]: isCornerCabinetInCorner}
          }));

          // Перевіряємо чи не був раніше доданий пустий проміжок зліва
          // (коли модель стойть в куті і вже провірялась на іншій стіні)
          if (cornerModelsInfo.hasOwnProperty(name)) {

            if (
              cornerModelsInfo[name][ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_LEFT] !== 0
            ) {
              objNewConfiguration[ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_LEFT] =
                cornerModelsInfo[name][ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_LEFT];
            }

            if (
              cornerModelsInfo[name][ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_RIGHT] !== 0
            ) {
              objNewConfiguration[ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_RIGHT] =
                cornerModelsInfo[name][ATTRIBUTES_NAMES_THREEKIT.DIMENSIONS_EMPTY_RIGHT];
            }

          } else {
            cornerModelsInfo[name] = objNewConfiguration;
          }

        }

        // arrPromices.push(configuratorModel.setConfiguration({
        //   ...objNewConfiguration
        // }));

        

        const attributeName = getAttributeNameFromNullName(name);

        arrPromices.push(setNestedConfigurationFromNullName({
          nullName: name,
          attributeName,
          configuration: objNewConfiguration
        }));

        // формуємо масив Apliances, які знаходяться на стінах (є в інтервалах для стін)
        if (isNullNameModelApliancesT(name))
          apliancesListOnWalls.push(name);

        // Test code START
        // updateLineDimensionsEmpty({
        //   nameNullCabinetBase: name,
        //   positionX: lineEmptyLeftStartX,
        //   nameLineDimensions: EMPTY_LEFT_DIMENTION.LINE,
        //   nameMovedPoint: EMPTY_LEFT_DIMENTION.START
        // });
        // updateLineDimensionsEmpty({
        //   nameNullCabinetBase: name,
        //   positionX: lineEmptyRightEndX,
        //   nameLineDimensions: EMPTY_RIGHT_DIMENTION.LINE,
        //   nameMovedPoint: EMPTY_RIGHT_DIMENTION.END
        // });
        // Test code END

      }

      if (index > 0) {
        isFirstIntervalEmpty = false;
      }

      prevObjInterval = objInterval;

    })

  });

  // потрібно опрацювати Apliances, які не знаходяться біля стіни
  // тобто знаходяться всередині кімнати
  // для них потрібно вимкнути бокові лінії: 
  checkAppliancesNotOnWalls(
    apliancesListOnWalls
  )

  return Promise.all(arrPromices)

}