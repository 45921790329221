import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { ModelsName_NodesT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getСompletedModelsNullNames } from "../getNodesCabinets";
import { getEvalNode, getRotationThreekit, getTranslationThreekit } from "../../../utils/threekit/general/getFunctions";
import { checkIntersectsModelsBox } from "../IntersectsModelsBox";

export interface InfoForCabinetsIslandI {
  rotation: ICoordinates;
  position: ICoordinates;
  neabor: ModelsName_NodesT[];
}
export type ObjInfoForCabinetsIslandT = {
  [key in ModelsName_NodesT]: InfoForCabinetsIslandI;
};

export const getObjInfoForCabinetsIsland = (): ObjInfoForCabinetsIslandT => {
  const allNullForСabinetsIsland = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_ISLAND
  );

  let objInfo: ObjInfoForCabinetsIslandT = {};
  allNullForСabinetsIsland.forEach((name) => {
    const evalNode = getEvalNode({ name: name });
    //@ts-ignore
    const boundingBox = evalNode.getBoundingBox();

    objInfo[name] = {
      ...objInfo[name],
      rotation: getRotationThreekit({ name: name }),
      position: getTranslationThreekit({ name: name }),
      neabor: [],
    };

    allNullForСabinetsIsland.forEach((nameLast) => {
      if (name !== nameLast) {
        const evalNodeLast = getEvalNode({ name: nameLast });
        //@ts-ignore
        const boundingBoxLast = evalNodeLast.getBoundingBox();
        if (checkIntersectsModelsBox(boundingBox, boundingBoxLast)) {
          objInfo[name] = {
            ...objInfo[name],
            neabor: [...objInfo[name]["neabor"], nameLast],
          };
        }
      }
    });
  });

  return objInfo;
};