import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { updateSetingsUIAndThreekit } from "../../../store/actions/player.action";
import { getStatusDependencySettings, getValueOption } from "../../../store/selectors/settingsUISelectors";
import { updateSettings } from "../../../store/slices/settingsUI/settingsUISlice";
import { RadioOptionI } from "../../../utils/constants/framedOptions";

import {
  setDoorStyleForAllCabinetsType,
  setConfigurationForOneCabinetType,
} from "../../../functionsConfigurator/cabinets/configuration/stylingCabinets";
import { RadioOptionIconText } from "./RadioOptionIconText/RadioOptionIconText";

// scss
import s from "./RadioOptionsIconText.module.scss";

export const RadioOptionsIconText = ({ ...props }) => {
  const { id, label, values, defaultValue, sectionId, dependencySettings } = props;

  const dispatch = useAppDispatch();
  const selectValue = useAppSelector(
    getValueOption({ idOption: id, sectionId: sectionId })
  );

  const onChange = () => (value: string) => {
    dispatch(updateSetingsUIAndThreekit([{optionId: id, value: value}]));
  };

  const isVisible = useAppSelector(getStatusDependencySettings(dependencySettings));
  if (!isVisible) return <></>;

  return (
    <div className={s.radioOptionsIconTextWrap}>
      {label && <p className={s.radioOptionsIconTextLabel}>{label}</p>}
      <div className={s.radioOptionsIconTextList}>
        {values.map((option: RadioOptionI) => {
          const isActiveOption = option["value"] === selectValue;
          return (
            <RadioOptionIconText
              key={option["id"]}
              {...option}
              onClick={onChange()}
              isActive={isActiveOption}
            />
          );
        })}
      </div>
    </div>
  );
};
